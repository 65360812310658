import { useEffect, useMemo, useState } from "react";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { debounce } from "lodash";

const getColumns = patientAlertData => {
  const columns = [
    {
      accessorKey: "firstName",
      header: () => <span>Patient Name</span>,
      cell: info => {
        let firstName = info.row.original.firstName;
        let lastName = info.row.original.lastName;
        let patientId = info.row.original.id;
        return (
          <Link
            className="patient-name with-link"
            to={`/patients/${patientId}`}
          >
            {firstName || ""} {lastName || ""}
          </Link>
        );
      },
    },
    {
      accessorKey: "phoneNumber",
      header: () => <span>Phone Number</span>,
      cell: info => info.getValue(),
    },
    {
      accessorKey: "email",
      header: () => <span>Email</span>,
      cell: info => info.getValue(),
    },
    {
      accessorKey: "alerts",
      header: () => <div style={{ textAlign: "center" }}>Alerts</div>,
      columns: [
        {
          accessorKey: "depression",
          header: () => <div style={{ textAlign: "center" }}>Depression</div>,
          cell: info => {
            let patientId = info.row.original.id;
            let alerts = patientAlertData[patientId];
            let depression = alerts?.depression || null;
            if (!depression) {
              return "";
            }
            return (
              <div className={`patient-alert-indicator ${depression?.cardColor}`}>
                {depression?.intensityText}
              </div>
            );
          },
        },
        {
          accessorKey: "anxiety",
          header: () => <div style={{ textAlign: "center" }}>Anxiety</div>,
          cell: info => {
            let patientId = info.row.original.id;
            let alerts = patientAlertData[patientId];
            let anxiety = alerts?.anxiety || null;
            if (!anxiety) {
              return "";
            }
            return (
              <div className={`patient-alert-indicator ${anxiety?.cardColor}`}>
                {anxiety?.intensityText}
              </div>
            );
          },
        },
        {
          accessorKey: "suicide_risk",
          header: () => <div style={{ textAlign: "center" }}>Suicide Risk</div>,
          cell: info => {
            let patientId = info.row.original.id;
            let alerts = patientAlertData[patientId];
            let suicideRisk = alerts?.suicideRisk || null;
            if (!suicideRisk || !suicideRisk?.intensityText) {
              return "";
            }
            return (
              <div className={`patient-alert-indicator ${suicideRisk?.cardColor}`}>
                {suicideRisk?.intensityText}
              </div>
            );
          },
        },
      ],
    },
  ];
  return columns;
};

const usePatients = props => {
  const [searchKey, setSearchKey] = useState("");
  const [addPatientModaOpen, setAddPatientModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const patientsData = useMemo(() => {
    if (!props.patients.data || props.patients.data.length === 0) {
      return [];
    }
    if (!searchKey) {
      return props.patients.data;
    }
    return props.patients.data.filter(i => {
      let patientName = `${i.firstName} ${i.lastName}`;
      let patientEmail = i.email;
      return (
        !!patientName.trim().toLowerCase().includes(searchKey.trim().toLowerCase()) ||
        patientEmail?.trim?.()?.toLowerCase()?.includes(searchKey.trim().toLowerCase())
      );
    });
  }, [props.patients.data, searchKey]);
  useEffect(() => {
    if (!props.patients.loading && props.patients.data.length === 0) {
      props.getPatients();
    }
  }, []);
  useEffect(() => {
    document.title = "Patients";
  }, []);
  const columns = useMemo(() => getColumns(props.patientAlertData), [props.patientAlertData]);
  const table = useReactTable({
    data: patientsData,
    columns,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  });
  const tableVisibleData = useMemo(() => {
    return table.getRowModel().rows.map(i => i.original);
  }, [table.getRowModel().rows]);
  const fetchAlerts = useMemo(
    () =>
      debounce(data => {
        if (data?.length > 0) {
          props.getPatientsAlerts(data.map(patient => patient.id));
        }
      }, 2000),
    [props.getPatientsAlerts]
  );
  useEffect(() => {
    fetchAlerts(tableVisibleData);
  }, [tableVisibleData, fetchAlerts]);
  return {
    searchKey,
    setSearchKey,
    addPatientModaOpen,
    setAddPatientModalOpen,
    pagination,
    setPagination,
    patientsData,
    table,
  };
};

export default usePatients;
