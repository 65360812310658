import React, { useMemo, useState } from "react";
import { MemberAvatar, Loader, SecondaryButton } from "@/components";
import VoicePrintIcon from "@/assets/voice-print.svg";
import VoicePrintCapturedIcon from "@/assets/voice-print-captured.svg";
import "./Transcript.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function Transcript(props) {
  const [transcriptCopied, setTranscriptCopied] = useState(false);
  const providerName = `${props.sessionData?.practitionerFirstName || ""}${
    props.sessionData?.practitionerLastName ? " " : ""
  }${props.sessionData?.practitionerLastName || ""}`;
  const patientName = `${props.sessionData?.patientFirstName || ""}${
    props.sessionData?.patientLastName ? " " : ""
  }${props.sessionData?.patientLastName || ""}`;
  const providerSessionsTranscriptData = props.data || [];
  const transcriptData = useMemo(
    () =>
      providerSessionsTranscriptData.reduce((acc, providerSessionsTranscript) => {
        const sessionTranscripts = providerSessionsTranscript.transcriptData.map(i => {
          return {
            ...i,
            providerSessionId: providerSessionsTranscript.providerSessionId,
          };
        });
        return acc.concat(sessionTranscripts);
      }, []),
    [providerSessionsTranscriptData]
  );
  const copyTranscript = () => {
    let transcriptText = "";
    transcriptData.forEach(transcript => {
      let memberName = transcript.memberName;
      if (
        ["provider", "patient"].includes(memberName?.toLowerCase()) &&
        props.sessionCategory === "individual"
      ) {
        if (memberName?.toLowerCase() === "provider" && providerName) {
          memberName = providerName;
        }
        if (memberName?.toLowerCase() === "patient" && patientName) {
          memberName = patientName;
        }
      }
      transcriptText += `${memberName}: ${transcript.transcriptText}\n\n`;
    });
    navigator.clipboard
      .writeText(transcriptText)
      .then(() => {
        setTranscriptCopied(true);
        setTimeout(() => {
          setTranscriptCopied(false);
        }, 3000);
      })
      .catch(err => {
        console.error("Unable to copy text to clipboard", err);
      });
  };
  return (
    <div className="session-transcript-container">
      <div className="session-transcript-header">
        <div className="session-transcript-title">Transcript</div>
        {(props.selectedUserRole?.businessFunction === "Management and Leadership" ||
          props.sessionCategory === "group") &&
          transcriptData.length > 0 && (
            <SecondaryButton onClick={() => props.setSpeakerMappingOpen(true)}>
              Speaker Mapping
            </SecondaryButton>
          )}
        <SecondaryButton onClick={copyTranscript}>
          {transcriptCopied ? "Transcript Copied" : "Copy Transcript"}
        </SecondaryButton>
      </div>
      <Loader loading={props.loading}>
        {transcriptData.length > 0 ? (
          <div className="session-transcript-content">
            {transcriptData.map((transcript, index) => {
              let memberId = transcript.memberId;
              let providerParticipantId = transcript.providerParticipantId;
              let providerSessionId = transcript.providerSessionId;
              let memberName = transcript.memberName;
              if (
                memberId === providerParticipantId &&
                props.speakerRecognition?.data?.[props.sessionId]?.[providerSessionId]?.[
                  memberId
                ] &&
                props.sessionCategory === "group"
              ) {
                const { predictedLabel = "", speakerUUID = "" } =
                  props.speakerRecognition?.data?.[props.sessionId]?.[providerSessionId]?.[
                    providerParticipantId
                  ];
                if (predictedLabel && speakerUUID) {
                  if (predictedLabel === "Provider") {
                    memberName = "Provider";
                  } else {
                    memberId = speakerUUID;
                    let sessionPatientMemberDtos =
                      props.sessionData?.sessionPatientMemberDtos || [];
                    let patientObj = sessionPatientMemberDtos.find(
                      i => i.patientId === speakerUUID
                    );
                    if (patientObj) {
                      memberName = `${patientObj.patientFirstName || ""} ${
                        patientObj.patientLastName || ""
                      }`;
                    }
                  }
                }
              }
              let baseTimeStamp = transcriptData?.[0].timestamp;
              let seconds = transcript.timestamp - baseTimeStamp;
              let transcriptTime = formatTime(seconds / 1000);

              let transcriptText = transcript.transcriptText;
              let isProvider =
                memberName === "Provider" ||
                memberId === props.sessionData?.practitionerRoleId ||
                memberId === "Provider";
              let isVoicePrintCaptured = false;
              let voiceUrl = "";
              if (!isProvider) {
                if (props.sessionCategory === "individual") {
                  let patientId = props.sessionData?.patientId;
                  let enrollmentStatus = props.speakerProfileEnrollment?.data?.find(
                    i => i.speakerType === "Patient" && i.patientId === patientId
                  );
                  if (enrollmentStatus) {
                    isVoicePrintCaptured = !!enrollmentStatus.speakerProfileEnrolled;
                    voiceUrl = enrollmentStatus.speakerProfileEnrollmentFileUrl;
                  }
                } else {
                  let enrollmentStatus = props.speakerProfileEnrollment?.data?.find(
                    i => i.speakerType === "Patient" && i.patientId === memberId
                  );
                  if (enrollmentStatus) {
                    isVoicePrintCaptured = !!enrollmentStatus.speakerProfileEnrolled;
                    voiceUrl = enrollmentStatus.speakerProfileEnrollmentFileUrl;
                  }
                }
              } else {
                let practitionerRoleId = props.sessionData?.practitionerRoleId;
                let enrollmentStatus = props.speakerProfileEnrollment?.data?.find(
                  i => i.speakerType === "Provider" && i.practitionerRoleId === practitionerRoleId
                );
                if (enrollmentStatus) {
                  isVoicePrintCaptured = !!enrollmentStatus.speakerProfileEnrolled;
                  voiceUrl = enrollmentStatus.speakerProfileEnrollmentFileUrl;
                }
              }
              if (
                ["provider", "patient"].includes(memberName?.toLowerCase()) &&
                props.sessionCategory === "individual"
              ) {
                if (memberName?.toLowerCase() === "provider" && providerName) {
                  memberName = providerName;
                }
                if (memberName?.toLowerCase() === "patient" && patientName) {
                  memberName = patientName;
                }
              }
              return (
                <div
                  className={`transcript-item`}
                  key={`${transcript.timestamp}-${transcript.memberId}-${convertStringToHash(
                    transcriptText
                  )}-${index}`}
                >
                  <div className="transcript-item-header">
                    <div className="transcript-avatar">
                      {/* <MemberAvatar
                        name={memberName}
                        size={"sm"}
                      /> */}
                      {memberName}
                    </div>
                    {props.enableVoiceRecognitionFeature && (
                      <div className="voice-enrollment-status">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              {isVoicePrintCaptured
                                ? props.sessionCategory === "individual"
                                  ? "Voice Print Captured"
                                  : "Speaker recognized"
                                : props.sessionCategory === "individual"
                                ? "Voice print not captured"
                                : "Speaker not recognized"}
                            </Tooltip>
                          }
                        >
                          {isVoicePrintCaptured ? (
                            <a
                              target="_blank"
                              href={voiceUrl}
                            >
                              <img
                                src={isVoicePrintCaptured ? VoicePrintCapturedIcon : VoicePrintIcon}
                                className="voice-enrollment-icon"
                                alt=""
                              />
                            </a>
                          ) : (
                            <img
                              src={isVoicePrintCaptured ? VoicePrintCapturedIcon : VoicePrintIcon}
                              className="voice-enrollment-icon"
                              alt=""
                            />
                          )}
                        </OverlayTrigger>
                      </div>
                    )}
                    <div className="transcript-time">{transcriptTime}</div>
                  </div>
                  <div className="transcript-text">{transcriptText}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-transcript-container">
            No Transcripts Found. Please check back again later.
          </div>
        )}
      </Loader>
    </div>
  );
}

export default Transcript;

const convertStringToHash = str => {
  if (!str) {
    return "";
  }
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}
