import React from "react";
import { OverlayTrigger, Dropdown, Tooltip } from "react-bootstrap";
import "./TablePagination.scss";

const TablePagination = props => {
  const { table } = props;
  const paginationSizes = props.paginationSizes || [20, 50, 100];
  const totalPages = table.getPageCount().toLocaleString();
  const pageSize = table.getState().pagination.pageSize;
  const currentPage = table.getState().pagination.pageIndex + 1;
  const totalItems = table.getRowCount().toLocaleString();
  let lowerLimit = 0;
  let upperLimit = 0;
  if (totalItems) {
    upperLimit = pageSize * currentPage;
    lowerLimit = upperLimit - pageSize + 1;
    if (upperLimit > totalItems) {
      upperLimit = totalItems;
    }
  }
  return (
    <div className={`table-pagination ${totalItems ? "with-total-items" : ""}`}>
      {!!totalItems && (
        <div className="pagination-left-section">
          <span>{`Showing ${lowerLimit} - ${upperLimit} of ${totalItems}`}</span>
        </div>
      )}
      <div className="pagination-right-section">
        <span>Rows per page:</span>
        <div className="table-page-size">
          <Dropdown
            className="pagination-size-dropdown"
            drop={"up"}
          >
            <Dropdown.Toggle>{pageSize}</Dropdown.Toggle>
            <Dropdown.Menu>
              {paginationSizes.map(size => {
                return (
                  <Dropdown.Item
                    as={"button"}
                    key={size}
                    onClick={() => table.setPageSize(Number(size))}
                    className="pagination-size-item"
                  >
                    {size}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <OverlayTrigger
          overlay={<Tooltip className="tooltip-overlay">Prev</Tooltip>}
          placement={"top"}
        >
          <div
            className={`pagination-btn prev ${!table.getCanPreviousPage() ? "disabled" : ""}`}
            onClick={() => table.previousPage()}
          >
            <span></span>
          </div>
        </OverlayTrigger>
        <div className="page-info">
          Page <span className="current-page">{currentPage}</span> of {totalPages}
        </div>
        <OverlayTrigger
          overlay={<Tooltip className="tooltip-overlay">Next</Tooltip>}
          placement={"top"}
        >
          <div
            className={`pagination-btn next ${!table.getCanNextPage() ? "disabled" : ""}`}
            onClick={() => table.nextPage()}
          >
            <span></span>
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default TablePagination;
