import React, { useState } from "react";
import { MemberAvatar } from "../Avatar";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import "./MemberNotes.scss";
import { ReactSVG } from "react-svg";
import ShareIcon from "@/assets/share.svg";
import ReminderIcon from "@/assets/reminder.svg";
import InfoIcon from "@/assets/info.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { SendText } from "@/components";
import { RichTextEditor } from "../RichTextEditor";
import { Tabs, Tab } from "react-bootstrap";
import TreatmentPlan from "../TreatmentPlanV2";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

const camelCaseToWords = s => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const MemberNotes = props => {
  const { data, hideNotes } = props;
  const { sessionCategory, patientId, sessionId } = useParams();
  const [activeTab, setActiveTab] = useState("notes"); //notes, treatmentPlan
  const [showSMSReminderPopup, setShowSMSReminderPopup] = useState(false);
  const [showSMSMeetingLinkPopup, setShowSMSMeetingLinkPopup] = useState(false);
  const patientSessionTime = `${moment(props.sessionDetailsData?.startTime)
    .tz(props.preferredTimezone?.name || "America/Los_Angeles")
    .format("MMM DD, h:mm a")} ${props.preferredTimezone?.abbr || "PT"}`;
  const patientPhoneNumber = props.sessionDetailsData?.patientPhoneNumber || "";
  const meetingLink = `https://app${
    APP_ENV === "DEV" ? ".dev" : ""
  }.soulsidehealth.com/virtual-session/${patientId}`;
  const providerName = `${props.sessionDetailsData?.practitionerFirstName || ""}${
    props.sessionDetailsData?.practitionerLastName ? " " : ""
  }${props.sessionDetailsData?.practitionerLastName || ""}`;
  let patientName = props.sessionDetailsData?.patientFirstName || "";
  let orgName = props.sessionDetailsData?.organizationName || "";
  if (orgName.includes("Serenity")) {
    orgName = "Serenity Health Clinic";
  }
  const smsReminderMsg = `From: ${orgName} (Dr. ${providerName})\n\nHi ${patientName},\n\nDr. ${providerName} ${
    orgName ? `from ${orgName} ` : ""
  }has joined the appointment room and is waiting for you.\n\nPlease join with the following link now:\n${meetingLink}`;
  let sessionDate = props.sessionDetailsData?.startTime
    ? moment(props.sessionDetailsData.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("Do MMMM")
    : "";
  let sessionTime = props.sessionDetailsData?.startTime
    ? `${moment(props.sessionDetailsData.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("h:mm A")} ${props.preferredTimezone?.abbr || "PT"}`
    : "";
  let meetingLinkMessage = `From: ${orgName} (Dr. ${providerName})\n\nHi ${patientName},\n\nYour appointment ${
    orgName ? `at ${orgName} ` : ""
  }with Dr. ${providerName} is scheduled for ${sessionDate} at ${sessionTime}.\n\nYou can join your appointment with the following link:\n${meetingLink}`;
  const previousSessionDate = props.data?.soapNotesSession?.startTime
    ? `${moment(props.data.soapNotesSession.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMMM DD YYYY, h:mm A")} ${props.preferredTimezone?.abbr || "PT"}`
    : "";
  const previousSessionproviderName = `${
    props.data?.soapNotesSession?.practitionerFirstName || ""
  }${props.data?.soapNotesSession?.practitionerLastName ? " " : ""}${
    props.data?.soapNotesSession?.practitionerLastName || ""
  }`;
  return (
    <div className="member-notes-container">
      <div className="member-notes-header">
        <MemberAvatar
          {...data}
          size={"md"}
          showFullName={true}
        />
        <div className="session-time">{patientSessionTime}</div>
      </div>
      {props.sessionType === "virtual" && (
        <div className="sms-send-btns">
          <div
            className="send-sms-btn"
            onClick={() => setShowSMSReminderPopup(true)}
          >
            <ReactSVG
              src={ReminderIcon}
              className="send-btn-icon"
            />
            Send Reminder
          </div>
          <div
            className="send-sms-btn"
            onClick={() => setShowSMSMeetingLinkPopup(true)}
          >
            <ReactSVG
              src={ShareIcon}
              className="send-btn-icon"
            />
            Share Meeting Link
          </div>
        </div>
      )}
      <div className="session-member-content-container">
        {/* <div className="session-member-notes-header">
          <div className="session-member-notes-label">Previous Session Notes</div>
          {props.data?.soapNotesSession && (
            <OverlayTrigger
              overlay={
                <Tooltip className="session-info-tooltip">
                  <div className="session-time">
                    <strong>Session Time:</strong> {previousSessionDate}
                  </div>
                  <div className="session-provider">
                    <strong>Provider:</strong> {previousSessionproviderName}
                  </div>
                </Tooltip>
              }
              placement="left"
            >
              <div className="session-info">
                <ReactSVG
                  src={InfoIcon}
                  className="info-icon"
                />
              </div>
            </OverlayTrigger>
          )}
        </div> */}
        <Tabs
          id="controlled-tab-example"
          activeKey={activeTab}
          onSelect={k => setActiveTab(k)}
          className="session-member-notes-tabs-list"
        >
          <Tab
            eventKey="notes"
            title="Previous Session Notes"
          >
            {!!data?.soapNotesJson?.narrative ||
            !!data?.soapNotesJson?.subjective?.chief_complaint?.result ? (
              <textarea
                value={
                  data?.soapNotesJson?.narrative ||
                  data?.soapNotesJson?.subjective?.chief_complaint?.result
                }
                readOnly={true}
                className="member-notes-narrative"
              />
            ) : !!data?.soapNotes ? (
              <RichTextEditor
                value={data.soapNotes}
                readOnly={true}
              />
            ) : (
              <div className="no-notes-added-message">No previous notes available</div>
            )}
          </Tab>
          <Tab
            eventKey="treatmentPlan"
            title="Treatment Plan"
          >
            <TreatmentPlan
              patientId={props.patientId}
              noAction={true}
            />
          </Tab>
        </Tabs>
      </div>

      <SendText
        modalOpen={showSMSReminderPopup}
        toggleModal={setShowSMSReminderPopup}
        phoneNumber={patientPhoneNumber}
        message={smsReminderMsg}
        title={"Let the patient know that you have entered the room"}
      />
      <SendText
        modalOpen={showSMSMeetingLinkPopup}
        toggleModal={setShowSMSMeetingLinkPopup}
        phoneNumber={patientPhoneNumber}
        message={meetingLinkMessage}
      />
    </div>
  );
};

export { MemberNotes };
