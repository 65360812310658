import React, { useState, useEffect } from "react";
import { Tabs, Tab, Form } from "react-bootstrap";
import { intakeAssessmentTabs } from "./intakeAssessmentSchema";
import dummyData from "./dummyIntakeAssessment2";
import "./IntakeNotesTemplate.scss";

function IntakeNotesTemplate(props) {
  const [activeTab, setActiveTab] = useState(intakeAssessmentTabs[0].name);
  const data = props.data?.["intake-assessment"] || null;
  return (
    <div className="intake-notes-template">
      <div className="intake-notes-tabs-container">
        <Tabs
          activeKey={activeTab}
          onSelect={k => setActiveTab(k)}
          className="intake-notes-tabs-list"
        >
          {intakeAssessmentTabs.map(tab => {
            return (
              <Tab
                eventKey={tab.name}
                title={tab.name}
                key={tab.name}
              >
                {tab.content.map(content => {
                  return (
                    <div
                      className="intake-notes-tab-content"
                      key={`${content.key}-${content.type}`}
                    >
                      {content.name && (
                        <div className="intake-notes-tab-content-name">{content.name}</div>
                      )}
                      {content.type === "paragraph" && (
                        <textarea
                          className="notes-paragraph"
                          readOnly={true}
                          value={data?.[content.key] || ""}
                        />
                      )}
                      {content.type === "list_of_single_choice_questions" && (
                        <div className="questions-list">
                          {data?.[content.key]?.length > 0 &&
                            data?.[content.key].map((question, index) => {
                              return (
                                <SingleChoiceQuestion
                                  data={question}
                                  sectionKey={content.key}
                                  keyIndex={index}
                                  key={index}
                                />
                              );
                            })}
                        </div>
                      )}
                      {content.type === "single_choice_question" && (
                        <div className="questions-list">
                          {data?.[content.key]?.question && (
                            <SingleChoiceQuestion
                              data={data?.[content.key]}
                              sectionKey={content.key}
                              keyIndex={0}
                            />
                          )}
                        </div>
                      )}
                      {content.type === "list_of_tables" && (
                        <div className="tables-list">
                          {content.tables.map(table => {
                            if (!data?.[content.key]?.[table.key]?.length > 0) {
                              return <></>;
                            }
                            return (
                              <IntakeTable
                                key={table.key}
                                tableName={table.name}
                                data={data?.[content.key]?.[table.key]}
                              />
                            );
                          })}
                        </div>
                      )}
                      {content.type === "table" && data?.[content.key]?.length > 0 && (
                        <IntakeTable
                          tableName={""}
                          data={data?.[content.key]}
                        />
                      )}
                      {content.type === "mixed_content" &&
                        content.subContent?.length > 0 &&
                        content.subContent.map(subContent => {
                          return (
                            <div
                              className="intake-notes-tab-content"
                              key={`${subContent.key}-${subContent.type}`}
                            >
                              {subContent.name && (
                                <div className="intake-notes-tab-content-name">
                                  {subContent.name}
                                </div>
                              )}
                              {subContent.type === "list_of_single_choice_questions" && (
                                <div className="questions-list">
                                  {data?.[content.key]?.[subContent.key]?.length > 0 &&
                                    data?.[content.key][subContent.key].map((question, index) => {
                                      return (
                                        <SingleChoiceQuestion
                                          data={question}
                                          sectionKey={subContent.key}
                                          keyIndex={index}
                                          key={index}
                                        />
                                      );
                                    })}
                                </div>
                              )}
                              {subContent.type === "list_of_tables" && (
                                <div className="tables-list">
                                  {subContent.tables.map(table => {
                                    if (
                                      !data?.[content.key]?.[subContent.key]?.[table.key]?.length >
                                      0
                                    ) {
                                      return <></>;
                                    }
                                    return (
                                      <IntakeTable
                                        key={table.key}
                                        tableName={table.name}
                                        data={data?.[content.key]?.[subContent.key]?.[table.key]}
                                      />
                                    );
                                  })}
                                </div>
                              )}
                              {subContent.type === "table" &&
                                data?.[content.key]?.[subContent.key]?.length > 0 && (
                                  <IntakeTable
                                    tableName={""}
                                    data={data?.[content.key]?.[subContent.key]}
                                  />
                                )}
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
}

export default IntakeNotesTemplate;

function simpleHash(inputString) {
  let hash = 0;
  if (inputString.length === 0) return hash.toString();
  for (let i = 0; i < inputString.length; i++) {
    const char = inputString.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32-bit integer
  }
  return hash.toString(16); // Return hexadecimal string
}

const SingleChoiceQuestion = ({ data, sectionKey, keyIndex }) => {
  return (
    <div className="intake-single-choice-question">
      <div className="question-label">
        <strong>{keyIndex + 1}.</strong> {data?.question}
      </div>
      {data?.options?.length > 0 ? (
        <div className="intake-single-choice-question-options">
          {data?.options?.map(value => {
            return (
              <Form.Check
                type="radio"
                label={value}
                key={`${simpleHash(data?.question || "")}-${value}`}
                // name={`${sectionKey}-${keyIndex}`}
                // id={`${sectionKey}-${keyIndex}`}
                checked={data.response === value}
                className="intake-notes-single-select-radio-button"
                readOnly={true}
              />
            );
          })}
        </div>
      ) : (
        <>{data?.response && <div className="response-value">{data.response}</div>}</>
      )}
      {data?.explanation && (
        <div className="intake-question-explanation">
          <strong>Explanation:</strong> {data.explanation}
        </div>
      )}
    </div>
  );
};

const IntakeTable = ({ data, tableName }) => {
  let tableColumns = Object.keys(data?.[0] || {});
  return (
    <div className="intake-table-container">
      {tableName && <div className="table-name">{tableName}</div>}
      <table className="intake-table">
        <thead>
          <tr>
            {tableColumns.map((tableColumn, index) => {
              return <th key={index}>{tableColumn}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((i, index) => {
            return (
              <tr key={index}>
                {tableColumns.map((tableColumn, index2) => {
                  return <td key={index2}>{i?.[tableColumn] || ""}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
