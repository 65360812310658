import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import { MemberAvatar, SecondaryButton } from "@/components";
import ShareMeetingLink from "@/Routes/Session/components/VirtualSession/components/VideoWidget/components/Meeting/components/ShareMeetingLink";
import WaitingStatus from "../WaitingStatus";
import MentalWellnessScore from "../MentalWellnessScore";
import ScheduleSessionModal from "../../../ScheduleSessionModal";

import InPersonIcon from "@/assets/sofa.svg";
import VirtualIcon from "@/assets/video-recorder.svg";
import PhoneSpeakerIcon from "@/assets/phone-speaker.svg";
import GroupsIcon from "@/assets/group.svg";
import IntakeIcon from "@/assets/intake.svg";
import PhoneIcon from "@/assets/phone.svg";
import EmailIcon from "@/assets/email.svg";
import GroupsPlaceholderIcon from "@/assets/group-avatar-placeholder.svg";

import "./SessionCard.scss";

function SessionCard(props) {
  const [showEditSessionModal, setShowEditSessionModal] = useState(false);
  const session = props.session;
  const isUpcoming = props.isUpcoming;
  const isCompleted = props.isCompleted;
  // const sessionTime = `${moment(session.startTime)
  //   .tz(props.preferredTimezone?.name || "America/Los_Angeles")
  //   .format("MMMM DD")}, ${moment(session.startTime)
  //   .tz(props.preferredTimezone?.name || "America/Los_Angeles")
  //   .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"} | ${
  //   session.durationInMinutes
  // } mins`;
  const sessionTime = `${moment(session.startTime)
    .tz(props.preferredTimezone?.name || "America/Los_Angeles")
    .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"} | ${
    session.durationInMinutes
  } mins`;
  const isExternalSpeakerPhone = session.sessionName?.includes("Phone session");
  // Shifted to dyte for in person sessions from 9th September 2024
  let sessionDate = new Date(session.startTime);
  let updateDate = new Date("2024-09-09T06:00:00.000+05:30");
  let inPersonOldSession = sessionDate < updateDate;
  useEffect(() => {
    props.sessionNotesStatusCheck(session, true);
  }, []);
  return (
    <div className="session-card-item">
      <div className="session-card-item-left">
        <div className="session-meta-container">
          <div className="session-time">{sessionTime}</div>
          <div className="session-tags-container">
            {session.sessionCategory === "GROUP" && (
              <div className="session-type">
                <img
                  className="session-type-icon"
                  src={GroupsIcon}
                />
                Group
              </div>
            )}
            {session.sessionCategory === "INDIVIDUAL" && session.appointmentType === "INTAKE" && (
              <div className="session-type">
                <img
                  className="session-type-icon"
                  src={IntakeIcon}
                />
                Intake
              </div>
            )}
            <div className="session-type">
              <img
                className="session-type-icon"
                src={
                  session.modeOfDelivery === "VIRTUAL"
                    ? VirtualIcon
                    : isExternalSpeakerPhone
                    ? PhoneSpeakerIcon
                    : InPersonIcon
                }
              />
              {session.modeOfDelivery === "VIRTUAL"
                ? "Telehealth"
                : isExternalSpeakerPhone
                ? "Phone"
                : "In-Office"}
            </div>
          </div>
          {props.selectedUserRole?.businessFunction === "Clinical Care" &&
            // isUpcoming &&
            !props.hideStartSessionButton && (
              <SessionStartLink
                session={session}
                isExternalSpeakerPhone={isExternalSpeakerPhone}
              />
            )}
          {props.selectedUserRole?.businessFunction === "Management and Leadership" && (
            <div
              className="start-session-button"
              onClick={() => setShowEditSessionModal(true)}
            >
              Edit Session
            </div>
          )}
        </div>
        {/* <div className="session-name">{session.sessionName}</div> */}

        <div className="session-item-patient-container">
          {props.selectedUserRole?.businessFunction !== "Clinical Care" && (
            <div className="session-patient-label">Patient:</div>
          )}
          <div className="patient-container">
            <MemberAvatar
              name={
                session.sessionCategory === "GROUP"
                  ? session.groupName
                  : `${session.patientFirstName} ${session.patientLastName}`
              }
              size={"sm"}
              showFullName={true}
              placeholderImg={session.sessionCategory === "GROUP" ? GroupsPlaceholderIcon : null}
              // location={`${session.patientData?.patient_city}, ${session.patientData?.patient_state}`}
            />
            {isUpcoming && <WaitingStatus session={session} />}
          </div>
          {isUpcoming && <MentalWellnessScore session={session} />}
          {session.sessionCategory === "INDIVIDUAL" &&
            (session.patientPhoneNumber || session.patientEmail) && (
              <div className="patient-contact-container">
                {session.patientPhoneNumber && (
                  <div className="patient-contact-item">
                    <ReactSVG
                      src={PhoneIcon}
                      className="patient-contact-icon"
                    />
                    <span className="patient-contact-value">{session.patientPhoneNumber}</span>
                  </div>
                )}
                {session.patientEmail && (
                  <div className="patient-contact-item">
                    <ReactSVG
                      src={EmailIcon}
                      className="patient-contact-icon"
                    />
                    <span className="patient-contact-value">{session.patientEmail}</span>
                  </div>
                )}
              </div>
            )}
        </div>
        {props.selectedUserRole?.businessFunction !== "Clinical Care" && (
          <div className="session-provider-container">
            <div className="session-provider-label">Provider:</div>
            <MemberAvatar
              name={`${session.practitionerFirstName} ${session.practitionerLastName}`}
              size={"sm"}
              showFullName={true}
            />
          </div>
        )}
        {session.modeOfDelivery === "VIRTUAL" && isUpcoming && (
          <ShareMeetingLink
            sessionCategory={session.sessionCategory === "GROUP" ? "group" : "individual"}
            sessionDetailsData={session}
            patientId={session?.patientId}
            groupId={session?.groupId}
          />
        )}
      </div>
      <div
        // className={`session-card-item-right ${
        //   isSessionEnded && isUpcoming ? "with-details-link" : ""
        // }`}
        className={`session-card-item-right with-details-link`}
      >
        {isUpcoming && !props.hideStartSessionButton && (
          <Link
            className="session-details-link"
            to={`/session-details/${session.sessionCategory === "GROUP" ? "group" : "individual"}/${
              session.modeOfDelivery === "VIRTUAL" ? "virtual" : "in-person"
            }/${session.id}/${
              session.sessionCategory === "GROUP" ? session.groupId : session.patientId
            }${inPersonOldSession ? "?inPersonOldSession=true" : ""}`}
          >
            View Session Notes
          </Link>
        )}
        <div className="session-item-details-container">
          {isCompleted && !props.hideStartSessionButton && (
            <Link
              className="session-details-link"
              to={`/session-details/${
                session.sessionCategory === "GROUP" ? "group" : "individual"
              }/${session.modeOfDelivery === "VIRTUAL" ? "virtual" : "in-person"}/${session.id}/${
                session.sessionCategory === "GROUP" ? session.groupId : session.patientId
              }${inPersonOldSession ? "?inPersonOldSession=true" : ""}`}
            >
              View Session Notes
            </Link>
          )}
        </div>
        {props.sessionNotesGenerated.includes(session.id) && (
          <Link
            className="notes-ready-tag"
            to={`/session-details/${session.sessionCategory === "GROUP" ? "group" : "individual"}/${
              session.modeOfDelivery === "VIRTUAL" ? "virtual" : "in-person"
            }/${session.id}/${
              session.sessionCategory === "GROUP" ? session.groupId : session.patientId
            }`}
            target="_blank"
          >
            Notes Ready
          </Link>
        )}
      </div>
      <ScheduleSessionModal
        modalOpen={showEditSessionModal}
        toggleModal={setShowEditSessionModal}
        getSessionsByPractitionerRole={props.getSessionsByPractitionerRole}
        selectedUserRole={props.selectedUserRole}
        filterSelectedDate={moment(session.startTime).tz(
          props.preferredTimezone?.name || "America/Los_Angeles"
        )}
        data={session}
        isEdit={true}
      />
    </div>
  );
}

export default SessionCard;

export const SessionStartLink = props => {
  let session = props.session;
  const onStartSession = () => {
    let meetingLink = `/session/${session.sessionCategory === "GROUP" ? "group" : "individual"}/${
      session.modeOfDelivery === "VIRTUAL" ? "virtual" : "in-person"
    }/${session.id}/${session.sessionCategory === "GROUP" ? session.groupId : session.patientId}${
      props.isExternalSpeakerPhone ? "?externalSpeakerPhone=true" : ""
    }`;
    window.location.href = window.location.origin + meetingLink;
  };
  return (
    <div
      className="start-session-button"
      // to={meetingLink}
      onClick={onStartSession}
      // target="_blank"
    >
      Start Session
    </div>
  );
};
