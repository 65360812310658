import SessionCard from "./SessionCard";
import { connect } from "react-redux";
import { sessionNotesStatusCheck } from "@/Routes/Appointments/appointmentsModule";

const mapStateToProps = state => {
  return {
    selectedUserRole: state.user.selectedUserRole,
    preferredTimezone: state.user.preferredTimezone,
    sessionNotesGenerated: state.appointments?.sessions?.sessionNotesGenerated || [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sessionNotesStatusCheck: (session, forSessionTag) =>
      dispatch(sessionNotesStatusCheck(session, forSessionTag)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionCard);
