import React, { useEffect, useState } from "react";
import "./SpeakerEnrollmentLoader.scss";
import { Loader } from "@/components";

const SpeakerEnrollmentLoader = ({
  speakerProfileEnrollment,
  sessionCategory,
  sessionData,
  ...props
}) => {
  const { data: speakerProfileEnrollmentData, loading } = speakerProfileEnrollment;
  const [speakers, setSpeakers] = useState([]);
  useEffect(() => {
    if (sessionData) {
      let speakers = [];
      if (sessionCategory === "individual") {
        if (sessionData?.patientId) {
          speakers.push({ isPatient: true, patientId: sessionData.patientId });
        }
        if (sessionData?.practitionerRoleId) {
          speakers.push({
            isPatient: false,
            practitionerRoleId: sessionData.practitionerRoleId,
          });
        }
      } else {
        let sessionPatientMemberDtos = sessionData?.sessionPatientMemberDtos || [];
        speakers = sessionPatientMemberDtos.map(patient => {
          return {
            ...patient,
            isPatient: true,
          };
        });
        if (sessionData?.practitionerRoleId) {
          speakers.push({
            isPatient: false,
            practitionerRoleId: sessionData.practitionerRoleId,
          });
        }
      }
      setSpeakers(speakers);
    }
  }, [sessionData]);
  useEffect(() => {
    if (speakers.length > 0) {
      props.getSessionSpeakerProfilesEnrollmentStatus({
        speakers,
        sessionCategory,
        sessionData,
        sessionId: props.sessionId,
      });
    }
  }, [sessionCategory, speakers]);
  const enrolledSpeakers = [];
  speakers.forEach(speaker => {
    if (speaker.isPatient) {
      let enrolledSpeaker = speakerProfileEnrollmentData?.find(
        i =>
          i.speakerType === "Patient" &&
          i.patientId === speaker.patientId &&
          !!i.speakerProfileEnrolled
      );
      if (enrolledSpeaker) {
        enrolledSpeakers.push(enrolledSpeaker);
      }
    }
  });
  const [startMessage, setStartMessage] = useState(true); // For showing the initial message
  const [completionMessage, setCompletionMessage] = useState(""); // To store the final message
  const [speakerEnrollmentStarted, setSpeakerEnrollmentStarted] = useState(false);
  const [enrollmentError, setEnrollmentError] = useState(false);
  const totalSpeakers = speakers.length;
  const enrolledCount = enrolledSpeakers.length;

  // Determine progress message
  const progressMessage =
    enrolledCount === totalSpeakers
      ? "Patient's voice print have been successfully enrolled!"
      : `Enrolling patient voice print...`;

  // Manage message logic on loading state changes
  useEffect(() => {
    if (loading) {
      setStartMessage(true); // Show start message initially
      setCompletionMessage(""); // Clear any previous completion message
      setSpeakerEnrollmentStarted(true);
      setEnrollmentError(false);
      const timer = setTimeout(() => {
        setStartMessage(false); // Switch to progress message after 3 seconds
      }, 3000);

      return () => clearTimeout(timer); // Clean up timeout
    } else if (!loading && totalSpeakers > 0) {
      // Set the final message based on error or success
      if (speakerEnrollmentStarted) {
        setCompletionMessage(
          enrolledCount === totalSpeakers
            ? "Patient's voice print have been successfully enrolled!"
            : "Patient's voice print could not be enrolled."
        );
        setEnrollmentError(enrolledCount !== totalSpeakers);
      } else {
        setCompletionMessage("");
        setEnrollmentError(false);
      }
    }
  }, [loading, totalSpeakers]);

  if ((!loading && !completionMessage) || props.sessionCategory === "group") {
    return null; // Do not show anything if no enrollment occurred
  }

  return (
    <div
      className={`speaker-enrollment-loader-container ${
        startMessage
          ? "enrollment-started"
          : !loading && completionMessage
          ? enrollmentError
            ? "enrollment-error"
            : "enrollment-success"
          : "enrollment-in-progress"
      }`}
    >
      <Loader loading={loading} />
      <div className="enrollment-loader-msg">
        {loading
          ? startMessage
            ? "Enrolling patient voice print..."
            : progressMessage
          : completionMessage}
      </div>
    </div>
  );
};

export default SpeakerEnrollmentLoader;
