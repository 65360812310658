import React, { useEffect, useState } from "react";
import "./GroupNotes.scss";
import { Avatar, RichTextEditor } from "@/components";
import "../../../GroupMemberInsights/components/MemberInsights/MemberInsights.scss";

function GroupNotes(props) {
  const [selectedMember, setSelectedMember] = useState(null);
  const data = props.data?.groupNotes || {};
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setSelectedMember(Object.keys(data)[0]);
    }
  }, [data]);
  let showSelectedMember = selectedMember || (data && Object.keys(data)?.[0]) || null || null;
  const getSpeakerName = speakerLabel => {
    let speakerName = speakerLabel;
    let speakerRecognitionData = props.speakerRecognition?.data?.[props.sessionId]
      ? props.speakerRecognition?.data?.[props.sessionId][
          Object.keys(props.speakerRecognition.data[props.sessionId])[0]
        ]
      : null;
    if (!speakerRecognitionData) {
      return speakerName;
    }
    let speakerData = speakerRecognitionData[speakerLabel] || null;
    if (!speakerData) {
      return speakerName;
    }
    if (speakerData.predictedLabel === "Provider") {
      if (props.sessionData?.practitionerFirstName) {
        speakerName = `${props.sessionData.practitionerFirstName || ""}${
          props.sessionData.practitionerLastName ? " " : ""
        }${props.sessionData.practitionerLastName || ""}`;
        return speakerName;
      }
    }
    let sessionPatientMemberDtos = props.sessionData?.sessionPatientMemberDtos || [];
    let speakerPatient = sessionPatientMemberDtos.find(
      i => i.patientId === speakerData.speakerUUID
    );
    if (speakerPatient) {
      speakerName = `${speakerPatient.patientFirstName || ""}${
        speakerPatient.patientLastName ? " " : ""
      }${speakerPatient.patientLastName || ""}`;
    }
    return speakerName;
  };
  return (
    <div className="member-insights-widget">
      <div className="member-insights-label">Patient Notes</div>
      <div className="member-list">
        {Object.keys(data).map((member, index) => {
          return (
            <div
              className={`member-item ${selectedMember === member ? "selected" : ""}`}
              key={member}
              onClick={() => setSelectedMember(member)}
            >
              <div className="avatar-container">
                <Avatar name={getSpeakerName(member)} />
                <span className="avatar-name">{getSpeakerName(member)}</span>
              </div>
            </div>
          );
        })}
      </div>
      <div className="insights-list">
        {showSelectedMember && (
          <div className="insights-item">
            <div className="member-top-container">
              <div className="avatar-container">
                <Avatar name={getSpeakerName(showSelectedMember)} />
                <span className="avatar-name">{getSpeakerName(showSelectedMember)}</span>
              </div>
            </div>

            <div className="insight-desc">
              <div className="individual-notes">{data[showSelectedMember]}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default GroupNotes;
