import axios from "axios";
import { getCookie, deleteCookie } from "./utils";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

let instance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    // Authorization: "Bearer " + getCookie("authtoken"),
  },
});

let rawInstance = axios.create({
  baseURL: API_BASE_URL,
});

function loginRedirect() {
  if (!window.location.href.includes("/login") && !window.location.href.includes("/signup")) {
    deleteCookie("authtoken");
    window.location.href = window.location.origin + "/login";
  }
  // return;
}

function notFoundRedirect() {
  // return redirect("/404");
}

const isAuthTokenPresent = () => {
  let cookie = getCookie("authtoken");
  if (!cookie) {
    loginRedirect();
    return false;
  } else if (!instance.defaults.headers.Authorization) {
    instance.defaults.headers.Authorization = "Bearer " + cookie;
  }
};

instance.interceptors.request.use(config => {
  let cookie = getCookie("authtoken");
  if (!config.headers.Authorization && cookie) {
    config.headers.Authorization = "Bearer " + cookie;
  }
  return config;
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log(error);
    if (error && error.response && error.response.status) {
      if (
        error.response.status === 403 ||
        (error.response.status === 500 &&
          (!!error.response.data?.message?.includes("JWT expired") ||
            !!error.response.data?.message?.includes("JWT validity")))
      ) {
        loginRedirect();
      }
    }
    return Promise.reject(error);
  }
);

export const get = (url, data, extraHeaders, getPayload) => {
  isAuthTokenPresent();
  return new Promise((resolve, reject) => {
    instance.get(url, { params: data, headers: extraHeaders }, extraHeaders, getPayload).then(
      res => {
        resolve(res);
      },
      err => {
        reject(err);
      }
    );
  });
};

export const rawGet = (url, data, extraHeaders) =>
  rawInstance.get(url, { params: data, headers: extraHeaders });
export const rawHead = (url, data) => rawInstance.head(url, { params: data });

export const post = (url, data, extraHeaders) => {
  isAuthTokenPresent();
  return new Promise((resolve, reject) => {
    let request;
    if (extraHeaders) {
      request = instance.post(url, data, extraHeaders);
    } else {
      request = instance.post(url, data);
    }
    request.then(
      res => {
        resolve(res);
      },
      err => {
        reject(err);
      }
    );
  });
};

export const rawPost = (url, data, extraHeaders) => {
  return new Promise((resolve, reject) => {
    let request;
    if (extraHeaders) {
      request = rawInstance.post(url, data, extraHeaders);
    } else {
      request = rawInstance.post(url, data);
    }
    request.then(
      res => {
        resolve(res);
      },
      err => {
        reject(err);
      }
    );
  });
};

export const put = (url, data) => {
  isAuthTokenPresent();
  return new Promise((resolve, reject) => {
    instance.put(url, data).then(
      res => {
        resolve(res);
      },
      err => {
        reject(err);
      }
    );
  });
};

export const patch = (url, data) => {
  isAuthTokenPresent();
  return new Promise((resolve, reject) => {
    instance.patch(url, data).then(
      res => {
        resolve(res);
      },
      err => {
        reject(err);
      }
    );
  });
};

export const deleteApi = (url, data) => {
  isAuthTokenPresent();
  return new Promise((resolve, reject) => {
    instance.delete(url, { data }).then(
      res => {
        resolve(res);
      },
      err => {
        reject(err);
      }
    );
  });
};
