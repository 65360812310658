import { get, post } from "@/services/api";
import { addLocalStorage, getLocalStorage, checkTodaySession } from "@/services/utils";
import { getSessionMemberNotes } from "../SessionDetails/sessionDetailsModule";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { io } from "socket.io-client";

const IN_SESSION_SOCKET_URL =
  import.meta.env.VITE_IN_SESSION_SOCKET_URL || "https://insession.prod.soulsidehealth.com";

let patientSocket = null;
let groupSocket = null;
let patientIdsForSocket = [];
let groupIdsForSocket = [];
let sessionSocketResetTimer = null;
let sessionNotesPdfStatusCheckStarted = {};
let sessionNotesGeneratedCheckStarted = {};

let sessionNotesGeneratedLocalData = getLocalStorage("session-notes-generated") || [];

export const getSessionsByPractitionerRole = options => {
  return async (dispatch, getState) => {
    let data = [];
    if (!options?.isBackgroundProcess) {
      dispatch({ type: "TOGGLE_SESSIONS_LOADER", show: true });
    }
    let state = getState();
    let practitionerRoleId = state.user.selectedUserRole?.id;
    let businessFunction = state.user.selectedUserRole?.businessFunction;
    let orgId = state.user.selectedUserRole?.organizationId;
    try {
      // let url = `practitioner-role/individual-session/find-all-by-org/${orgId}`;
      let url = `practitioner-role/individual-session/find-all-by-org-and-date-range/${orgId}?startDateTime=${options?.startDateTime}&endDateTime=${options?.endDateTime}`;
      if (businessFunction === "Clinical Care") {
        // url = `practitioner-role/individual-session/find-all-by-practitioner-role/${practitionerRoleId}`;
        url = `practitioner-role/individual-session/find-all-by-practitioner-role-and-date-range/${practitionerRoleId}?startDateTime=${options?.startDateTime}&endDateTime=${options?.endDateTime}`;
      }
      let response = await get(url);
      if (response?.data?.length > 0) {
        data = response.data;
        if (
          businessFunction !== "Clinical Care" &&
          state.user.selectedUserRole?.organizationName?.toLowerCase().includes("serenity")
        ) {
          // data = data.filter(i => i.practitionerFirstName === "Azfar");
        }
      }
    } catch (error) {}
    try {
      let url = `practitioner-role/sessions/find-all-by-org-and-date-range/${orgId}?startDateTime=${options?.startDateTime}&endDateTime=${options?.endDateTime}`;
      if (businessFunction === "Clinical Care") {
        url = `practitioner-role/sessions/find-all-by-practitioner-role-and-date-range/${practitionerRoleId}?startDateTime=${options?.startDateTime}&endDateTime=${options?.endDateTime}`;
      }
      let response = await get(url);
      if (response?.data?.length > 0) {
        data = [
          ...data,
          ...response.data.map(i => {
            return { ...i, sessionCategory: "GROUP" };
          }),
        ];
        // if (process.env.NODE_ENV !== "development") {

        // }
      }
    } catch (error) {}
    if (data?.length > 0) {
      dispatch(setupSessionSocket(data));
    }
    dispatch({ type: "ADD_SESSIONS_DATA", data });
    if (!options?.isBackgroundProcess) {
      dispatch({ type: "TOGGLE_SESSIONS_LOADER", show: false });
    }
    return data;
  };
};

export const getTodaysSessionsByPractitionerRole = options => {
  return async (dispatch, getState) => {
    let data = [];
    if (!options?.isBackgroundProcess) {
      dispatch({ type: "TOGGLE_TODAYS_SESSIONS_LOADER", show: true });
    }
    let state = getState();
    let practitionerRoleId = state.user.selectedUserRole?.id;
    let businessFunction = state.user.selectedUserRole?.businessFunction;
    let orgId = state.user.selectedUserRole?.organizationId;
    let preferredTimezone = state.user.preferredTimezone;
    let todaysDate = moment().tz(preferredTimezone?.name || "America/Los_Angeles");
    let startDateTime = moment.tz(todaysDate, preferredTimezone?.name || "America/Los_Angeles");
    startDateTime = startDateTime.hour(0);
    startDateTime = startDateTime.minute(0);
    startDateTime = startDateTime.second(0);
    startDateTime = startDateTime.format();
    let endDateTime = moment.tz(todaysDate, preferredTimezone?.name || "America/Los_Angeles");
    endDateTime = endDateTime.hour(23);
    endDateTime = endDateTime.minute(59);
    endDateTime = endDateTime.second(59);
    endDateTime = endDateTime.format();
    try {
      let url = `practitioner-role/individual-session/find-all-by-org-and-date-range/${orgId}?startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
      if (businessFunction === "Clinical Care") {
        url = `practitioner-role/individual-session/find-all-by-practitioner-role-and-date-range/${practitionerRoleId}?startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
      }
      let response = await get(url);
      if (response?.data?.length > 0) {
        data = response.data;
        if (
          businessFunction !== "Clinical Care" &&
          state.user.selectedUserRole?.organizationName?.toLowerCase().includes("serenity")
        ) {
          // data = data.filter(i => i.practitionerFirstName === "Azfar");
        }
      }
    } catch (error) {}
    try {
      let url = `practitioner-role/sessions/find-all-by-org-and-date-range/${orgId}?startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
      if (businessFunction === "Clinical Care") {
        url = `practitioner-role/sessions/find-all-by-practitioner-role-and-date-range/${practitionerRoleId}?startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
      }
      let response = await get(url);
      if (response?.data?.length > 0) {
        data = [
          ...data,
          ...response.data.map(i => {
            return { ...i, sessionCategory: "GROUP" };
          }),
        ];
      }
    } catch (error) {}
    if (data?.length > 0) {
      dispatch(setupSessionSocket(data));
    }
    dispatch({ type: "ADD_TODAYS_SESSIONS_DATA", data });
    if (!options?.isBackgroundProcess) {
      dispatch({ type: "TOGGLE_TODAYS_SESSIONS_LOADER", show: false });
    }
    return data;
  };
};

const setupSessionSocket = sessions => {
  return async (dispatch, getState) => {
    let state = getState();
    let preferredTimezone = state.user.preferredTimezone;
    let todaysSession = sessions.filter(checkTodaySession);
    todaysSession = todaysSession.sort(
      (session1, session2) => new Date(session1.startTime) - new Date(session2.startTime)
    );
    todaysSession.forEach(session => {
      if (session.sessionCategory === "INDIVIDUAL") {
        // if (!sessionNotesPdfStatusCheckStarted[session.id]) {
        //   dispatch(sessionNotesStatusCheck(session));
        // }
        if (session.patientId && !patientIdsForSocket.includes(session.patientId)) {
          patientIdsForSocket.push(session.patientId);
        }
      } else {
        if (session.groupId && !groupIdsForSocket.includes(session.groupId)) {
          groupIdsForSocket.push(session.groupId);
        }
      }
    });
    // sessions.forEach(async session => {
    //   // if (session.sessionCategory === "INDIVIDUAL") {
    //   if (
    //     !state.appointments?.sessions?.sessionNotesGenerated?.includes(session.id) &&
    //     !sessionNotesGeneratedCheckStarted[session.id]
    //   ) {
    //     // await dispatch(sessionNotesStatusCheck(session, true));
    //   }
    //   // }
    // });
    // dispatch(getSessionMemberNotesByOrgId(sessions));
    dispatch(setupPatientSocket({ patientIds: patientIdsForSocket }));
    dispatch(setupGroupSocket({ groupIds: groupIdsForSocket }));
    // let currentTime = moment().tz(preferredTimezone?.name || "America/Los_Angeles");
    // let endOfDay = moment.tz(preferredTimezone?.name || "America/Los_Angeles").endOf("day");
    // let secondsRemaining = endOfDay.diff(currentTime, "seconds") + 60;
    // if (sessionSocketResetTimer) {
    //   clearTimeout(sessionSocketResetTimer);
    // }
    // sessionSocketResetTimer = setTimeout(() => {
    //   if (patientSocket) {
    //     patientSocket.emit("practitioner-role-leave-patient-rooms", patientIdsForSocket);
    //   }
    //   patientIdsForSocket = [];
    //   if (groupSocket) {
    //     groupSocket.emit("practitioner-role-leave-group-rooms", groupIdsForSocket);
    //   }
    //   groupIdsForSocket = [];
    //   dispatch(setupSessionSocket(sessions));
    // }, secondsRemaining * 1000);
  };
};

const setupPatientSocket = options => {
  let retryCount = options?.retryCount || 0;
  return async dispatch => {
    let patientIds = options.patientIds;
    try {
      if (!patientSocket) {
        await dispatch(createPatientSocketConnection(patientIds));
      } else {
        patientSocket.emit("practitioner-role-join-patient-rooms", patientIds);
      }
      // patientSocket.emit("practitioner-role-join-patient-rooms", patientIds);
      patientSocket.on("patient-status-update", data => {
        dispatch({ type: "ADD_PATIENT_STATUS_DATA", data });
      });
      patientSocket.on("send-mental-wellness-data", data => {
        dispatch({ type: "ADD_PATIENT_MENTAL_WELLNESS_DATA", data });
      });
      patientSocket.on("session-notes-status-update", data => {
        let sessionId = data?.individualSessionId;
        if (sessionId && data?.status === "created") {
          dispatch({
            type: "ADD_SESSION_NOTES_GENERATED",
            sessionId,
          });
          if (!sessionNotesGeneratedLocalData.includes(sessionId)) {
            sessionNotesGeneratedLocalData.push(sessionId);
            addLocalStorage("session-notes-generated", sessionNotesGeneratedLocalData);
          }
        }
      });
    } catch (error) {
      if (retryCount < 3) {
        retryCount = retryCount + 1;
        dispatch(setupPatientSocket({ ...options, retryCount }));
      } else {
        // console.log(error);
      }
    }
  };
};

const setupGroupSocket = options => {
  let retryCount = options?.retryCount || 0;
  return async dispatch => {
    let groupIds = options.groupIds;
    try {
      if (!groupSocket) {
        await dispatch(createGroupSocketConnection(groupIds));
      } else {
        groupSocket.emit("practitioner-role-join-group-rooms", groupIds);
      }
      // groupSocket.emit("practitioner-role-join-group-rooms", groupIds);
      groupSocket.on("group-status-update", data => {
        dispatch({ type: "ADD_GROUP_STATUS_DATA", data });
      });
      groupSocket.on("send-mental-wellness-data", data => {
        dispatch({ type: "ADD_GROUP_MENTAL_WELLNESS_DATA", data });
      });
    } catch (error) {
      if (retryCount < 3) {
        retryCount = retryCount + 1;
        dispatch(setupGroupSocket({ ...options, retryCount }));
      } else {
        console.log(error);
      }
    }
  };
};

const createPatientSocketConnection = patientIds => {
  return async (dispatch, getState) => {
    let state = getState();
    let selectedUserRole = state.user.selectedUserRole;
    let organizationId = selectedUserRole?.organizationId;
    let practitionerRoleId = selectedUserRole?.id;
    return new Promise((resolve, reject) => {
      patientSocket = io(IN_SESSION_SOCKET_URL + "/patient", {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 10,
        query: {
          organizationId,
          practitionerRoleId,
          practitionerRoleClient: true,
        },
      });

      patientSocket.on("connect", () => {
        patientSocket.emit("practitioner-role-join-patient-rooms", patientIds);
        // console.log("patient socket connected");
        resolve(patientSocket); // Resolve the promise with the socket object
      });

      patientSocket.on("connect_error", err => {
        // console.log(err.message);
        // console.log(err.description);
        // console.log(err.context);
        reject(err); // Reject the promise with the error
      });

      patientSocket.on("error", err => {
        // console.log("Socket encountered an error", err);
        reject(err); // Handle general socket errors and reject
      });
    });
  };
};

const createGroupSocketConnection = groupIds => {
  return async (dispatch, getState) => {
    let state = getState();
    let selectedUserRole = state.user.selectedUserRole;
    let organizationId = selectedUserRole?.organizationId;
    let practitionerRoleId = selectedUserRole?.id;
    return new Promise((resolve, reject) => {
      groupSocket = io(IN_SESSION_SOCKET_URL + "/group", {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 10,
        query: {
          organizationId,
          practitionerRoleId,
          practitionerRoleClient: true,
        },
      });

      groupSocket.on("connect", () => {
        groupSocket.emit("practitioner-role-join-group-rooms", groupIds);
        // console.log("group socket connected");
        resolve(groupSocket); // Resolve the promise with the socket object
      });

      groupSocket.on("connect_error", err => {
        // console.log(err.message);
        // console.log(err.description);
        // console.log(err.context);
        reject(err); // Reject the promise with the error
      });

      groupSocket.on("error", err => {
        // console.log("Socket encountered an error", err);
        reject(err); // Handle general socket errors and reject
      });
    });
  };
};

export const sendDelayMessage = async options => {
  const message = options?.message || "";
  const patientPhoneNumber = options?.patientPhoneNumber || "";
  const isGroupSession = options?.isGroupSession || "";
  const groupId = options.groupId || "";
  const patientId = options?.patientId || "";
  if (!isGroupSession) {
    if (!!patientSocket) {
      patientSocket.emit("send-session-delay-message", patientId, message);
      if (message && patientPhoneNumber) {
        let url = "practitioner-role/patient/send-text-to-phone";
        let payload = {
          contactPhone: patientPhoneNumber,
          mode: "AUTO",
          text: message,
        };
        try {
          let response = await post(url, payload);
        } catch (error) {
          console.log(error);
        }
      }
    }
  } else {
    if (!!groupSocket) {
      groupSocket.emit("send-session-delay-message", groupId, message);
    }
  }
  if (message) {
    toast.success("Message Sent");
  }
};

export const sendLiveTranscript = async options => {
  const payload = options?.payload || {};
  const patientId = options?.patientId || "";
  if (!!patientSocket) {
    patientSocket.emit("send-live-transcript", patientId, payload);
  }
};

export const sendNetworkStatData = async options => {
  const payload = options?.payload || {};
  const patientId = options?.patientId || "";
  if (!!patientSocket) {
    patientSocket.emit("send-network-stat", patientId, payload);
  }
};

export const sendMeetingJoinedEvent = async options => {
  const isGroupSession = options?.isGroupSession || "";
  const groupId = options?.groupId || "";
  const patientId = options?.patientId || "";
  if (!isGroupSession) {
    if (!!patientSocket) {
      patientSocket.emit("send-meeting-started-message", patientId);
    }
  } else {
    if (!!groupSocket) {
      groupSocket.emit("send-meeting-started-message", groupId);
    }
  }
};

export const getSessionMemberNotesByOrgId = sessions => {
  return async (dispatch, getState) => {
    let state = getState();
    let organizationId = state.user.selectedUserRole?.organizationId;
    let url = `practitioner-role/session-member-notes/organization/${organizationId}/find`;
    let sessionMemberNotesData = [];
    try {
      let response = await get(url);
      if (response?.data?.length > 0) {
        sessionMemberNotesData = response.data;
      }
    } catch (error) {}
    sessions.forEach(session => {
      if (session.sessionCategory === "INDIVIDUAL") {
        let sessionId = session.id;
        let sessionNotes = sessionMemberNotesData.find(i => i.sessionId === sessionId);
        if (
          sessionNotes &&
          sessionNotes.jsonSoapNote &&
          Object.keys(sessionNotes.jsonSoapNote).length > 1
        ) {
          let soapNotesJsonData = sessionNotes.jsonSoapNote;
          dispatch({
            type: "ADD_SESSION_NOTES_GENERATED",
            sessionId: sessionId,
          });
          if (!sessionNotesGeneratedLocalData.includes(sessionId)) {
            sessionNotesGeneratedLocalData.push(sessionId);
            addLocalStorage("session-notes-generated", sessionNotesGeneratedLocalData);
          }
        } else {
          // if (checkTodaySession(session)) {
          //   setTimeout(() => {
          //     dispatch(sessionNotesStatusCheck(session, true));
          //   }, 120000);
          // }
        }
      }
    });
  };
};

export const sessionNotesStatusCheck = (session, forSessionTag = false) => {
  return async (dispatch, getState) => {
    let sessionId = session.id;
    if (!forSessionTag) {
      sessionNotesPdfStatusCheckStarted = {
        ...sessionNotesPdfStatusCheckStarted,
        [sessionId]: true,
      };
    } else {
      sessionNotesGeneratedCheckStarted = {
        ...sessionNotesGeneratedCheckStarted,
        [sessionId]: true,
      };
    }
    let patientId = session.sessionCategory === "INDIVIDUAL" ? session.patientId : session.groupId;
    let sessionNotesDownloadedData = getLocalStorage("session-notes-downloaded") || {};
    if (!forSessionTag) {
      if (!sessionNotesDownloadedData[sessionId]) {
        let { soapNotesJsonData } = await dispatch(
          getSessionMemberNotes({ sessionId, patientId, noAction: true })
        );
        dispatch({
          type: "ADD_SESSION_NOTES_PDF_STATUS",
          session,
          sessionId,
          notes: soapNotesJsonData,
        });
        // if (
        //   !soapNotesJsonData ||
        //   Object.keys(soapNotesJsonData).length === 0 ||
        //   Object.keys(soapNotesJsonData).length === 1
        // ) {
        //   if (checkTodaySession(session)) {
        //     setTimeout(() => {
        //       dispatch(sessionNotesStatusCheck(session));
        //     }, 120000);
        //   }
        // }
      }
    } else {
      let state = getState();
      if (!state.appointments?.sessions?.sessionNotesGenerated?.includes(sessionId)) {
        let { soapNotesJsonData } = await dispatch(
          getSessionMemberNotes({ sessionId, patientId, groupId: session.groupId, noAction: true })
        );
        let notesReady =
          soapNotesJsonData &&
          soapNotesJsonData.subjective &&
          soapNotesJsonData.objective &&
          soapNotesJsonData.assessment;
        if (session.appointmentType === "INTAKE") {
          notesReady = !!soapNotesJsonData?.["intake-assessment"]?.intakeHPINote;
        }
        if (session.sessionCategory === "GROUP") {
          notesReady =
            !!soapNotesJsonData?.["groupNotes"] &&
            Object.keys(soapNotesJsonData["groupNotes"]).length > 0;
        }
        if (notesReady) {
          dispatch({
            type: "ADD_SESSION_NOTES_GENERATED",
            sessionId,
          });
          if (!sessionNotesGeneratedLocalData.includes(sessionId)) {
            sessionNotesGeneratedLocalData.push(sessionId);
            addLocalStorage("session-notes-generated", sessionNotesGeneratedLocalData);
          }
        } else {
          // if (checkTodaySession(session)) {
          //   setTimeout(() => {
          //     dispatch(sessionNotesStatusCheck(session, true));
          //   }, 120000);
          // }
        }
      }
    }
  };
};

const initState = {
  sessions: {
    data: [],
    patientStatus: {},
    groupStatus: {},
    patientMentalWellnessData: null,
    groupMentalWellnessData: null,
    sessionNotesGenerated: sessionNotesGeneratedLocalData || [],
    loading: false,
  },
  todaysSessions: {
    data: [],
    loading: false,
  },
  sessionNotesPdfStatus: {},
};

const ACTION_HANDLERS = {
  ["TOGGLE_SESSIONS_LOADER"]: (state, action) => {
    return {
      ...state,
      sessions: {
        ...state.sessions,
        loading: action.show,
      },
    };
  },
  ["ADD_SESSIONS_DATA"]: (state, action) => {
    return {
      ...state,
      sessions: {
        ...state.sessions,
        data: action.data,
      },
    };
  },
  ["TOGGLE_TODAYS_SESSIONS_LOADER"]: (state, action) => {
    return {
      ...state,
      todaysSessions: {
        ...state.todaysSessions,
        loading: action.show,
      },
    };
  },
  ["ADD_TODAYS_SESSIONS_DATA"]: (state, action) => {
    return {
      ...state,
      todaysSessions: {
        ...state.todaysSessions,
        data: action.data,
      },
    };
  },
  ["ADD_PATIENT_STATUS_DATA"]: (state, action) => {
    return {
      ...state,
      sessions: {
        ...state.sessions,
        patientStatus: {
          ...state.sessions.patientStatus,
          [action.data.patientId]: {
            patientId: action.data.patientId,
            status: action.data.status,
            updatedAt: action.data.updatedAt,
          },
        },
      },
    };
  },
  ["ADD_GROUP_STATUS_DATA"]: (state, action) => {
    return {
      ...state,
      sessions: {
        ...state.sessions,
        groupStatus: {
          ...state.sessions.groupStatus,
          [action.data.groupId]: {
            groupId: action.data.groupId,
            patients: action.data.patients,
            updatedAt: action.data.updatedAt,
          },
        },
      },
    };
  },
  ["ADD_PATIENT_MENTAL_WELLNESS_DATA"]: (state, action) => {
    return {
      ...state,
      sessions: {
        ...state.sessions,
        patientMentalWellnessData: {
          ...state.sessions.patientMentalWellnessData,
          [action.data?.patientId]: action.data,
        },
      },
    };
  },
  ["ADD_GROUP_MENTAL_WELLNESS_DATA"]: (state, action) => {
    return {
      ...state,
      sessions: {
        ...state.sessions,
        groupMentalWellnessData: {
          ...state.sessions.groupMentalWellnessData,
          [action.data?.groupId]: action.data,
        },
      },
    };
  },
  ["ADD_SESSION_NOTES_PDF_STATUS"]: (state, action) => {
    return {
      ...state,
      sessionNotesPdfStatus: {
        ...state.sessionNotesPdfStatus,
        [action.sessionId]: {
          session: action.session,
          notes: action.notes,
        },
      },
    };
  },
  ["ADD_SESSION_NOTES_GENERATED"]: (state, action) => {
    return {
      ...state,
      sessions: {
        ...state.sessions,
        sessionNotesGenerated: !state.sessions.sessionNotesGenerated.includes(action.sessionId)
          ? [...state.sessions.sessionNotesGenerated, action.sessionId]
          : state.sessions.sessionNotesGenerated,
      },
    };
  },
};

export default function appointments(state = initState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
