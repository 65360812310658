import React, { useState, useEffect } from "react";
import { Loader, PrimaryButton, RichTextEditor, SecondaryButton } from "@/components";
import { toast } from "react-toastify";
import "./Narrative.scss";

import { narrativeData as nData } from "@/services/dummyData";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

function Narrative(props) {
  // const [narrativeData, setNarativeData] = useState(nData);
  const [narrativeData, setNarativeData] = useState("");
  const [generateSoapLoading, setGenerateSoapLoading] = useState(false);
  const [soapNotesJson, setSoapNotesJson] = useState(null);
  useEffect(() => {
    setSoapNotesJson(props.soapNotesJson || null);
    setNarativeData(props.soapNotesJson?.narrative || "");
  }, [props.soapNotesJson]);
  const providerName = `${props.sessionData?.practitionerFirstName || ""}${
    props.sessionData?.practitionerLastName ? " " : ""
  }${props.sessionData?.practitionerLastName || ""}`;
  const patientName = `${props.sessionData?.patientFirstName || ""}${
    props.sessionData?.patientLastName ? " " : ""
  }${props.sessionData?.patientLastName || ""}`;
  const generateNarrative = async (regenrate = false) => {
    let sessionId = props.sessionId;
    let notesId = props.notesId || null;
    let groupId = props?.groupId;
    let patientId = props?.patientId;
    let sessionCategory = props.sessionCategory;
    if (sessionId) {
      const providerSessionsTranscriptData = props.transcriptData || [];
      const transcriptData = providerSessionsTranscriptData.reduce(
        (acc, providerSessionsTranscript) => {
          const sessionTranscripts = providerSessionsTranscript.transcriptData;
          return acc.concat(sessionTranscripts);
        },
        []
      );
      let transcriptText = "";
      transcriptData.forEach(transcript => {
        let memberName = transcript.memberName;
        if (
          ["provider", "patient"].includes(memberName?.toLowerCase()) &&
          props.sessionCategory === "individual"
        ) {
          if (memberName?.toLowerCase() === "provider" && providerName) {
            memberName = providerName;
          }
          if (memberName?.toLowerCase() === "patient" && patientName) {
            memberName = patientName;
          }
        }
        transcriptText += `${memberName}: ${transcript.transcriptText}\n\n`;
      });
      let payload = {
        transcript: transcriptText,
      };
      setGenerateSoapLoading(true);
      await props.generateNarrative({
        sessionId,
        groupId,
        patientId,
        payload,
        regenrate,
        sessionCategory,
        notesId,
        narrativeData,
        soapNote: props.soapNote,
        soapNotesJson: soapNotesJson,
      });
      setGenerateSoapLoading(false);
    }
  };
  const saveNarrative = () => {
    let sessionId = props.sessionId;
    let groupId = props?.groupId;
    let patientId = props?.patientId;
    let notesId = props.notesId || null;
    props.saveNarrativeData({
      groupId,
      patientId,
      sessionId,
      soapNote: props.soapNote,
      notesId,
      narrativeData,
      soapNotesJson: soapNotesJson,
    });
  };
  return (
    <Loader loading={props.loading || props.updateLoading}>
      <div className="narrative-container">
        <div className="narrative-header">
          <div className="save-narrative-container">
            <SecondaryButton onClick={() => generateNarrative(!!narrativeData)}>
              {narrativeData ? "Regenerate" : "Generate"} Narrative
            </SecondaryButton>
            <PrimaryButton onClick={saveNarrative}>Save Changes</PrimaryButton>
          </div>
        </div>
        <div className="narrative-rich-container">
          {/* <RichTextEditor
            value={narrativeData}
            setValue={setNarativeData}
          /> */}
          <textarea
            value={narrativeData}
            onChange={e => setNarativeData(e.target.value)}
          />
        </div>
      </div>
    </Loader>
  );
}

export default Narrative;
