import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import "./TranscriptLoader.scss";
import { PrimaryButton } from "@/components";

function TranscriptLoader(props) {
  const [secondsRemaining, setSecondsRemaing] = useState(240);
  useEffect(() => {
    setSecondsRemaing(secondsRemaining);
    let intervalId = setInterval(() => {
      setSecondsRemaing(sec => sec - 1);
    }, 1000);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);
  if (!props.loading) {
    return props.children;
  }
  const minutesRemaing = Math.ceil(secondsRemaining / 60);
  return (
    <div className="transcript-loader-container">
      <div className="loading-msg">Please wait while we fetch the transcript for the session.</div>
      <div className="progress-container">
        <ProgressBar
          animated
          now={600 - secondsRemaining}
        />
      </div>

      <div className="review-later-container">
        <div className="loading-time-remaining">
          {/* About {minutesRemaing} minute{minutesRemaing > 1 ? "s" : ""} left */}
          It usually takes around 2-3 minutes to generate the transcript
        </div>
        <div className="review-later-msg">
          You can also review your notes later from the <strong>Pending Tasks</strong> widget on the{" "}
          <strong>Dashboard</strong> page
        </div>
        <Link to={"/"}>
          <PrimaryButton>Review Later</PrimaryButton>
        </Link>
      </div>
    </div>
  );
}

export default TranscriptLoader;
