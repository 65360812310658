import React, { useState, useEffect, useMemo } from "react";
import moment from "moment-timezone";
import { Loader } from "../Loader";
import "./TreatmentPlanV2.scss";
import { PrimaryButton, SecondaryButton, TertiaryButton } from "../Buttons";
import TreatmentPlanModal from "./TreatmentPlanModal";

function TreatmentPlanV2(props) {
  const [generateTreatmentPlanModalOpen, setGenerateTreatmentPlanModalOpen] = useState(false);
  const patientId = props.patientId;
  useEffect(() => {
    props.getTreatmentPlan({ patientId });
  }, []);
  const lastUpdatedOn = props.treatmentPlan?.[patientId]?.data?.updatedAt
    ? moment(props.treatmentPlan[patientId].data.updatedAt)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMM DD, YYYY")
    : moment()
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMM DD, YYYY");
  const intakeSessionDate = props.treatmentPlan?.[patientId]?.data?.plan?.intakeSession?.startTime
    ? moment(props.treatmentPlan[patientId].data.plan.intakeSession.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMM DD, YYYY")
    : "-";
  const generateTreatmentPlanAI = () => {
    props.generateTreatmentPlanAI({ patientId });
  };
  const treatmentPlanData = props.treatmentPlan?.[patientId]?.data?.plan?.treatmentPlanData;
  return (
    <>
      <Loader loading={props.treatmentPlan?.[patientId]?.loading}>
        <div className="treatment-plan-container">
          {!!treatmentPlanData ? (
            <>
              <div className="treatment-plan-header">
                <div className="treatment-plan-meta-container">
                  <div className="treatment-plan-last-updated">
                    Intake Date: {intakeSessionDate}
                  </div>
                  <div className="treatment-plan-last-updated">
                    Last updated on: {lastUpdatedOn}
                  </div>
                </div>
                {!props.noAction && (
                  <div className="action-btns">
                    <TertiaryButton onClick={() => generateTreatmentPlanAI()}>
                      Regenerate Treatment Plan
                    </TertiaryButton>
                    <SecondaryButton onClick={() => setGenerateTreatmentPlanModalOpen(true)}>
                      Revise Treatment Plan
                    </SecondaryButton>
                  </div>
                )}
              </div>
              <div className="treatment-plan-content-container">
                {treatmentPlanData.problems?.length > 0 &&
                  treatmentPlanData.problems?.[0]?.description && (
                    <div className="treatment-plan-problems-container">
                      <div className="treatment-plan-problems-label">Problems</div>
                      <ol className="treatment-plan-problems-list">
                        {treatmentPlanData.problems.map((problem, index) => {
                          if (!problem.description) {
                            return <></>;
                          }
                          return (
                            <li
                              className="treatment-plan-problem-item"
                              key={index}
                            >
                              <div className="treatment-plan-input-container">
                                <div className="treatment-plan-input">{problem.description}</div>
                              </div>
                              {problem?.goals?.length > 0 && problem.goals?.[0]?.description && (
                                <div className="treatment-plan-problems-container">
                                  <div className="treatment-plan-problems-label">Goals</div>
                                  <ol className="treatment-plan-problems-list">
                                    {problem.goals.map((goal, index) => {
                                      return (
                                        <li
                                          className="treatment-plan-problem-item"
                                          key={index}
                                        >
                                          <div className="treatment-plan-input-container with-textarea">
                                            <div className="treatment-plan-input">
                                              {goal.description || "-"}
                                            </div>
                                          </div>
                                          {goal.objectives?.length > 0 &&
                                            goal.objectives?.[0]?.description && (
                                              <div className="treatment-plan-problems-container">
                                                <div
                                                  className="treatment-plan-problems-label"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  Objectives:
                                                </div>
                                                <ol className="treatment-plan-problems-list">
                                                  {goal.objectives.map((objective, index) => {
                                                    return (
                                                      <li
                                                        className="treatment-plan-problem-item"
                                                        key={index}
                                                      >
                                                        <div className="treatment-plan-input-container with-textarea">
                                                          <div className="treatment-plan-input">
                                                            {objective.description || "-"}
                                                          </div>
                                                          {objective.timeline && (
                                                            <div className="treatment-objective-timeline">
                                                              <strong style={{ fontWeight: 600 }}>
                                                                Timeline:
                                                              </strong>{" "}
                                                              {objective.timeline}
                                                            </div>
                                                          )}
                                                        </div>
                                                      </li>
                                                    );
                                                  })}
                                                </ol>
                                              </div>
                                            )}
                                        </li>
                                      );
                                    })}
                                  </ol>
                                </div>
                              )}
                              {problem?.interventions?.length > 0 &&
                                problem?.interventions?.[0] && (
                                  <div className="treatment-plan-problems-container">
                                    <div className="treatment-plan-problems-label">
                                      Interventions
                                    </div>
                                    <ol className="treatment-plan-problems-list">
                                      {problem.interventions.map((intervention, index) => {
                                        return (
                                          <li
                                            className="treatment-plan-problem-item"
                                            key={index}
                                          >
                                            <div className="treatment-plan-input-container">
                                              <div className="treatment-plan-input">
                                                {intervention}
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      })}
                                    </ol>
                                  </div>
                                )}
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                  )}
              </div>
            </>
          ) : (
            <div className="generate-treatment-plan-container">
              <div className="no-treatment-plan">No Treatment Plan Created</div>
              {!props.noAction && (
                <PrimaryButton onClick={() => generateTreatmentPlanAI()}>
                  Generate Treatment Plan
                </PrimaryButton>
              )}
            </div>
          )}
        </div>
      </Loader>
      <TreatmentPlanModal
        modalOpen={generateTreatmentPlanModalOpen}
        toggleModal={setGenerateTreatmentPlanModalOpen}
        treatmentPlan={props.treatmentPlan?.[patientId]}
        generateTreatmentPlan={props.generateTreatmentPlan}
        patientId={patientId}
        selectedUserRole={props.selectedUserRole}
      />
    </>
  );
}

export default TreatmentPlanV2;
