import { get, post, rawPost } from "@/services/api";
import {
  getSessionDetailsTranscript,
  getSessionMemberNotes,
  getSessionSpeakerRecognitionStatus,
} from "../SessionDetails/sessionDetailsModule";

export const getSessionDetails = options => {
  return async dispatch => {
    let url = `practitioner-role/sessions/find-by-id/${options?.sessionId}`;
    if (options?.sessionCategory === "individual") {
      url = `practitioner-role/individual-session/find-by-id/${options?.sessionId}`;
    }
    let sessionDetails = null;
    dispatch({ type: "TOGGLE_MEETING_SESSIONS_LOADER", show: true });
    try {
      let response = await get(url);
      if (response?.data) {
        sessionDetails = response.data;
      }
    } catch (error) {}
    dispatch({ type: "ADD_MEETING_SESSIONS_DATA", data: sessionDetails });
    dispatch({ type: "TOGGLE_MEETING_SESSIONS_LOADER", show: false });
  };
};

export const getMeetingToken = options => {
  let tokenRetry = options?.tokenRetry || 0;
  return (dispatch, getState) => {
    let state = getState();
    let practitionerRoleId = state.user.selectedUserRole?.id;
    let url = "practitioner-role/meeting/individual-session/auth-token";
    let payload = {
      patientId: options?.patientId,
      practitionerRoleId,
    };
    if (options?.sessionCategory === "group") {
      url = "practitioner-role/meeting/groups/auth-token";
      payload = {
        groupId: options?.groupId,
        practitionerRoleId,
      };
    }
    dispatch({ type: "TOGGLE_MEETING_TOKEN_LOADER", show: true });
    post(url, payload)
      .then(response => {
        if (response?.data) {
          if (options?.sessionCategory === "group") {
            dispatch({
              type: "ADD_MEETING_TOKEN",
              token: response.data,
              id: options?.groupId,
              sessionCategory: "group",
            });
          } else {
            dispatch({
              type: "ADD_MEETING_TOKEN",
              token: response.data,
              id: options?.patientId,
              sessionCategory: "individual",
            });
          }
        }
        dispatch({ type: "TOGGLE_MEETING_TOKEN_LOADER", show: false });
      })
      .catch(error => {
        console.log(error);
        if (tokenRetry < 3) {
          tokenRetry = tokenRetry + 1;
          dispatch(getMeetingToken({ ...options, tokenRetry }));
        } else {
          dispatch({ type: "TOGGLE_MEETING_TOKEN_LOADER", show: false });
        }
        // alert(JSON.stringify(error));
      });
  };
};

export const getMemberNotes = options => {
  return dispatch => {
    let url = `practitioner-role/member-notes/${options?.sessionId}/find`;
    dispatch({ type: "TOGGLE_IN_SESSION_NOTES_LOADER", show: true });
    get(url)
      .then(response => {
        if (response?.data) {
          dispatch({ type: "ADD_IN_SESSION_NOTES", data: response.data });
        }
        dispatch({ type: "TOGGLE_IN_SESSION_NOTES_LOADER", show: false });
      })
      .catch(() => {
        dispatch({ type: "TOGGLE_IN_SESSION_NOTES_LOADER", show: false });
      });
  };
};

export const mapProviderSessionId = options => {
  let tokenRetry = options?.tokenRetry || 0;
  return async dispatch => {
    let url = "practitioner-role/meeting-session/reconcile-provider-session";
    let payload = {
      providerSessionId: options?.providerSessionId,
      providerMeetingId: options?.providerMeetingId || null,
      sessionCategory: options?.sessionCategory === "individual" ? "INDIVIDUAL" : "GROUP",
      modeOfDelivery: options?.modeOfDelivery === "virtual" ? "VIRTUAL" : "IN_PERSON",
      startedAt: new Date().toISOString(),
    };
    let meetingSessionData = null;
    dispatch({ type: "TOGGLE_MEETING_SESSION_LOADER", show: true });
    if (options?.sessionCategory === "individual") {
      payload = {
        ...payload,
        individualSessionId: options?.sessionId,
        patientId: options?.patientId,
      };
    } else {
      payload = {
        ...payload,
        sessionId: options?.sessionId,
        groupId: options?.groupId,
      };
    }
    try {
      let response = await post(url, payload);
      if (response?.data) {
        meetingSessionData = response.data;
      }
    } catch (error) {
      if (tokenRetry < 10) {
        tokenRetry = tokenRetry + 1;
        dispatch(mapProviderSessionId({ ...options, tokenRetry }));
      } else {
        dispatch({ type: "ADD_MEETING_SESSION_DATA", data: meetingSessionData });
        dispatch({ type: "TOGGLE_MEETING_SESSION_LOADER", show: false });
      }
      console.log(error);
    }
  };
};

export const changeMeetingSession = options => {
  return async dispatch => {
    let url = "practitioner-role/meeting-session/update-meeting-session";
    let payload = options?.payload || {};
    dispatch({ type: "ADD_MEETING_SESSION_DATA", data: payload });
    try {
      await post(url, payload);
    } catch (error) {
      console.log(error);
    }
  };
};

export const getPatientNotes = options => {
  return async (dispatch, getState) => {
    let state = getState();
    let orgId = state.user.selectedUserRole?.organizationId;
    let patientId = options.patientId;
    dispatch({
      type: "SESSION_PATIENT_NOTES_LOADER",
      patientId,
      noteType: "individualSessionNotes",
      show: true,
    });
    let { patientSessionMemberNotes: patientIndividualSessionNotes, patientBhPredictions } =
      await getPatientIndividualSessionMemberNotes(patientId);
    dispatch({
      type: "ADD_SESSION_PATIENT_NOTES_DATA",
      patientId,
      noteType: "individualSessionNotes",
      data: patientIndividualSessionNotes,
    });
    dispatch({
      type: "ADD_SESSION_PATIENT_NOTES_DATA",
      patientId,
      noteType: "bhPredictions",
      data: patientBhPredictions,
    });
    dispatch({
      type: "SESSION_PATIENT_NOTES_LOADER",
      patientId,
      noteType: "individualSessionNotes",
      show: false,
    });
    dispatch({
      type: "SESSION_PATIENT_NOTES_LOADER",
      patientId,
      noteType: "groupSessionNotes",
      show: true,
    });
    let patientGroupSessions = await getPatientGroupSessions(patientId, orgId);
    let patientGroupSessionNotes = await dispatch(
      getPatientGroupSessionsNotes(patientId, patientGroupSessions)
    );
    dispatch({
      type: "ADD_SESSION_PATIENT_NOTES_DATA",
      patientId,
      noteType: "groupSessionNotes",
      data: patientGroupSessionNotes,
    });
    dispatch({
      type: "SESSION_PATIENT_NOTES_LOADER",
      patientId,
      noteType: "groupSessionNotes",
      show: false,
    });
    return {
      patientId,
      individualSessionNotes: patientIndividualSessionNotes,
      groupSessionNotes: patientGroupSessionNotes,
    };
  };
};

const getPatientIndividualSessionMemberNotes = async patientId => {
  let patientSessionMemberNotes = [];
  let patientBhPredictions = [];
  let url = `practitioner-role/session-member-notes/patient/${patientId}/find`;
  try {
    let response = await get(url);
    if (response?.data?.length > 0) {
      patientSessionMemberNotes = response.data;
    }
    patientSessionMemberNotes = patientSessionMemberNotes.sort(
      (notes1, notes2) => new Date(notes2.createdAt) - new Date(notes1.createdAt)
    );
    patientSessionMemberNotes = patientSessionMemberNotes.filter(patientNotes => {
      if (
        patientNotes?.jsonSoapNote &&
        (((patientNotes.jsonSoapNote.subjective || patientNotes.jsonSoapNote.Subjective) &&
          (patientNotes.jsonSoapNote.assessment || patientNotes.jsonSoapNote.Assessment) &&
          (patientNotes.jsonSoapNote.objective || patientNotes.jsonSoapNote.Objective)) ||
          patientNotes.jsonSoapNote.narrative)
      ) {
        return true;
      }
      return false;
    });
    patientBhPredictions = patientSessionMemberNotes.filter(
      patientNotes => !!patientNotes.behaviouralHealthPredictions
    );
  } catch (error) {}
  patientSessionMemberNotes = patientSessionMemberNotes.map(patientNotes => {
    let jsonSoapNote = patientNotes?.jsonSoapNote ? { ...patientNotes.jsonSoapNote } : null;
    if (jsonSoapNote?.Subjective) {
      jsonSoapNote.subjective = jsonSoapNote.Subjective;
      delete jsonSoapNote.Subjective;
    }
    if (jsonSoapNote?.Assessment) {
      jsonSoapNote.assessment = jsonSoapNote.Assessment;
      delete jsonSoapNote.Assessment;
    }
    if (jsonSoapNote?.Objective) {
      jsonSoapNote.objective = jsonSoapNote.Objective;
      delete jsonSoapNote.Objective;
    }
    return {
      ...patientNotes,
      jsonSoapNote,
    };
  });
  return { patientSessionMemberNotes, patientBhPredictions };
};

const getPatientGroupSessions = async (patientId, orgId) => {
  let groupSessions = [];
  let url = `practitioner-role/sessions/find-all-by-org/${orgId}`;
  try {
    let response = await get(url);
    if (response?.data?.length > 0) {
      groupSessions = response.data.filter(groupSession => {
        let isPatientInSession = !!groupSession.sessionPatientMemberDtos?.find?.(
          i => i.patientId === patientId
        );
        return isPatientInSession;
      });
    }
  } catch (error) {}
  return groupSessions;
};

const getPatientGroupSessionsNotes = (patientId, sessions) => {
  return async dispatch => {
    let patientGroupSessionNotes = [];
    // for (let i = 0; i < sessions.length; i++) {
    //   let groupSession = sessions[i];
    //   let groupSessionId = groupSession.id;
    //   let groupId = groupSession.groupId;
    //   const { soapNotesJsonData } = await dispatch(
    //     getSessionMemberNotes({ sessionId: groupSessionId, patientId, groupId, noAction: true })
    //   );
    //   if (soapNotesJsonData?.groupNotes && Object.keys(soapNotesJsonData.groupNotes).length > 0) {
    //     let sessionTranscript = await dispatch(
    //       getSessionDetailsTranscript({
    //         sessionCategory: "group",
    //         sessionId: groupSessionId,
    //         noAction: true,
    //       })
    //     );
    //     if (sessionTranscript?.length > 0) {
    //       let providerSessionsSpeakers = [];
    //       sessionTranscript.forEach(providerSession => {
    //         let providerSessionTranscriptData = providerSession.transcriptData;
    //         let uniqueSpeakers = [
    //           ...new Set(providerSessionTranscriptData.map(item => item.memberId)),
    //         ];
    //         uniqueSpeakers = uniqueSpeakers.filter(i => i.includes("Speaker "));
    //         uniqueSpeakers = uniqueSpeakers.map(i => i.replace("Speaker ", ""));
    //         providerSessionsSpeakers.push({
    //           providerSessionId: providerSession.providerSessionId,
    //           speakers: uniqueSpeakers,
    //         });
    //       });
    //       let speakerRecognitionData = await dispatch(
    //         getSessionSpeakerRecognitionStatus({
    //           sessionId: groupSessionId,
    //           providerSessionsSpeakers,
    //           noAction: true,
    //         })
    //       );
    //       let patientSpeakerLabel = speakerRecognitionData.find(
    //         i => i?.data?.predictedLabel === "Patient" && i?.data?.speakerUUID === patientId
    //       )?.speakerId;
    //       if (patientSpeakerLabel && soapNotesJsonData.groupNotes[patientSpeakerLabel]) {
    //         patientGroupSessionNotes.push({
    //           patientId,
    //           groupId,
    //           sessionId: groupSessionId,
    //           sessionData: groupSession,
    //           note: soapNotesJsonData.groupNotes[patientSpeakerLabel],
    //         });
    //       }
    //     }
    //   }
    // }
    return patientGroupSessionNotes;
  };
};

const initState = {
  sessionDetails: {
    data: {},
    loading: false,
  },
  meetingToken: {
    data: {},
    loading: false,
  },
  memberNotes: {
    data: [],
    loading: false,
  },
  meetingSession: {
    data: null,
    loading: true,
  },
  patientNotes: {},
};

const ACTION_HANDLERS = {
  ["TOGGLE_MEETING_SESSIONS_LOADER"]: (state, action) => {
    return {
      ...state,
      sessionDetails: {
        ...state.sessionDetails,
        loading: action.show,
      },
    };
  },
  ["ADD_MEETING_SESSIONS_DATA"]: (state, action) => {
    return {
      ...state,
      sessionDetails: {
        ...state.sessionDetails,
        data: action.data,
      },
    };
  },
  ["TOGGLE_MEETING_TOKEN_LOADER"]: (state, action) => {
    return {
      ...state,
      meetingToken: {
        ...state.meetingToken,
        loading: action.show,
      },
    };
  },
  ["ADD_MEETING_TOKEN"]: (state, action) => {
    return {
      ...state,
      meetingToken: {
        ...state.meetingToken,
        data: {
          ...state.meetingToken.data,
          [action.id]: {
            token: action.token,
            sessionCategory: action.sessionCategory,
          },
        },
      },
    };
  },
  ["TOGGLE_IN_SESSION_NOTES_LOADER"]: (state, action) => {
    return {
      ...state,
      memberNotes: {
        ...state.memberNotes,
        loading: action.show,
      },
    };
  },
  ["ADD_IN_SESSION_NOTES"]: (state, action) => {
    return {
      ...state,
      memberNotes: {
        ...state.memberNotes,
        data: action.data,
      },
    };
  },
  ["TOGGLE_MEETING_SESSION_LOADER"]: (state, action) => {
    return {
      ...state,
      meetingSession: {
        ...state.meetingSession,
        loading: action.show,
      },
    };
  },
  ["ADD_MEETING_SESSION_DATA"]: (state, action) => {
    return {
      ...state,
      meetingSession: {
        ...state.meetingSession,
        data: action.data,
      },
    };
  },
  ["SESSION_PATIENT_NOTES_LOADER"]: (state, action) => {
    return {
      ...state,
      patientNotes: {
        ...state.patientNotes,
        [action.patientId]: state.patientNotes[action.patientId]
          ? {
              ...state.patientNotes[action.patientId],
              [action.noteType]: state.patientNotes[action.patientId][action.noteType]
                ? {
                    ...state.patientNotes[action.patientId][action.noteType],
                    loading: action.show,
                  }
                : {
                    loading: action.show,
                  },
            }
          : {
              [action.noteType]: {
                loading: action.show,
              },
            },
      },
    };
  },
  ["ADD_SESSION_PATIENT_NOTES_DATA"]: (state, action) => {
    return {
      ...state,
      patientNotes: {
        ...state.patientNotes,
        [action.patientId]: state.patientNotes[action.patientId]
          ? {
              ...state.patientNotes[action.patientId],
              [action.noteType]: state.patientNotes[action.patientId][action.noteType]
                ? {
                    ...state.patientNotes[action.patientId][action.noteType],
                    data: action.data,
                  }
                : {
                    data: action.data,
                  },
            }
          : {
              [action.noteType]: {
                data: action.data,
              },
            },
      },
    };
  },
};

export default function session(state = initState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
