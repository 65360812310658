import React, { useState, useRef, useEffect, useMemo } from "react";
import { ReactSVG } from "react-svg";
import { PrimaryButton, SecondaryButton, Loader, MemberAvatar, Search } from "@/components";
import { Overlay, Tooltip, Form, Dropdown } from "react-bootstrap";
import { roleBusinessFunctionMapping } from "@/services/dummyData";
import Calendar from "react-calendar";
import SessionCard from "./components/SessionCard";
import StartSessionModal from "../StartSessionModal";
import ScheduleSessionModal from "../ScheduleSessionModal";
import ClockIcon from "@/assets/clock.svg";
import CompletedSessionIcon from "@/assets/calendar-completed.svg";
import CalendarIcon from "@/assets/calendar.svg";
import moment from "moment-timezone";
import "./Sessions.scss";
import { addLocalStorage, getLocalStorage } from "@/services/utils";

const DATE_FORMAT = "MMMM DD, YYYY";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function Sessions(props) {
  const [activeTab, setActiveTab] = useState("upcoming");
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerSearch, setProviderSearch] = useState("");
  const [showStartSessionModal, setShowStartSessionModal] = useState(false);
  const [showScheduleSessionModal, setShowScheduleSessionModal] = useState(false);
  const calendarRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const getDefaultSelectedDate = () => {
    return moment().tz(props.preferredTimezone?.name || "America/Los_Angeles");
  };
  const [selectedDate, setSelectedDate] = useState(getDefaultSelectedDate());
  useEffect(() => {
    if (!props.sessions.loading) {
      let startDateTime = moment.tz(
        selectedDate,
        props.preferredTimezone?.name || "America/Los_Angeles"
      );
      startDateTime = startDateTime.hour(0);
      startDateTime = startDateTime.minute(0);
      startDateTime = startDateTime.second(0);
      startDateTime = startDateTime.format();
      let endDateTime = moment.tz(
        selectedDate,
        props.preferredTimezone?.name || "America/Los_Angeles"
      );
      endDateTime = endDateTime.hour(23);
      endDateTime = endDateTime.minute(59);
      endDateTime = endDateTime.second(59);
      endDateTime = endDateTime.format();
      props.getSessionsByPractitionerRole({ startDateTime, endDateTime });
    }
    // props.getSessionsByPractitionerRole();
  }, [selectedDate]);
  const onSelectDate = date => {
    const preferredTimezone = props.preferredTimezone?.name || "America/Los_Angeles";
    let userDateOnly = moment.tz(moment(date).format("YYYY-MM-DD"), userTimeZone);
    let convertedDate = userDateOnly.clone().tz(preferredTimezone, true);
    setSelectedDate(convertedDate);
    setShowCalendar(false);
  };
  // useEffect(() => {
  //   let blurFunc = e => {
  //     let sessionDateWrapper = document.querySelector(".session-date-wrapper");
  //     let calendarElem = document.querySelector("#calendar-overlay");
  //     if (
  //       e?.target !== calendarElem &&
  //       e?.target !== sessionDateWrapper &&
  //       !calendarElem?.contains(e?.target) &&
  //       !sessionDateWrapper?.contains(e?.target)
  //     ) {
  //       // setShowCalendar(false);
  //     }
  //   };
  //   document.addEventListener("click", blurFunc);
  //   return () => {
  //     document.removeEventListener("click", blurFunc);
  //   };
  // }, []);
  //
  // let dateFilteredSessions = props.sessions.data.filter(session => {
  //   if (!session.startTime) return false;
  //   const sessionDate = moment(session.startTime).tz(
  //     props.preferredTimezone?.name || "America/Los_Angeles"
  //   );
  //   const today = moment(selectedDate);
  //   return (
  //     sessionDate.year() === today.year() &&
  //     sessionDate.month() === today.month() &&
  //     sessionDate.date() === today.date()
  //   );
  // });
  // dateFilteredSessions = dateFilteredSessions.sort(
  //   (session1, session2) => new Date(session1.startTime) - new Date(session2.startTime)
  // );
  // // let sessionList = dateFilteredSessions;
  // if (selectedProvider && props.selectedUserRole?.businessFunction !== "Clinical Care") {
  //   sessionList = sessionList.filter(session => session.practitionerRoleId === selectedProvider.id);
  // }
  // sessionList = sessionList.filter(session => session.appointmentType === "INTAKE");
  const sessionList = useMemo(() => {
    let data = props.sessions.data.filter(session => {
      if (!session.startTime) return false;
      const sessionDate = moment(session.startTime).tz(
        props.preferredTimezone?.name || "America/Los_Angeles"
      );
      const today = moment(selectedDate);
      return (
        sessionDate.year() === today.year() &&
        sessionDate.month() === today.month() &&
        sessionDate.date() === today.date()
      );
    });
    data = data.sort(
      (session1, session2) => new Date(session1.startTime) - new Date(session2.startTime)
    );
    if (selectedProvider && props.selectedUserRole?.businessFunction !== "Clinical Care") {
      data = data.filter(session => session.practitionerRoleId === selectedProvider.id);
    }
    return data;
  }, [props.sessions.data, selectedDate, selectedProvider]);
  // let completedSessions = props.sessions.data.filter(session => {
  //   if (!session.startTime) return false;

  //   const sessionDate = new Date(
  //     new Date(session.startTime).toLocaleString("en", {
  //       timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
  //     })
  //   );
  //   const today = new Date(
  //     new Date().toLocaleString("en", {
  //       timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
  //     })
  //   );

  //   // Compare the full date (year, month, day) to exclude today's sessions
  //   return (
  //     sessionDate.getFullYear() < today.getFullYear() ||
  //     (sessionDate.getFullYear() === today.getFullYear() &&
  //       (sessionDate.getMonth() < today.getMonth() ||
  //         (sessionDate.getMonth() === today.getMonth() && sessionDate.getDate() < today.getDate())))
  //   );
  //   // return !!session.endTime && new Date(session.endTime) < new Date()
  // });
  // completedSessions = completedSessions.sort(
  //   (session1, session2) => new Date(session2.startTime) - new Date(session1.startTime)
  // );
  let upcomingSessions = props.sessions.data.filter(session => {
    const sessionDate = new Date(
      new Date(session.startTime).toLocaleString("en", {
        timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
      })
    );
    const today = new Date(
      new Date().toLocaleString("en", {
        timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
      })
    );
    return !(
      sessionDate.getFullYear() < today.getFullYear() ||
      (sessionDate.getFullYear() === today.getFullYear() &&
        (sessionDate.getMonth() < today.getMonth() ||
          (sessionDate.getMonth() === today.getMonth() && sessionDate.getDate() < today.getDate())))
    );
  });
  upcomingSessions = upcomingSessions.sort(
    (session1, session2) => new Date(session1.startTime) - new Date(session2.startTime)
  );
  // const sessionList = activeTab === "upcoming" ? upcomingSessions : completedSessions;

  const onDateBackClick = () => {
    let newSelectedDate = moment(selectedDate)
      .tz(props.preferredTimezone?.name || "America/Los_Angeles")
      .subtract(1, "days");
    // let newSelectedDate = new Date(selectedDate);
    // let selectDateDay = newSelectedDate.getDate();
    // newSelectedDate.setDate(selectDateDay - 1);
    setSelectedDate(newSelectedDate);
  };
  const onDateNextClick = () => {
    let newSelectedDate = moment(selectedDate)
      .tz(props.preferredTimezone?.name || "America/Los_Angeles")
      .add(1, "days");
    // let newSelectedDate = new Date(selectedDate);
    // let selectDateDay = newSelectedDate.getDate();
    // newSelectedDate.setDate(selectDateDay + 1);
    setSelectedDate(newSelectedDate);
  };
  const orgUsersData = props.orgUsers.data || [];
  // let providersList = [];
  // if (orgUsersData?.length > 0) {
  //   orgUsersData.forEach(user => {
  //     user.roles.forEach(role => {
  //       if (roleBusinessFunctionMapping(role.behaviorHealthRole) === "Clinical Care") {
  //         providersList.push({
  //           ...role,
  //           practitionerEmail: user.practitionerEmail,
  //           practitionerFirstName: user.practitionerFirstName,
  //           practitionerId: user.practitionerId,
  //           practitionerLastName: user.practitionerLastName,
  //         });
  //       }
  //     });
  //   });
  // }
  // providersList = providerSearch
  //   ? providersList?.filter(i => {
  //       let providerName = `${i.practitionerFirstName} ${i.practitionerLastName}`;
  //       return !!providerName.toLowerCase().includes(providerSearch.toLowerCase());
  //     })
  //   : providersList;
  const providersList = useMemo(() => {
    let data = [];
    if (orgUsersData?.length > 0) {
      orgUsersData.forEach(user => {
        user.roles.forEach(role => {
          if (roleBusinessFunctionMapping(role.behaviorHealthRole) === "Clinical Care") {
            data.push({
              ...role,
              practitionerEmail: user.practitionerEmail,
              practitionerFirstName: user.practitionerFirstName,
              practitionerId: user.practitionerId,
              practitionerLastName: user.practitionerLastName,
            });
          }
        });
      });
    }
    data = providerSearch
      ? data?.filter(i => {
          let providerName = `${i.practitionerFirstName} ${i.practitionerLastName}`;
          return !!providerName.toLowerCase().includes(providerSearch.toLowerCase());
        })
      : data;
    return data;
  }, [orgUsersData, providerSearch]);
  useEffect(() => {
    let selectedUserBusinessFunction = props.selectedUserRole?.businessFunction;
    if (
      selectedUserBusinessFunction &&
      selectedUserBusinessFunction !== "Clinical Care" &&
      orgUsersData?.length === 0 &&
      !props.orgUsers.loading
    ) {
      props.getOrgUsers();
    }
    if (orgUsersData?.length > 0) {
      let provider = orgUsersData[0];
      let savedFilter = getLocalStorage("selected-provider-filter");
      if (savedFilter) {
        provider = savedFilter;
      }
      provider = {
        ...provider,
        practitionerEmail: provider.practitionerEmail,
        practitionerFirstName: provider.practitionerFirstName,
        practitionerId: provider.practitionerId,
        practitionerLastName: provider.practitionerLastName,
      };
      setSelectedProvider(provider);
    }
  }, [orgUsersData]);
  const selectProvider = provider => {
    addLocalStorage("selected-provider-filter", provider);
    setSelectedProvider(provider);
  };
  return (
    <div className="appointments-wrapper">
      <div className="session-header">
        {/* <div className="session-label"></div> */}
        {/* <div className="sessions-tab-container">
          <div
            className={`session-tab ${activeTab === "upcoming" ? "active" : ""}`}
            onClick={() => setActiveTab("upcoming")}
          >
            <ReactSVG
              src={ClockIcon}
              className="session-tab-icon"
            />
            <span className="session-tab-title">Today's Appointments</span>
          </div>
          <div
            className={`session-tab ${activeTab === "completed" ? "active" : ""}`}
            onClick={() => setActiveTab("completed")}
          >
            <ReactSVG
              src={CompletedSessionIcon}
              className="session-tab-icon"
            />
            <span className="session-tab-title">Previous Appointments</span>
          </div>
        </div> */}
        <div className="session-filters-container">
          <div className="session-date-wrapper">
            <div
              className="date-left-arrow date-arrow"
              onClick={onDateBackClick}
            ></div>
            <div
              className="session-date-container"
              ref={calendarRef}
              onClick={() => setShowCalendar(!showCalendar)}
            >
              <div className="session-date">
                {moment
                  .tz(selectedDate, props.preferredTimezone?.name || "America/Los_Angeles")
                  .format(DATE_FORMAT)}
              </div>
            </div>
            <div
              className="date-right-arrow date-arrow"
              onClick={onDateNextClick}
            ></div>
            <Overlay
              target={calendarRef.current}
              show={showCalendar}
              placement="bottom"
              rootCloseEvent="mousedown"
            >
              {props => (
                <Tooltip
                  id="calendar-overlay"
                  className="calendar-overlay"
                  {...props}
                >
                  <Calendar
                    onChange={onSelectDate}
                    // value={moment.tz(
                    //   selectedDate,
                    //   props.preferredTimezone?.name || "America/Los_Angeles"
                    // )}
                    value={moment
                      .tz(
                        moment(selectedDate).format("YYYY-MM-DD"),
                        props.preferredTimezone?.name || "America/Los_Angeles"
                      )
                      .clone()
                      .tz(userTimeZone, true)}
                  />
                </Tooltip>
              )}
            </Overlay>
          </div>
        </div>
        <div className="session-header-actions">
          {props.selectedUserRole?.businessFunction !== "Clinical Care" && (
            <div className="provider-filter-container">
              <Form.Label className="provider-filter-label">Filter by Provider:</Form.Label>
              <Dropdown className="provider-filter-dropdown">
                <Dropdown.Toggle>
                  {!!selectedProvider
                    ? `${selectedProvider?.practitionerFirstName} ${selectedProvider?.practitionerLastName}`
                    : "Select Provider"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Search
                    placeholder={"Search provider"}
                    value={providerSearch}
                    onSearch={val => setProviderSearch(val)}
                    clearSearch={() => setProviderSearch("")}
                    noDebounce={true}
                  />
                  <Loader loading={props.orgUsers.loading}>
                    {providersList.length > 0 ? (
                      providersList.map(practitioner => {
                        return (
                          <Dropdown.Item
                            as={"button"}
                            onClick={() => selectProvider(practitioner)}
                            key={practitioner.id}
                            className="provider-filter-dropdown-item"
                          >
                            <div className="provider-filter-dropdown-item-top">
                              <MemberAvatar
                                name={`${practitioner?.practitionerFirstName} ${practitioner?.practitionerLastName}`}
                                size={"small"}
                                showFullName={true}
                              />
                              <div className="practitioner-roles">
                                <div className="role">{practitioner.practitionerEmail}</div>
                              </div>
                            </div>
                            <div className="practitioner-roles">
                              <div className="role">{practitioner.behaviorHealthRole}</div>
                            </div>
                          </Dropdown.Item>
                        );
                      })
                    ) : (
                      <Dropdown.Item
                        as={"button"}
                        className="no-items-found"
                      >
                        No Providers Found
                      </Dropdown.Item>
                    )}
                  </Loader>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          {props.selectedUserRole?.businessFunction !== "Clinical Care" && (
            <SecondaryButton
              className="schedule-session-btn"
              onClick={() => setShowScheduleSessionModal(true)}
            >
              <ReactSVG
                src={CalendarIcon}
                className="schedule-session-icon"
              />
              Schedule Session
            </SecondaryButton>
          )}
          {props.selectedUserRole?.businessFunction === "Clinical Care" && (
            <SecondaryButton
              onClick={() => setShowStartSessionModal(true)}
              className="start-instant-session-btn"
            >
              Start an Instant Session
            </SecondaryButton>
          )}
        </div>
      </div>
      <Loader loading={props.sessions.loading}>
        <div className="session-list-container">
          {sessionList?.length > 0 ? (
            <div className="sessions-list">
              {sessionList.map(session => {
                const sessionDate = new Date(
                  new Date(session.startTime).toLocaleString("en", {
                    timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
                  })
                );
                const today = new Date(
                  new Date().toLocaleString("en", {
                    timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
                  })
                );
                let isUpcoming = !(
                  sessionDate.getFullYear() < today.getFullYear() ||
                  (sessionDate.getFullYear() === today.getFullYear() &&
                    (sessionDate.getMonth() < today.getMonth() ||
                      (sessionDate.getMonth() === today.getMonth() &&
                        sessionDate.getDate() < today.getDate())))
                );
                let isCompleted =
                  sessionDate.getFullYear() < today.getFullYear() ||
                  (sessionDate.getFullYear() === today.getFullYear() &&
                    (sessionDate.getMonth() < today.getMonth() ||
                      (sessionDate.getMonth() === today.getMonth() &&
                        sessionDate.getDate() < today.getDate())));
                return (
                  <SessionCard
                    session={session}
                    isUpcoming={isUpcoming}
                    isCompleted={isCompleted}
                    key={session.id}
                    getSessionsByPractitionerRole={props.getSessionsByPractitionerRole}
                  />
                );
              })}
            </div>
          ) : (
            <div className="no-sessions-found-container">
              <div className="no-sessions-found">No Sessions Found</div>
              {props.selectedUserRole?.businessFunction === "Clinical Care" && (
                <PrimaryButton
                  onClick={() => setShowStartSessionModal(true)}
                  className="start-instant-session-btn"
                >
                  Start an Instant Session
                </PrimaryButton>
              )}
            </div>
          )}
        </div>
      </Loader>
      <StartSessionModal
        modalOpen={showStartSessionModal}
        toggleModal={setShowStartSessionModal}
        practitionerRoleId={props.practitionerRoleId}
        getSessionsByPractitionerRole={props.getSessionsByPractitionerRole}
        filterSelectedDate={moment().tz(props.preferredTimezone?.name || "America/Los_Angeles")}
      />
      <ScheduleSessionModal
        modalOpen={showScheduleSessionModal}
        toggleModal={setShowScheduleSessionModal}
        practitionerRoleId={props.practitionerRoleId}
        getSessionsByPractitionerRole={props.getSessionsByPractitionerRole}
        selectedUserRole={props.selectedUserRole}
        filterSelectedDate={selectedDate}
      />
    </div>
  );
}

export default Sessions;
