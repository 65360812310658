import TreatmentPlanV2 from "./TreatmentPlanV2";
import { connect } from "react-redux";
import {
  getTreatmentPlan,
  generateTreatmentPlan,
  generateTreatmentPlanAI,
} from "@/store/modules/userModule";

const mapStateToProps = state => {
  return {
    treatmentPlan: state.user.treatmentPlan,
    selectedUserRole: state.user.selectedUserRole,
    preferredTimezone: state.user.preferredTimezone,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTreatmentPlan: options => dispatch(getTreatmentPlan(options)),
    generateTreatmentPlan: options => dispatch(generateTreatmentPlan(options)),
    generateTreatmentPlanAI: options => dispatch(generateTreatmentPlanAI(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanV2);
