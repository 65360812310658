import React, { useEffect, useState } from "react";
import { Loader } from "@/components";
import { Link, useParams } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import Profile from "./components/Profile";
import PreExistingRisks from "./components/PreExistingRisks";
import Recommendation from "./components/Recommendation";
import RecommendationOld from "./components/RecommendationOld";
import CumulativeNotes from "./components/CumulativeNotes";
import DSM5 from "./components/DSM5";
import SDOH from "./components/SDOH";
import SymptomTracker from "./components/SymptomTracker";
import PredictionCards from "./components/PredictionCards";
import PatientSessions from "./components/PatientSessions";
import "./PatientDetails.scss";
import SessionDetails from "../SessionDetails";
import moment from "moment-timezone";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

const getIntensityColor = (intensity, key) => {
  // let color = "#74dd74";
  let color = "low";
  let intensitySmallCase = intensity?.toLowerCase() || "";
  if (intensitySmallCase.includes("severe") || intensitySmallCase.includes("present")) {
    // color = "#FF5F05";
    color = "severe";
  } else if (
    intensitySmallCase.includes("moderate") ||
    (key === "emotional_distress" && intensitySmallCase.includes("high"))
  ) {
    // color = "#FF5F05";
    color = "high";
  } else if (
    intensitySmallCase.includes("mild") ||
    (key === "emotional_distress" && intensitySmallCase.includes("medium"))
  ) {
    // color = "#64C833";
    color = "medium";
  } else if (
    intensitySmallCase.includes("low") ||
    (key === "emotional_distress" && intensitySmallCase.includes("low"))
  ) {
    // color = "44C13C";
    color = "low";
  }
  return color;
};

function PatientDetails(props) {
  const {
    soapNotes,
    soapNotesLoading,
    behaviouralHealthPredictions,
    privateNotesLoading,
    privateNotes,
  } = props;
  const { patientId } = useParams();
  const [riskAssessmemtData, setRiskAssessmentData] = useState([]);
  const [treatmentPlanData, setTreatmentPlanData] = useState([]);
  const [diagnosisData, setDiagnosisData] = useState(null);
  const [behaviouralHealthPredictionsData, setBehaviouralHealthPredictionsData] = useState(null);
  // const behaviouralHealthPredictions =
  //   props.patientNotes?.[patientId]?.bhPredictions?.data?.[0]?.behaviouralHealthPredictions;
  const patientsList = props.patients.data;
  const [activeTab, setActiveTab] = useState("patientChart");
  const [sessionNotesTabs, setSessionNotesTabs] = useState([]);
  useEffect(() => {
    if (patientsList?.length === 0 && !props.patients.loading) {
      props.getPatients();
    }
  }, []);
  useEffect(() => {
    if (props.sessions.data?.length === 0 && !props.sessions.loading) {
      props.getSessionsByPatientId(patientId);
    }
  }, []);

  const patientData = patientsList.find(i => i.id === patientId);
  useEffect(() => {
    if (patientData?.firstName) {
      let patientName = `${patientData.firstName || ""} ${patientData.lastName || ""}`;
      document.title = patientName;
    } else {
      document.title = "Patient Details";
    }
  }, [patientData]);
  useEffect(() => {
    // if (
    //   !props.patientNotes?.[patientId]?.individualSessionNotes?.loading &&
    //   !props.patientNotes?.[patientId]?.groupSessionNotes?.loading
    // ) {
    //   // props.getLatestSoapNotes({ patientId });
    //   props.getPatientNotes({ patientId });
    //   // props.getLatestPatientSessionMemberNotes(patientId);
    // }
    if (!soapNotesLoading) {
      // props.getLatestSoapNotes({ patientId });
      // props.getPatientNotes({ patientId });
      props.getLatestPatientSessionMemberNotes(patientId);
    }
    // props.getPatientPrivateNotes({ groupId, patientId });
    return () => {
      props.resetPatientDetails();
    };
  }, []);
  useEffect(() => {
    // if (soapNotes) {
    //   let riskAssessmemtData = parseHtml(soapNotes, "Assessment (A)");
    //   setRiskAssessmentData(riskAssessmemtData);
    //   let treatmentPlanData = parseHtml(soapNotes, "Plan (P)");
    //   setTreatmentPlanData(treatmentPlanData);
    // }
  }, [soapNotes]);
  useEffect(() => {
    if (behaviouralHealthPredictions) {
      let data = behaviouralHealthPredictions || null;
      if (data && Object.keys(data).length > 0) {
        let anxietyParameters = data?.["gad-7"] || {};
        let depressionParameters = data?.["phq-9"] || {};
        anxietyParameters = Object.keys(anxietyParameters).map(parameter => {
          return {
            title: parameter,
            detected: anxietyParameters[parameter] > 0,
            intensity: anxietyParameters[parameter],
          };
        });
        depressionParameters = Object.keys(depressionParameters).map(parameter => {
          return {
            title: parameter,
            detected: depressionParameters[parameter] > 0,
            intensity: depressionParameters[parameter],
          };
        });
        let anxietyScore = anxietyParameters.reduce((result, i) => {
          return i.intensity + result;
        }, 0);
        let depressionScore = depressionParameters.reduce((result, i) => {
          return i.intensity + result;
        }, 0);
        let anxietyIntensityText =
          anxietyScore >= 15
            ? "Severe"
            : anxietyScore >= 10
            ? "Moderate"
            : anxietyScore >= 5
            ? "Mild"
            : "Low";
        let depressionIntensityText =
          depressionScore >= 20
            ? "Severe"
            : depressionScore >= 15
            ? "Moderately severe"
            : depressionScore >= 10
            ? "Moderate"
            : depressionScore >= 5
            ? "Mild"
            : "Low";
        let anxietyData = {
          parameters: anxietyParameters,
          score: anxietyScore,
          intensityText: anxietyIntensityText,
          cardColor: getIntensityColor(anxietyIntensityText),
        };
        let depressionData = {
          parameters: depressionParameters,
          score: depressionScore,
          intensityText: depressionIntensityText,
          cardColor: getIntensityColor(depressionIntensityText),
        };
        let emotionalDistress = {
          intensityText: data?.emotional_distress || "-",
          cardColor: getIntensityColor(data?.emotional_distress, "emotional_distress"),
        };
        let suicideRisk = {
          intensityText: data?.suicide_risk || "",
          cardColor: getIntensityColor(data?.suicide_risk),
        };
        let predictData = {
          anxiety: anxietyData,
          depression: depressionData,
          emotionalDistress,
          suicideRisk,
        };
        setBehaviouralHealthPredictionsData(predictData);
      }
    }
  }, [behaviouralHealthPredictions]);
  const onSessionClick = session => {
    let newSessionNotesTabs = [...sessionNotesTabs];
    let sessionTab = newSessionNotesTabs.find(i => i.id === session.id);
    if (!sessionTab) {
      newSessionNotesTabs.push({
        ...session,
      });
      setSessionNotesTabs(newSessionNotesTabs);
    }
    setActiveTab(session.id);
  };
  const closeSessionNotesTab = (sessionId, e) => {
    e?.stopPropagation?.();
    let newSessionNotesTabs = sessionNotesTabs.filter(session => session.id !== sessionId);
    setActiveTab("patientChart");
    setSessionNotesTabs(newSessionNotesTabs);
  };
  // const latestSoapNotesJson =
  //   props.patientNotes?.[patientId]?.individualSessionNotes?.data?.[0]?.jsonSoapNote;
  const latestSoapNotesJson = props.soapNotesJson;
  return (
    <div className="member-report-page">
      <Link
        className="back-btn"
        to={"/patients"}
      >
        Back
      </Link>
      <Loader loading={props.patientNotes?.[patientId]?.individualSessionNotes?.loading}>
        <Tabs
          id="controlled-tab-example"
          activeKey={activeTab}
          onSelect={k => setActiveTab(k)}
          className="patient-chart-tabs-list"
        >
          <Tab
            eventKey="patientChart"
            title={
              patientData?.firstName
                ? `${patientData?.firstName || ""}${patientData?.lastName ? " " : ""}${
                    patientData?.lastName || ""
                  }`
                : "Patient"
            }
          >
            <div className="patient-details-wrapper">
              <div className="patient-details-container">
                {/* <div className="patient-details-top-container">
            </div> */}
                <div className="masonry-layout">
                  <div className="masonry-left">
                    <div className="member-report-widget">
                      <Profile
                        data={patientData}
                        behaviouralHealthPredictionsData={behaviouralHealthPredictionsData}
                        getDummyPhoneNumber={props.getDummyPhoneNumber}
                        getDummyEmail={props.getDummyEmail}
                        dischargeSummary={props.dischargeSummary[patientId]}
                        dischargeSummarySession={props.dischargeSummarySession[patientId]}
                        generateDischargeSummary={props.generateDischargeSummary}
                        sessions={props.sessions.data}
                        selectedUserRole={props.selectedUserRole}
                        getDischargeSummary={props.getDischargeSummary}
                        saveDischargeSummary={props.saveDischargeSummary}
                        preferredTimezone={props.preferredTimezone}
                      />
                    </div>
                    {riskAssessmemtData && false && (
                      <div className="member-report-widget">
                        <PreExistingRisks data={riskAssessmemtData} />
                      </div>
                    )}
                    {latestSoapNotesJson?.narrative && (
                      <div className="member-report-widget patient-narrative-widget">
                        <div className="widget-header">
                          <div className="widget-title">Latest Patient Narrative</div>
                        </div>
                        <textarea
                          className="patient-narrative-widget-content"
                          readOnly={true}
                        >
                          {latestSoapNotesJson.narrative}
                        </textarea>
                      </div>
                    )}
                    {latestSoapNotesJson?.plan?.["follow_up_plans"]?.result?.length > 0 && (
                      <div className="member-report-widget patient-narrative-widget">
                        <div className="widget-header">
                          <div className="widget-title">Follow-up Plans</div>
                        </div>
                        <div className="follow-up-plan-list">
                          {latestSoapNotesJson?.plan?.["follow_up_plans"]?.result.map(
                            (result, index) => {
                              return (
                                <div
                                  className="follow-up-plan-item"
                                  key={index}
                                >
                                  {result}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                    {/* <div className="member-report-widget">
                  <CumulativeNotes data={privateNotes} />
                </div> */}
                    {APP_ENV === "DEV" && (
                      <div className="member-report-widget">
                        <SDOH data={{ sdohParameters: ["living", "food"] }} />
                      </div>
                    )}
                  </div>
                  <div className="masonry-right">
                    {treatmentPlanData && (
                      <div className="member-report-widget">
                        <Recommendation
                          data={treatmentPlanData}
                          sessions={props.sessions.data}
                          preferredTimezone={props.preferredTimezone}
                        />
                      </div>
                    )}
                    {latestSoapNotesJson?.assessment?.["DSM-5 Diagnoses"]?.result && (
                      <div className="member-report-widget patient-narrative-widget">
                        <div className="widget-header">
                          <div className="widget-title">DSM-5 Diagnosis</div>
                        </div>
                        <textarea
                          className="patient-narrative-widget-content"
                          readOnly={true}
                          style={{ minHeight: "unset" }}
                        >
                          {latestSoapNotesJson?.assessment?.["DSM-5 Diagnoses"]?.result}
                        </textarea>
                      </div>
                    )}
                    {latestSoapNotesJson?.assessment?.["Current Diagnosis"]?.result?.length > 0 && (
                      <div className="member-report-widget patient-narrative-widget">
                        <div className="widget-header">
                          <div className="widget-title">Current Diagnosis</div>
                        </div>
                        <textarea
                          className="patient-narrative-widget-content"
                          readOnly={true}
                          style={{ minHeight: "unset" }}
                          value={
                            latestSoapNotesJson?.assessment?.["Current Diagnosis"]?.result
                              ? typeof (
                                  latestSoapNotesJson?.assessment?.["Current Diagnosis"]?.result ===
                                  "string"
                                )
                                ? latestSoapNotesJson?.assessment?.["Current Diagnosis"]?.result
                                : latestSoapNotesJson?.assessment?.[
                                    "Current Diagnosis"
                                  ]?.result?.join?.(", ")
                              : ""
                          }
                        />
                      </div>
                    )}

                    {APP_ENV === "PROD" && treatmentPlanData && false && (
                      <div className="member-report-widget">
                        <RecommendationOld data={treatmentPlanData} />
                      </div>
                    )}
                    {!!diagnosisData && false && (
                      <div className="member-report-widget">
                        <DSM5 data={diagnosisData} />
                      </div>
                    )}
                    {!!behaviouralHealthPredictionsData && (
                      <div className="member-report-widget">
                        <SymptomTracker data={behaviouralHealthPredictionsData} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="member-report-widget sessions-report-widget">
                <PatientSessions
                  patientId={patientId}
                  patientData={patientData}
                  sessions={props.sessions}
                  getSessionsByPatientId={props.getSessionsByPatientId}
                  preferredTimezone={props.preferredTimezone}
                  selectedUserRole={props.selectedUserRole}
                  onSessionClick={onSessionClick}
                />
              </div>
            </div>
          </Tab>
          {sessionNotesTabs?.length > 0 &&
            sessionNotesTabs.map(tab => {
              let sessionDate = tab?.startTime
                ? `${moment(tab.startTime)
                    .tz(props.preferredTimezone?.name || "America/Los_Angeles")
                    .format("MMMM DD")}, ${moment(tab.startTime)
                    .tz(props.preferredTimezone?.name || "America/Los_Angeles")
                    .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"}`
                : "";
              return (
                <Tab
                  eventKey={tab.id}
                  key={tab.id}
                  title={
                    <SessionNotesTabTitle
                      title={sessionDate}
                      onClose={e => closeSessionNotesTab(tab.id, e)}
                    />
                  }
                >
                  <SessionDetails
                    isActive={activeTab === tab.id}
                    fromPatientChart={true}
                    sessionCategory={tab?.sessionCategory?.toLowerCase?.() || "individual"}
                    sessionType={tab?.modeOfDelivery === "IN_PERSON" ? "in-person" : "virtual"}
                    sessionId={tab.id}
                    patientId={tab?.sessionCategory === "INDIVIDUAL" ? tab.patientId : tab.groupId}
                  />
                </Tab>
              );
            })}
        </Tabs>
      </Loader>
    </div>
  );
}

export default PatientDetails;

const SessionNotesTabTitle = ({ title, onClose }) => {
  return (
    <div className="session-notes-tab-title">
      {title}{" "}
      <span
        className="session-notes-tab-close-btn"
        onClick={onClose}
      ></span>
    </div>
  );
};

function parseHtml(htmlString, title) {
  // Parse the HTML string into a DOM document
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Function to find the h3 element containing "Risk Assessment"
  const headings = Array.from(doc.querySelectorAll("h2"));
  const riskAssessmentHeading = headings.find(
    heading => heading.textContent.trim() === `${title}:` || heading.textContent.trim() === title
  );

  let content = null;

  // If the Risk Assessment section is found
  if (riskAssessmentHeading) {
    // Get the next sibling element, which should be the <ul> list
    content = "";
    let nextSibling = riskAssessmentHeading.nextElementSibling;
    while (nextSibling && nextSibling.tagName !== "h2") {
      content += nextSibling.outerHTML;
      nextSibling = nextSibling.nextElementSibling;
    }
    return content;
    // const riskAssessmentList = riskAssessmentHeading.nextElementSibling;
    // return riskAssessmentList ? riskAssessmentList.outerHTML : "";
    // Check if the next sibling is a <ul> element
    // if (riskAssessmentList && riskAssessmentList.tagName === "UL") {
    //   // Extract the list items from the Risk Assessment section
    //   const riskAssessmentItems = [];
    //   const listItems = riskAssessmentList.querySelectorAll("li");
    //   listItems.forEach(item => {
    //     riskAssessmentItems.push(item.textContent.trim());
    //   });

    //   return riskAssessmentItems;
    // }
  }

  // Return an empty array if the Risk Assessment section is not found or does not contain a <ul> list
  return null;
}

function parseDiagnosisHtml(htmlString, title) {
  // Parse the HTML string into a DOM document
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Function to find the h3 element containing "Risk Assessment"
  const headings = Array.from(doc.querySelectorAll("h4"));
  const riskAssessmentHeading = headings.find(
    heading => heading.textContent.trim() === `${title}:` || heading.textContent.trim() === title
  );

  let content = null;

  // If the Risk Assessment section is found
  if (riskAssessmentHeading) {
    // Get the next sibling element, which should be the <ul> list
    content = "";
    let nextSibling = riskAssessmentHeading.nextElementSibling;
    // while (nextSibling && nextSibling.tagName !== "h2") {
    content += nextSibling.outerHTML;
    // nextSibling = nextSibling.nextElementSibling;
    // }
    return content;
    // const riskAssessmentList = riskAssessmentHeading.nextElementSibling;
    // return riskAssessmentList ? riskAssessmentList.outerHTML : "";
    // Check if the next sibling is a <ul> element
    // if (riskAssessmentList && riskAssessmentList.tagName === "UL") {
    //   // Extract the list items from the Risk Assessment section
    //   const riskAssessmentItems = [];
    //   const listItems = riskAssessmentList.querySelectorAll("li");
    //   listItems.forEach(item => {
    //     riskAssessmentItems.push(item.textContent.trim());
    //   });

    //   return riskAssessmentItems;
    // }
  }

  // Return an empty array if the Risk Assessment section is not found or does not contain a <ul> list
  return null;
}

function parseHtmlJson(htmlString, title) {
  // Parse the HTML string into a DOM document
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Function to find the h3 element containing "Risk Assessment"
  const headings = Array.from(doc.querySelectorAll("h3"));
  const riskAssessmentHeading = headings.find(
    heading => heading.textContent.trim() === `${title}:` || heading.textContent.trim() === title
  );

  // If the Risk Assessment section is found
  if (riskAssessmentHeading) {
    // Get the next sibling element, which should be the <ul> list
    const riskAssessmentList = riskAssessmentHeading.nextElementSibling;
    // Check if the next sibling is a <ul> element
    if (riskAssessmentList && riskAssessmentList.tagName === "UL") {
      // Extract the list items from the Risk Assessment section
      const riskAssessmentItems = [];
      const listItems = riskAssessmentList.querySelectorAll("li");
      listItems.forEach(item => {
        riskAssessmentItems.push(item.textContent.trim());
      });

      return riskAssessmentItems;
    }
  }

  // Return an empty array if the Risk Assessment section is not found or does not contain a <ul> list
  return [];
}
