import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./Session.scss";
import VirtualSession from "./components/VirtualSession";
import InPersonSession from "./components/InPersonSession";

const groupId = import.meta.env.VITE_GROUP_ID;

function Session(props) {
  const { sessionCategory, sessionType, sessionId, patientId } = useParams();
  useEffect(() => {
    props.getSessionDetails({ sessionId, sessionCategory });
    if (
      !props.patientNotes?.[patientId]?.individualSessionNotes?.loading &&
      sessionCategory === "individual"
    ) {
      props.getPatientNotes({ patientId });
    }
  }, [sessionId, patientId, sessionCategory]);
  useEffect(() => {
    return () => {
      props.resetPatientDetails();
    };
  }, []);
  const memberNotes = {
    loading: props.patientNotes?.[patientId]?.individualSessionNotes?.loading,
    soapNotes: props.patientNotes?.[patientId]?.individualSessionNotes?.data?.[0]?.soapNote || "",
    soapNotesJson:
      props.patientNotes?.[patientId]?.individualSessionNotes?.data?.[0]?.jsonSoapNote || null,
    firstName: props.sessionDetails?.data?.patientFirstName || "",
    lastName: props.sessionDetails?.data?.patientLastName || "",
    name: `${props.sessionDetails?.data?.patientFirstName || ""} ${
      props.sessionDetails?.data?.patientLastName || ""
    }`,
  };
  useEffect(() => {
    if (
      props.sessionDetails?.data &&
      !props.sessionDetails.loading &&
      props.sessionDetails?.data?.sessionCategory
    ) {
      let patientName =
        props.sessionDetails?.data?.sessionCategory === "INDIVIDUAL"
          ? `${props.sessionDetails?.data?.patientFirstName || ""} ${
              props.sessionDetails?.data?.patientLastName || ""
            }`
          : props.sessionDetails?.data?.groupName;
      document.title = `Session | ${patientName}`;
    } else {
      document.title = "Session";
    }
  }, [props.sessionDetails?.data, patientId]);
  // if (sessionType === "virtual") {
  return (
    <VirtualSession
      sessionDetails={props.sessionDetails}
      meetingToken={props.meetingToken}
      memberNotes={memberNotes}
      userData={props.userData}
      getSessionDetails={props.getSessionDetails}
      getMeetingToken={props.getMeetingToken}
      selectedUserRole={props.selectedUserRole}
      addTask={props.addTask}
      preferredTimezone={props.preferredTimezone}
      mapProviderSessionId={props.mapProviderSessionId}
      hideSideBar={props.hideSideBar}
      meetingSession={props.meetingSession}
      changeMeetingSession={props.changeMeetingSession}
      sessionType={sessionType}
      sideBarHidden={props.sideBarHidden}
      getTodaysSessionsByPractitionerRole={props.getTodaysSessionsByPractitionerRole}
      patientNotes={props.patientNotes}
      getPatientNotes={props.getPatientNotes}
    />
  );
  // } else {
  //   return (
  //     <InPersonSession
  //       memberNotes={memberNotes}
  //       userData={props.userData}
  //       selectedUserRole={props.selectedUserRole}
  //       sessionDetails={props.sessionDetails}
  //       addTask={props.addTask}
  //       preferredTimezone={props.preferredTimezone}
  //       mapProviderSessionId={props.mapProviderSessionId}
  //       hideSideBar={props.hideSideBar}
  //     />
  //   );
  // }
}

export default Session;
