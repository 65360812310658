import React, { useState, useEffect } from "react";
import { ProgressBar, Dropdown, Form } from "react-bootstrap";
import {
  Loader,
  PrimaryButton,
  RichTextEditor,
  SecondaryButton,
  TertiaryButton,
} from "@/components";
import { toast } from "react-toastify";
import { getLocalStorage } from "@/services/utils";
import "./SoapNotes.scss";
import SoapNotesTemplate from "./components/SoapNotesTemplate";
import IntakeNotesTemplate from "./components/IntakeNotesTemplate";
import { post } from "@/services/api";
import GroupNotes from "./components/GroupNotes";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

function SoapNotes(props) {
  const [notesData, setNotesData] = useState("");
  const [notesTemplate, setNotesTemplate] = useState(
    props.sessionCategory === "group" ? "group" : "assessment"
  ); //default, assessment, intake
  const [generateSoapLoading, setGenerateSoapLoading] = useState(false);
  const [soapNotesJson, setSoapNotesJson] = useState(null);
  useEffect(() => {
    setSoapNotesJson(props.soapNotesJson || null);
  }, [props.soapNotesJson]);
  useEffect(() => {
    setNotesData(props.data || "");
  }, [props.data]);
  useEffect(() => {
    if (
      props.sessionData?.appointmentType === "INTAKE" &&
      props.sessionData?.sessionCategory === "INDIVIDUAL"
    ) {
      setNotesTemplate("intake");
    }
  }, [props.sessionData]);
  const providerName = `${props.sessionData?.practitionerFirstName || ""}${
    props.sessionData?.practitionerLastName ? " " : ""
  }${props.sessionData?.practitionerLastName || ""}`;
  const patientName = `${props.sessionData?.patientFirstName || ""}${
    props.sessionData?.patientLastName ? " " : ""
  }${props.sessionData?.patientLastName || ""}`;
  const generateSoapNotes = async (regenrate = false) => {
    let sessionId = props.sessionId;
    let notesId = props.notesId || null;
    let groupId = props?.groupId;
    let patientId = props?.patientId;
    let sessionCategory = props.sessionCategory;
    if (sessionId) {
      const providerSessionsTranscriptData = props.transcriptData || [];
      const transcriptData = providerSessionsTranscriptData.reduce(
        (acc, providerSessionsTranscript) => {
          const sessionTranscripts = providerSessionsTranscript.transcriptData;
          return acc.concat(sessionTranscripts);
        },
        []
      );
      let transcriptText = "";
      transcriptData.forEach(transcript => {
        let memberName = transcript.memberName;
        if (
          ["provider", "patient"].includes(memberName?.toLowerCase()) &&
          props.sessionCategory === "individual"
        ) {
          if (memberName?.toLowerCase() === "provider" && providerName) {
            memberName = providerName;
          }
          if (memberName?.toLowerCase() === "patient" && patientName) {
            memberName = patientName;
          }
        }
        transcriptText += `${memberName}: ${transcript.transcriptText}\n\n`;
      });
      let payload = {
        transcript: transcriptText,
        sessionCategory,
      };
      if (notesTemplate === "group") {
        let transcriptCSVRows = providerSessionsTranscriptData.reduce(
          (acc, providerSessionsTranscript) => {
            const sessionTranscripts = providerSessionsTranscript.transcriptData.map(i => {
              return {
                timestamp: i.timestamp,
                peerID: i.providerPeerId,
                participantID: i.providerParticipantId,
                customParticipantID: i.memberId,
                participantName: i.memberName,
                transcript: i.transcriptText,
              };
            });
            return acc.concat(sessionTranscripts);
          },
          []
        );
        console.log("transcriptCSVRows", transcriptCSVRows);
        payload.transcriptCSVRows = transcriptCSVRows;
      }
      setGenerateSoapLoading(true);
      let options = {
        sessionId,
        groupId,
        patientId,
        payload,
        regenrate,
        sessionCategory,
        notesId,
        notesTemplate,
        soapNote: notesData,
        soapNotesJson: soapNotesJson,
      };
      if (notesTemplate === "intake") {
        options.hpiNote = true;
      }
      await props.generateSoapNotes(options);
      setGenerateSoapLoading(false);
    }
  };
  const saveNotes = () => {
    let sessionId = props.sessionId;
    let groupId = props?.groupId;
    let patientId = props?.patientId;
    let notesId = props.notesId || null;
    props.removeTask(sessionId);
    props.saveSoapNotes({
      groupId,
      patientId,
      sessionId,
      soapNote: notesData,
      notesId,
      soapNotesJson: soapNotesJson,
    });
  };
  const changeSoapJsonData = data => {
    setSoapNotesJson(data);
  };
  let LoaderComponent = generateSoapLoading ? ProgressLoader : Loader;
  const saveToEhr = async () => {
    let url = `practitioner-role/individual-session/update-chief-complain-note-by-visit-in-amd-ehr`;
    let payload = new FormData();
    if (
      props.sessionId &&
      (props.soapNotesJson?.chiefCompliantEnhanced ||
        props.soapNotesJson?.subjective?.chief_complaint?.result)
    ) {
      payload.append("id", props.sessionId);
      payload.append(
        "chiefComplaintNote",
        props.soapNotesJson?.chiefCompliantEnhanced ||
          props.soapNotesJson.subjective.chief_complaint.result
      );
      try {
        await post(url, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Notes Saved to EHR");
      } catch (error) {
        console.log("error", error);
        toast.error(error?.response?.data?.message || "Error saving notes to EHR");
      }
    }
  };
  return (
    <LoaderComponent loading={props.loading}>
      <div className="soap-notes-container">
        {(props.data && notesTemplate === "default") ||
        (!!soapNotesJson &&
          soapNotesJson.subjective &&
          soapNotesJson.objective &&
          soapNotesJson.assessment &&
          notesTemplate === "assessment") ||
        (!!soapNotesJson &&
          soapNotesJson["intake-assessment"] &&
          Object.keys(soapNotesJson["intake-assessment"])?.length > 0 &&
          notesTemplate === "intake") ||
        (!!soapNotesJson?.groupNotes &&
          Object.keys(soapNotesJson?.groupNotes).length > 0 &&
          notesTemplate === "group") ? (
          <>
            <div className="soap-notes-header">
              <div className="select-template-container">
                {props.sessionCategory === "individual" && (
                  <>
                    <Form.Label className="select-template-label">Notes Template:</Form.Label>
                    <Dropdown className="select-template-dropdown">
                      <Dropdown.Toggle>
                        {notesTemplate === "default"
                          ? `Default`
                          : notesTemplate === "intake"
                          ? "Intake"
                          : "Assessment"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as={"button"}
                          onClick={() => setNotesTemplate("default")}
                        >
                          Default
                        </Dropdown.Item>
                        <Dropdown.Item
                          as={"button"}
                          onClick={() => setNotesTemplate("intake")}
                        >
                          Intake
                        </Dropdown.Item>
                        <Dropdown.Item
                          as={"button"}
                          onClick={() => setNotesTemplate("assessment")}
                        >
                          Assessment
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                )}
              </div>
              <div className="save-notes-container">
                <TertiaryButton onClick={() => generateSoapNotes(true)}>
                  Regenerate Notes
                </TertiaryButton>
                {notesTemplate === "assessment" && (
                  <SecondaryButton onClick={() => saveToEhr()}>Sync Notes to EHR</SecondaryButton>
                )}
                <PrimaryButton onClick={saveNotes}>Save Changes</PrimaryButton>
              </div>
            </div>

            {props.sessionCategory === "individual" && notesTemplate === "default" && (
              <RichTextEditor
                value={notesData}
                setValue={setNotesData}
              />
            )}
            {props.sessionCategory === "individual" && notesTemplate === "intake" && (
              <IntakeNotesTemplate
                data={soapNotesJson}
                changeSoapJsonData={changeSoapJsonData}
                sessionData={props.sessionData}
                preferredTimezone={props.preferredTimezone}
                selectedUserRole={props.selectedUserRole}
              />
            )}
            {props.sessionCategory === "individual" && notesTemplate === "assessment" && (
              <SoapNotesTemplate
                data={soapNotesJson}
                changeSoapJsonData={changeSoapJsonData}
                sessionData={props.sessionData}
                preferredTimezone={props.preferredTimezone}
                selectedUserRole={props.selectedUserRole}
              />
            )}
            {props.sessionCategory === "group" && notesTemplate === "group" && (
              <GroupNotes
                data={soapNotesJson}
                changeSoapJsonData={changeSoapJsonData}
                sessionData={props.sessionData}
                preferredTimezone={props.preferredTimezone}
                selectedUserRole={props.selectedUserRole}
                speakerRecognition={props.speakerRecognition}
                sessionId={props.sessionId}
              />
            )}
          </>
        ) : (
          <div className="generate-soap-notes-container">
            {props.sessionCategory === "individual" && (
              <div className="select-template-container">
                <Form.Label className="select-template-label">Notes Template:</Form.Label>
                <Dropdown className="select-template-dropdown">
                  <Dropdown.Toggle>
                    {notesTemplate === "default"
                      ? `Default`
                      : notesTemplate === "intake"
                      ? `Intake`
                      : "Assessment"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={"button"}
                      onClick={() => setNotesTemplate("default")}
                    >
                      Default
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={"button"}
                      onClick={() => setNotesTemplate("intake")}
                    >
                      Intake
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={"button"}
                      onClick={() => setNotesTemplate("assessment")}
                    >
                      Assessment
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            <PrimaryButton onClick={() => generateSoapNotes()}>Generate Notes</PrimaryButton>
          </div>
        )}
      </div>
    </LoaderComponent>
  );
}

export default SoapNotes;

const ProgressLoader = props => {
  return (
    <div className="progress-loader">
      <ProgressBar
        animated
        now={100}
      />
      <div className="loading-msg">Generating Notes</div>
    </div>
  );
};
