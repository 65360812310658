import React, { useState, useRef, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDyteMeeting, useDyteSelector } from "@dytesdk/react-web-core";
import {
  DyteParticipantsAudio,
  DyteCameraToggle,
  DyteParticipantTile,
  DyteAvatar,
  DyteNameTag,
  DyteAudioVisualizer,
  DyteChatToggle,
  DyteParticipantsToggle,
  DyteMicToggle,
  DyteLeaveButton,
  DyteSettingsToggle,
  DyteChat,
  DyteGrid,
  DyteParticipants,
  defaultLanguage,
  useLanguage,
} from "@dytesdk/react-ui-kit";
import { createGrid } from "good-grid";
// import { useGridDimensions, useGoodGrid } from "good-grid/react";
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { MemberAvatar, SecondaryButton, Loader, AlertModal } from "@/components";
import { getLocalStorage, addLocalStorage } from "@/services/utils";
import { SendText } from "@/components";
import moment from "moment-timezone";
import { ReactSVG } from "react-svg";
import ShareIcon from "@/assets/share.svg";
import ReminderIcon from "@/assets/reminder.svg";
import PhoneIcon from "@/assets/phone.svg";
import VirtualIcon from "@/assets/video-recorder.svg";
import PhoneSpeakerIcon from "@/assets/phone-speaker.svg";
import AudioRecorderIcon from "@/assets/session/audio-recorder.svg";
import AudioRecorderOffIcon from "@/assets/session/audio-recorder-off.svg";
import LeaveIcon from "@/assets/session/leave.svg";
import { post } from "@/services/api";
import { Tabs, Tab } from "react-bootstrap";
import "./SessionJoined.scss";
import SessionMemberNotes from "@/Routes/Session/components/SessionMemberNotes";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";
const isMobile = window.innerWidth <= 480 || window.innerHeight <= 480;

function SessionJoined(props) {
  const { meeting } = useDyteMeeting();
  const { sessionCategory, patientId, sessionId } = useParams();
  const [showSMSReminderPopup, setShowSMSReminderPopup] = useState(false);
  const [showSMSMeetingLinkPopup, setShowSMSMeetingLinkPopup] = useState(false);
  const [providerJoinedSmsSent, setProviderJoinedSmsSent] = useState(false);
  const [meetingLeaveModalOpen, setMeetingLeaveModalOpen] = useState(false);
  // const [sessionModeOfDelivery, setSessionModeOfDelivery] = useState(
  //   props.sessionType === "in-person" ? "phone" : "video"
  // );
  const [sessionModeOfDelivery, setSessionModeOfDelivery] = useState("phone");
  const [sessionModeChanged, setSessionModeChanged] = useState(null);
  const [activeTab, setActiveTab] = useState("meeting"); //meeting, patientDetails
  const [patientTabs, setPatientTabs] = useState([]);
  useEffect(() => {
    setSessionModeChanged(sessionModeOfDelivery);
    if (sessionModeOfDelivery === "phone") {
      try {
        meeting?.self?.enableAudio();
      } catch (error) {
        console.log(error);
      }
    }
  }, [sessionModeOfDelivery]);
  useEffect(() => {
    if (props.meetingSession?.data && props.sessionType === "virtual") {
      let modeOfDelivery =
        props.meetingSession.data?.modeOfDelivery === "IN_PERSON" ? "phone" : "video";
      if (modeOfDelivery !== sessionModeOfDelivery) {
        // setSessionModeOfDelivery(modeOfDelivery);
      }
    }
  }, [props.meetingSession?.data]);
  useEffect(() => {
    let patientTabs = [];
    if (sessionCategory === "individual") {
      patientTabs = props.sessionDetailsData
        ? [
            {
              firstName: props.sessionDetailsData.patientFirstName || "",
              lastName: props.sessionDetailsData.patientLastName || "",
              patientId: props.sessionDetailsData.patientId,
            },
          ]
        : [];
    }
    // else {
    //   patientTabs =
    //     props.sessionDetailsData?.sessionPatientMemberDtos?.map(patient => {
    //       return {
    //         firstName: patient.patientFirstName || "",
    //         lastName: patient.patientLastName || "",
    //         patientId: patient.patientId,
    //       };
    //     }) || [];
    // }
    setPatientTabs(patientTabs);
  }, [patientId, sessionId, props.sessionDetailsData]);
  useEffect(() => {
    if (activeTab !== "meeting") {
      if (!props.patientNotes.hasOwnProperty(activeTab)) {
        props.getPatientNotes({ patientId: activeTab });
      }
    }
  }, [activeTab]);
  const onPatientClick = patient => {
    let newPatientTabs = [...patientTabs];
    let patientTab = newPatientTabs.find(i => i.patientId === patient.patientId);
    if (!patientTab) {
      newPatientTabs.push({
        firstName: patient.patientFirstName || "",
        lastName: patient.patientLastName || "",
        patientId: patient.patientId,
      });
      setPatientTabs(newPatientTabs);
    }
    setActiveTab(patient.patientId);
  };
  const changeModeOfDelivery = mode => {
    setSessionModeOfDelivery(mode);
    if (props.sessionType === "virtual") {
      let meetingSessionData = props.meetingSession?.data
        ? {
            ...props.meetingSession.data,
            modeOfDelivery: mode === "phone" ? "IN_PERSON" : "VIRTUAL",
          }
        : null;
      if (meetingSessionData) {
        props.changeMeetingSession({ payload: meetingSessionData });
      }
    }
  };
  const myLanguagePack = useLanguage({
    ...defaultLanguage,
    kick: "Remove",
  });
  // const [states, setStates] = useState({ activeSidebar: true, sidebar: "participants" });
  // const [states, setStates] = useState({});
  // const setState = s => setStates(states => ({ ...states, ...s }));
  const states = props.states;
  const setState = props.setState;
  const activeParticipants =
    useDyteSelector(meeting => meeting.participants.joined)?.toArray() || [];
  const prevParticipants = [...activeParticipants];
  const selfParticipant = useDyteSelector(meeting => meeting.self);
  let allParticipants = [selfParticipant, ...activeParticipants];

  const $grid = useRef(null);
  // const [dimensions, setDimensions] = useState(useGridDimensions($grid));
  const dimensions = useGridDimensions($grid, sessionModeChanged);
  let aspectRatio = "16:9";
  if (isMobile) {
    const totalParticipants = allParticipants.length;
    const videoGrid = $grid?.current || null;
    if (videoGrid) {
      let ratioWidth = 1;
      let ratioHeight = 1;
      let dimensions = videoGrid.getBoundingClientRect();
      let gridWidth = dimensions.width;
      let gridHeight = dimensions.height;
      if (totalParticipants <= 3) {
        let colGutter = 0;
        let rowGutter = (totalParticipants - 1) * 5;
        let availableWidth = gridWidth - colGutter;
        let availableHeight = gridHeight - rowGutter;
        ratioWidth = availableWidth;
        ratioHeight = availableHeight / totalParticipants;
      } else {
        let colGutter = 5;
        let rowGutter = (Math.ceil(totalParticipants / 2) - 1) * 5;
        let availableWidth = gridWidth - colGutter;
        let availableHeight = gridHeight - rowGutter;
        ratioWidth = availableWidth / 2;
        ratioHeight = availableHeight / Math.ceil(totalParticipants / 2);
      }
      aspectRatio = `${ratioWidth}:${ratioHeight}`;
      if (window.matchMedia("(orientation: landscape)").matches) {
        aspectRatio = "1:1";
      }
    } else {
      aspectRatio = "1:1";
    }
  }
  const { width, height, getPosition } = useMemo(() => {
    return createGrid({
      dimensions,
      count: allParticipants.length,
      aspectRatio,
      gap: 10,
    });
  }, [allParticipants, sessionModeChanged]);
  let patientParticipants = activeParticipants.filter(
    i => i.customParticipantId !== selfParticipant?.customParticipantId
  );
  useEffect(() => {
    if (props.sessionType === "virtual") {
      // let patientParticipants = activeParticipants.filter(
      //   i => i.customParticipantId !== selfParticipant?.customParticipantId
      // );
      // if (sessionModeOfDelivery === "phone") {
      if (patientParticipants?.length > 0 && sessionModeOfDelivery === "phone") {
        changeModeOfDelivery("video");
      } else {
        // changeModeOfDelivery("phone");
      }

      // }
    }
  }, [patientParticipants?.length]);

  const patientPhoneNumber = props.sessionDetailsData?.patientPhoneNumber || "";
  const meetingLink = `https://app${
    APP_ENV === "DEV" ? ".dev" : ""
  }.soulsidehealth.com/virtual-session/${patientId}`;
  const providerName = `${props.sessionDetailsData?.practitionerFirstName || ""}${
    props.sessionDetailsData?.practitionerLastName ? " " : ""
  }${props.sessionDetailsData?.practitionerLastName || ""}`;
  let patientName = props.sessionDetailsData?.patientFirstName || "";
  let patientFullName = `${props.sessionDetailsData?.patientFirstName || ""}${
    props.sessionDetailsData?.patientLastName ? " " : ""
  }${props.sessionDetailsData?.patientLastName || ""}`;
  if (sessionCategory === "group") {
    patientFullName = props.sessionDetailsData?.groupName || "";
  }
  let orgName = props.sessionDetailsData?.organizationName || "";
  if (orgName.includes("Serenity")) {
    orgName = "Serenity Health Clinic";
  }
  const smsReminderMsg = `From: ${orgName} (Dr. ${providerName})\n\nHi ${patientName},\n\nDr. ${providerName} ${
    orgName ? `from ${orgName} ` : ""
  }has joined the appointment room and is waiting for you.\n\nPlease join with the following link now:\n${meetingLink}`;
  let sessionDate = props.sessionDetailsData?.startTime
    ? moment(props.sessionDetailsData.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("Do MMMM")
    : "";
  let sessionTime = props.sessionDetailsData?.startTime
    ? `${moment(props.sessionDetailsData.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("h:mm A")} ${props.preferredTimezone?.abbr || "PT"}`
    : "";
  let meetingLinkMessage = `From: ${orgName} (Dr. ${providerName})\n\nHi ${patientName},\n\nYour appointment ${
    orgName ? `at ${orgName} ` : ""
  }with Dr. ${providerName} is scheduled for ${sessionDate} at ${sessionTime}.\n\nYou can join your appointment with the following link:\n${meetingLink}`;
  useEffect(() => {
    if (meeting?.meta?.sessionId && meeting?.meta?.meetingId) {
      mapProviderSessionId(meeting.meta.sessionId, meeting.meta.meetingId);
    }
  }, [meeting?.meta?.sessionId, meeting?.meta?.meetingId]);
  const mapProviderSessionId = (providerSessionId, providerMeetingId) => {
    let options = {
      providerSessionId,
      sessionCategory,
      modeOfDelivery: sessionModeOfDelivery === "video" ? "virtual" : "in_person",
      sessionId,
      providerMeetingId,
    };
    if (sessionCategory === "individual") {
      options = {
        ...options,
        patientId,
      };
    } else {
      options = {
        ...options,
        groupId: patientId,
      };
    }
    props.mapProviderSessionId(options);
  };
  const patientSessionTime = `${moment(props.sessionDetailsData?.startTime)
    .tz(props.preferredTimezone?.name || "America/Los_Angeles")
    .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"}`;
  useEffect(() => {
    if (props.sessionType === "virtual") {
      let reminderPopupAlreadyShownList = getLocalStorage("sms-reminder-popup-shown") || [];
      if (
        !reminderPopupAlreadyShownList.includes(sessionId) &&
        !patientPhoneNumber &&
        patientParticipants?.length === 0
      ) {
        setTimeout(() => {
          // setShowSMSReminderPopup(true);
          // reminderPopupAlreadyShownList.push(sessionId);
          // addLocalStorage("sms-reminder-popup-shown", reminderPopupAlreadyShownList);
        }, 3000);
      }
      if (patientPhoneNumber && patientParticipants?.length === 0) {
        sendReminderMessage();
      }
    }
  }, []);
  const sendReminderMessage = async () => {
    if (smsReminderMsg) {
      let url = "practitioner-role/patient/send-text-to-phone";
      let payload = {
        contactPhone: patientPhoneNumber,
        mode: "AUTO",
        text: smsReminderMsg,
      };
      // setLoading(true);
      try {
        let response = await post(url, payload);
        if (response?.data) {
          // toast.success("SMS sent");
          // props.toggleModal(false);
        } else {
          // toast.error("Error sending SMS, please verify the phone number");
        }
      } catch (error) {
        console.log(error);

        // toast.error("Error sending SMS, please verify the phone number");
      }
      // setLoading(false);
    }
  };
  const closePatientTab = (patientId, e) => {
    e?.stopPropagation?.();
    let newPatientTabs = patientTabs.filter(patient => patient.patientId !== patientId);
    setActiveTab("meeting");
    setPatientTabs(newPatientTabs);
  };
  return (
    <Loader loading={props.meetingSession?.loading && false}>
      <div className="session-joined-container">
        <DyteParticipantsAudio meeting={meeting} />
        <Tabs
          id="controlled-tab-example"
          activeKey={activeTab}
          onSelect={k => setActiveTab(k)}
          className="session-experience-tabs-list"
        >
          <Tab
            eventKey="meeting"
            title="Session"
          >
            {sessionModeOfDelivery === "phone" && (
              <div className="video-grid-container">
                <div
                  className="audio-video-grid"
                  ref={$grid}
                >
                  <div className="phone-session-content-container">
                    <div className="audio-recorder-text">Audio Recorder</div>
                    <div className="patient-waiting-message">
                      {props.sessionType === "virtual"
                        ? sessionCategory === "individual"
                          ? "Please wait for the patient to join or do a phone call instead"
                          : "Please wait for the patients to join virtually or continue with an in-office session"
                        : "Please make sure that your system is not placed too far from the Patient's voice, so that we can capture notes accurately"}
                    </div>
                    <div className="phone-session-patient-container">
                      <MemberAvatar
                        name={patientFullName}
                        size="md"
                        showFullName={true}
                      />
                      {props.sessionType === "virtual" &&
                        sessionCategory === "individual" &&
                        patientPhoneNumber && (
                          <div className="patient-contact-item">
                            <ReactSVG
                              src={PhoneIcon}
                              className="patient-contact-icon"
                            />
                            <span className="patient-contact-value">{patientPhoneNumber}</span>
                          </div>
                        )}
                      {/* {props.sessionType === "in-person" && ( */}
                      <div className="patient-contact-item">{patientSessionTime}</div>
                      {/* )} */}
                    </div>
                    {sessionCategory === "group" &&
                      props.sessionDetailsData?.sessionPatientMemberDtos?.length > 0 && (
                        <div className="group-patients-list">
                          {props.sessionDetailsData.sessionPatientMemberDtos.map(patient => {
                            return (
                              <div
                                onClick={() => {
                                  onPatientClick(patient);
                                }}
                                key={patient.patientId}
                                className="group-patient-container"
                              >
                                <MemberAvatar
                                  name={`${patient.patientFirstName || ""}${
                                    patient.patientLastName ? " " : ""
                                  }${patient.patientLastName || ""}`}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    {(props.sessionType === "in-person" || sessionCategory === "group") && (
                      <img
                        src={meeting?.self?.audioEnabled ? AudioRecorderIcon : AudioRecorderOffIcon}
                        alt=""
                        className="audio-recorder-icon"
                      />
                    )}
                    {props.sessionType === "virtual" && sessionCategory === "individual" && (
                      <div className="phone-call-instructions">
                        <div className="instruction">Call the patient from your phone</div>
                        <div className="instruction">
                          To record the session keep your phone in speaker mode and try to keep your
                          phone close to your computer
                        </div>
                        <div className="instruction">
                          Make sure your mic on the screen is turned ON
                        </div>
                      </div>
                    )}
                    <div
                      className={`recording-indicator ${
                        !meeting?.self?.audioEnabled ? "recording-paused" : ""
                      }`}
                    >
                      {meeting?.self?.audioEnabled ? "Recording" : "Recording Paused"}
                    </div>
                    <div className="audio-recorder-time">
                      <Timer />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {sessionModeOfDelivery === "video" && (
              // <DyteVideoGrid
              //   states={states}
              //   allParticipants={allParticipants}
              //   meeting={meeting}
              //   setState={setState}
              //   myLanguagePack={myLanguagePack}
              //   sessionModeOfDelivery={props.sessionModeOfDelivery}
              //   activeParticipants={activeParticipants}
              //   selfParticipant={selfParticipant}
              //   // sessionModeChanged={sessionModeChanged}
              // />
              <div
                className={`video-grid-container ${
                  !!states?.activeSidebar && !!states?.sidebar ? "with-sidebar" : ""
                }`}
              >
                <div
                  className="video-grid"
                  ref={$grid}
                >
                  {allParticipants?.length > 0 &&
                    allParticipants.map((participant, index) => {
                      const { top, left } = getPosition(index);
                      const participantPreset = participant.presetName;
                      const patient =
                        sessionCategory === "group"
                          ? props.sessionDetailsData?.sessionPatientMemberDtos?.find(
                              i => i.patientId === participant.customParticipantId
                            )
                          : props.sessionDetailsData?.patientId
                          ? {
                              patientFirstName: props.sessionDetailsData.patientFirstName || "",
                              patientLastName: props.sessionDetailsData.patientLastName || "",
                              patientId: props.sessionDetailsData.patientId,
                            }
                          : null;
                      return (
                        <div
                          key={participant.id}
                          style={
                            participant.customParticipantId &&
                            participantPreset === "session_member" &&
                            patient
                              ? { cursor: "pointer" }
                              : {}
                          }
                          onClick={() => {
                            if (
                              participant.customParticipantId &&
                              participantPreset === "session_member" &&
                              patient
                            ) {
                              onPatientClick(patient);
                            }
                          }}
                        >
                          <DyteParticipantTile
                            participant={participant}
                            style={{
                              width,
                              height,
                              top,
                              left,
                              position: "absolute",
                              transition: "0.4s all",
                            }}
                            key={participant.id}
                            meeting={meeting}
                            size={isMobile ? "sm" : "lg"}
                          >
                            <DyteAvatar
                              participant={participant}
                              size={
                                !isMobile
                                  ? allParticipants.length >= 4
                                    ? "sm"
                                    : allParticipants.length > 2
                                    ? "md"
                                    : "lg"
                                  : allParticipants.length <= 3
                                  ? "lg"
                                  : allParticipants.length <= 6
                                  ? "md"
                                  : "sm"
                              }
                              meeting={meeting}
                            />
                            <DyteNameTag
                              participant={participant}
                              meeting={meeting}
                            >
                              <DyteAudioVisualizer
                                slot="start"
                                meeting={meeting}
                                participant={participant}
                              />
                            </DyteNameTag>
                          </DyteParticipantTile>
                        </div>
                      );
                    })}
                </div>
                {!!states?.activeSidebar && !!states?.sidebar && (
                  <div className="meeting-sidebar">
                    <div className="meeting-sidebar-title-container">
                      <div className="meeting-sidebar-title">
                        {states.sidebar === "chat"
                          ? "Chat"
                          : states.sidebar === "participants"
                          ? "Participants"
                          : ""}
                      </div>
                      <div
                        className="meeting-sidebar-close-btn"
                        onClick={() => {
                          setState({
                            activeSidebar: false,
                            sidebar: undefined,
                          });
                        }}
                      ></div>
                    </div>
                    {states.sidebar === "chat" && (
                      <DyteChat
                        meeting={meeting}
                        style={{ height: "100%", maxWidth: "100%" }}
                      />
                    )}
                    {states.sidebar === "participants" && (
                      <DyteParticipants
                        meeting={meeting}
                        style={{ height: "100%", maxWidth: "100%" }}
                        t={myLanguagePack}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </Tab>
          {patientTabs?.length > 0 &&
            patientTabs.map(tab => {
              const memberNotes = {
                loading: props.patientNotes?.[tab.patientId]?.individualSessionNotes?.loading,
                soapNotes:
                  props.patientNotes?.[tab.patientId]?.individualSessionNotes?.data?.[0]
                    ?.soapNote || "",
                soapNotesJson:
                  props.patientNotes?.[tab.patientId]?.individualSessionNotes?.data?.[0]
                    ?.jsonSoapNote || null,
                firstName: tab.firstName || "",
                lastName: tab.lastName || "",
                name: `${tab.firstName} ${tab.lastName}`,
              };
              return (
                <Tab
                  eventKey={tab.patientId}
                  key={tab.patientId}
                  title={
                    <PatientTabTitle
                      title={`${tab.firstName} ${tab.lastName}`}
                      sessionCategory={sessionCategory}
                      onClose={e => closePatientTab(tab.patientId, e)}
                    />
                  }
                >
                  <SessionMemberNotes
                    memberNotes={memberNotes}
                    preferredTimezone={props.preferredTimezone}
                    sessionDetailsData={props.sessionDetailsData}
                    sessionType={props.sessionType}
                    patientId={tab.patientId}
                    groupSessionNotes={props.patientNotes?.[tab.patientId]?.groupSessionNotes}
                  />
                </Tab>
              );
            })}
        </Tabs>
        <div className="meeting-controls-wrapper">
          <div className={`session-mode-of-delivery-switch`}>
            {props.sessionType === "virtual" && sessionCategory === "individual" && (
              <Form.Group
                className="custom-input-field mode-of-delivery-switch"
                controlId="modeOfDelivery-input"
              >
                <Form.Label>
                  {sessionModeOfDelivery === "video" ? (
                    <div>
                      <div>Do a phone call instead?</div>Switch to Phone Call
                    </div>
                  ) : (
                    "Switch back to video call"
                  )}
                </Form.Label>
                <Form.Check
                  type="switch"
                  label={sessionModeOfDelivery === "phone" ? "" : ""}
                  checked={sessionModeOfDelivery === "phone"}
                  onChange={() =>
                    changeModeOfDelivery(sessionModeOfDelivery === "phone" ? "video" : "phone")
                  }
                  className={sessionModeOfDelivery}
                />
              </Form.Group>
            )}
          </div>
          <div className="meeting-controls-container">
            <DyteMicToggle
              size={"md"}
              meeting={meeting}
              states={states}
            />
            {sessionModeOfDelivery === "video" && (
              <>
                <DyteCameraToggle
                  size={"md"}
                  meeting={meeting}
                  states={states}
                />
                {!isMobile && (
                  <>
                    <DyteChatToggle
                      size={"md"}
                      meeting={meeting}
                      states={states}
                      onDyteStateUpdate={event => {
                        setState({
                          activeSidebar: event?.detail?.activeSidebar,
                          sidebar: event?.detail?.sidebar,
                        });
                      }}
                    />
                    <DyteParticipantsToggle
                      size="md"
                      meeting={meeting}
                      states={states}
                      onDyteStateUpdate={event => {
                        setState({
                          activeSidebar: event?.detail?.activeSidebar,
                          sidebar: event?.detail?.sidebar,
                        });
                      }}
                    />
                  </>
                )}
              </>
            )}
            <DyteSettingsToggle
              size={"md"}
              meeting={meeting}
              states={states}
            />
            {/* <DyteLeaveButton
              size={"md"}
              meeting={meeting}
              states={states}
            /> */}
            <div
              className="session-leave-btn"
              onClick={() => setMeetingLeaveModalOpen(true)}
            >
              <ReactSVG
                src={LeaveIcon}
                className="session-leave-icon"
              />
              Leave
            </div>
          </div>
          <div className="show-notes-btn">
            {/* <SecondaryButton onClick={() => props.setShowNotes(!props.showNotes)}>
              {props.showNotes ? "Hide" : "Show"} Appointment/Notes
            </SecondaryButton> */}
          </div>
        </div>
        <SendText
          modalOpen={showSMSReminderPopup}
          toggleModal={setShowSMSReminderPopup}
          phoneNumber={patientPhoneNumber}
          message={smsReminderMsg}
          title={"Let the patient know that you have entered the room"}
        />
        <SendText
          modalOpen={showSMSMeetingLinkPopup}
          toggleModal={setShowSMSMeetingLinkPopup}
          phoneNumber={patientPhoneNumber}
          message={meetingLinkMessage}
        />
        <AlertModal
          modalOpen={meetingLeaveModalOpen}
          onConfirm={async () => {
            await meeting?.participants?.kickAll();
            setMeetingLeaveModalOpen(false);
          }}
          className="session-leave-alert-modal"
          onCancel={async () => {
            setMeetingLeaveModalOpen(false);
          }}
          title={"Leave"}
          desc={"Are you sure you want to end the session?"}
          confirmBtnText={"End Session"}
          cancelBtnText={"Cancel"}
          actionItemReversed={true}
        />
      </div>
    </Loader>
  );
}

export default SessionJoined;

const DyteVideoGrid = props => {
  let {
    states,
    allParticipants,
    meeting,
    setState,
    myLanguagePack,
    sessionModeChanged,
    activeParticipants,
    selfParticipant,
  } = props;
  const $grid = useRef(null);
  const dimensions = useGridDimensions($grid);
  const { width, height, getPosition } = useMemo(() => {
    return createGrid({
      dimensions,
      count: allParticipants.length,
      aspectRatio: "16:9",
      gap: 10,
    });
  }, [allParticipants]);
  return (
    <div
      className={`video-grid-container ${
        !!states?.activeSidebar && !!states?.sidebar ? "with-sidebar" : ""
      }`}
    >
      <div
        className="video-grid"
        ref={$grid}
      >
        {allParticipants?.length > 0 &&
          allParticipants.map((participant, index) => {
            const { top, left } = getPosition(index);
            const participantPreset = participant.presetName;
            return (
              <div key={participant.id}>
                <DyteParticipantTile
                  participant={participant}
                  style={{
                    width,
                    height,
                    top,
                    left,
                    position: "absolute",
                    // transition: "0.4s all",
                  }}
                  key={participant.id}
                  meeting={meeting}
                >
                  <DyteAvatar
                    participant={participant}
                    size={
                      allParticipants.length >= 4 ? "sm" : allParticipants.length > 2 ? "md" : "lg"
                    }
                    meeting={meeting}
                  />
                  <DyteNameTag
                    participant={participant}
                    meeting={meeting}
                  >
                    <DyteAudioVisualizer
                      slot="start"
                      meeting={meeting}
                      participant={participant}
                    />
                  </DyteNameTag>
                </DyteParticipantTile>
              </div>
            );
          })}
      </div>
      {!!states?.activeSidebar && !!states?.sidebar && (
        <div className="meeting-sidebar">
          <div className="meeting-sidebar-title-container">
            <div className="meeting-sidebar-title">
              {states.sidebar === "chat"
                ? "Chat"
                : states.sidebar === "participants"
                ? "Participants"
                : ""}
            </div>
            <div
              className="meeting-sidebar-close-btn"
              onClick={() => {
                setState({
                  activeSidebar: false,
                  sidebar: undefined,
                });
              }}
            ></div>
          </div>
          {states.sidebar === "chat" && (
            <DyteChat
              meeting={meeting}
              style={{ height: "100%", maxWidth: "100%" }}
            />
          )}
          {states.sidebar === "participants" && (
            <DyteParticipants
              meeting={meeting}
              style={{ height: "100%", maxWidth: "100%" }}
              t={myLanguagePack}
            />
          )}
        </div>
      )}
    </div>
  );
};

const Timer = () => {
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let interval = null;

    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const reset = () => {
    setSeconds(0);
    setIsActive(false);
  };

  const formatTime = totalSeconds => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  return <span>{formatTime(seconds)}</span>;
};

function useGridDimensions($el, sessionModeChanged) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (!$el.current) {
      throw new Error("good-grid: Element reference not set.");
    }
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { clientWidth: width, clientHeight: height } = entry.target;

        setDimensions({ width, height });
      }
    });
    observer.observe($el.current);
    return () => {
      observer.disconnect();
    };
  }, [sessionModeChanged]);
  return dimensions;
}

const PatientTabTitle = ({ title, sessionCategory, onClose }) => {
  return (
    <div className="patient-tab-title">
      {title}{" "}
      {sessionCategory === "group" && (
        <span
          className="patient-tab-close-btn"
          onClick={onClose}
        ></span>
      )}
    </div>
  );
};
