import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDyteClient, DyteProvider } from "@dytesdk/react-web-core";
import { Loader, SecondaryButton } from "@/components";
import VideoWidget from "./components/VideoWidget";
import SessionMemberNotes from "../SessionMemberNotes";
import SessionAppointments from "../SessionAppointments";
import NotesIcon from "@/assets/tasks/notes.svg";
import AppointmentsIcon from "@/assets/nav-icons/appointments.svg";
import { ReactSVG } from "react-svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { sendLiveTranscript, sendNetworkStatData } from "@/Routes/Appointments/appointmentsModule";
import "./VirtualSession.scss";

function VirtualSession(props) {
  const [meeting, initMeeting] = useDyteClient();
  const { sessionCategory, patientId, sessionId } = useParams();
  const [selectedMember, setSelectedMember] = useState(null);
  const [rightContent, setRightContent] = useState(true);
  const [showNotes, setShowNotes] = useState(true);
  const [showAppointments, setShowAppointments] = useState(false);
  useEffect(() => {
    return () => {
      endSession();
      props.hideSideBar(false);
    };
  }, []);
  const providerName = `${props.sessionDetails?.data?.practitionerFirstName || ""}${
    props.sessionDetails?.data?.practitionerLastName ? " " : ""
  }${props.sessionDetails?.data?.practitionerLastName || ""}`;
  let patientName = `${props.sessionDetails?.data?.patientFirstName || ""}${
    props.sessionDetails?.data?.patientLastName ? " " : ""
  }${props.sessionDetails?.data?.patientLastName || ""}`;
  let orgName = props.sessionDetails?.data?.organizationName || "";
  useEffect(() => {
    let intervalId = null;
    if ("connection" in navigator) {
      const connection =
        navigator.connection || navigator.mozConnection || navigator.webkitConnection;

      function updateConnectionStatus() {
        let options = {
          patientId,
          payload: {
            connection: {
              effectiveType: connection.effectiveType,
              downlink: connection.downlink,
              rtt: connection.rtt,
            },
            date: new Date().getTime(),
            providerName,
            patientName,
            patientId,
            orgName,
            preferredTimezone: props.preferredTimezone,
          },
        };
        // sendNetworkStatData(options);
        // logNetworkStat(options.payload);
      }

      connection.addEventListener("change", updateConnectionStatus);
      updateConnectionStatus(); // Initial log
      intervalId = setInterval(() => {
        updateConnectionStatus();
      }, 10000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [props.sessionDetails?.data]);
  const logNetworkStat = data => {
    console.log(" ");
    console.log("********************* Network Stat *********************");
    console.log(
      "Time",
      data?.date
        ? `${new Date(data.date).toLocaleString("en", {
            timeZone: data?.preferredTimezone?.name || "America/Los_Angeles",
          })} ${data?.preferredTimezone?.abbr || "PT"}`
        : ""
    );
    console.log("Organization Name:", data?.orgName || "");
    console.log("Provider Name:", data?.providerName || "");
    console.log("Patient Name:", data?.patientName || "");
    console.log("Patient ID:", data?.patientId);
    console.log("Connection type:", data?.connection?.effectiveType);
    console.log("Downlink:", data?.connection?.downlink, "Mbps");
    console.log("RTT:", data?.connection?.rtt, "ms");
    console.log(" ");
  };
  useEffect(() => {
    if (
      props.meetingToken.data?.[patientId]?.token &&
      props.meetingToken.data?.[patientId]?.sessionCategory === sessionCategory
    ) {
      initializeMeeting();
    } else {
      props.getMeetingToken({ patientId, sessionCategory, groupId: patientId });
    }
  }, [props.meetingToken.data?.[patientId]?.token]);
  const joinRoom = m => {
    try {
      m.join();
    } catch (error) {
      console.log("error", error);
      setTimeout(() => {
        joinRoom(m);
      }, 5000);
    }
  };
  const initializeMeeting = async () => {
    try {
      const m = await initMeeting({
        authToken: props.meetingToken.data[patientId].token,
        defaults: {
          audio: true,
          video: false,
        },
      });
      if (m) {
        // if (props.sessionType === "in-person") {
        joinRoom(m);
        // }
        m.recording.updateRecordings = () => {};
        // m.recording.on("recordingUpdate", () => {
        //   console.log("recording disabled");
        //   throw "dummyerror";
        // });
        // m.meta.on("mediaConnectionUpdate", ({ transport, state }) => {
        //   console.log("mediaConnectionUpdate", transport, state);

        //   // transport - 'consuming' | 'producing'
        //   // state - 'new' | 'connecting' | 'connected' | 'disconnected' | 'reconnecting' | 'failed'
        // });
        // m.meta.on("socketConnectionUpdate", ({ state, reconnectionAttempt, reconnected }) => {
        //   // console.log("socketConnectionUpdate", state, reconnectionAttempt, reconnected, m);
        //   if (state === "connected" && !!reconnected && m.self.roomState === "disconnected") {
        //     // console.log("trying again");
        //     // m.join();
        //   }
        //   // state - 'connected' | 'disconnected' | 'reconnecting' | 'failed'
        // });
        m?.ai?.on("transcript", transcriptData => {
          if (!transcriptData?.isPartialTranscript) {
            let joinedParticipants = m?.participants?.joined?.toArray() || [];
            const selfParticipant = m?.self;
            let allParticipants = [selfParticipant, ...joinedParticipants];
            let transcriptUser = allParticipants.find(i => i.userId === transcriptData?.userId);
            let transcriptDataUpdated = {
              ...transcriptData,
              customParticipantId: transcriptUser.customParticipantId,
              isProvider:
                transcriptUser.customParticipantId === selfParticipant?.customParticipantId,
              providerName,
              patientName,
              orgName,
              preferredTimezone: props.preferredTimezone,
            };
            sendLiveTranscript({ payload: transcriptDataUpdated, patientId });
            // console.log("transcriptDataUpdated", transcriptData);
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const endSession = async () => {
    try {
      let roomState = meeting?.self?.roomState || "";
      if (roomState !== "init" && roomState !== "ended") {
        if (roomState === "left") {
          if (props.meetingToken.data[patientId].token) {
            let m = await initMeeting({
              authToken: props.meetingToken.data[patientId].token,
              defaults: {
                audio: false,
                video: false,
              },
            });
            if (m) {
              // m?.recording?.on("recordingUpdate", () => {
              //   console.log("recording disabled");
              //   throw "dummyerror";
              // });
              m.recording.updateRecordings = () => {};
              await m.join();
              await m?.participants?.kickAll();
            }
          }
        } else {
          await meeting?.participants?.kickAll();
        }
      }
    } catch (error) {
      console.log(error);
    }
    // navigate(`/session-details/${sessionCategory}/virtual/${sessionId}/${patientId}`);
  };
  const roomState = meeting?.self?.roomState || "";
  const sidePanelExpanded = !!rightContent || roomState === "init";
  return (
    <div className="session-dashboard-container">
      <Loader loading={props.meetingToken.loading || props.sessionDetails.loading}>
        <DyteProvider
          value={meeting}
          fallback={<Loader loading={true} />}
        >
          <div className="session-wrapper">
            <VideoWidget
              meeting={meeting}
              initMeeting={initMeeting}
              setSelectedMember={setSelectedMember}
              meetingToken={props.meetingToken.data?.[patientId]?.token || ""}
              sessionDetailsData={props.sessionDetails.data}
              showMemberNotes={sessionCategory !== "group"}
              setShowNotes={flag => setRightContent(!!flag)}
              showNotes={!!rightContent}
              addTask={props.addTask}
              preferredTimezone={props.preferredTimezone}
              mapProviderSessionId={props.mapProviderSessionId}
              hideSideBar={props.hideSideBar}
              meetingSession={props.meetingSession}
              changeMeetingSession={props.changeMeetingSession}
              sessionType={props.sessionType}
              sideBarHidden={props.sideBarHidden}
              getTodaysSessionsByPractitionerRole={props.getTodaysSessionsByPractitionerRole}
              memberNotes={props.memberNotes}
              patientTreatmentPlan={props.patientTreatmentPlan}
              patientNotes={props.patientNotes}
              getPatientNotes={props.getPatientNotes}
            />
          </div>
          {(roomState === "init" || roomState === "joined") && (
            <div className={`session-page-right ${!!sidePanelExpanded ? "expanded" : "collapsed"}`}>
              {!!sidePanelExpanded && roomState != "init" && (
                <div
                  className="collapse-btn"
                  onClick={() => setRightContent(false)}
                >
                  <div className="collapse-icon"></div>
                </div>
              )}
              {!sidePanelExpanded && (
                <div className="session-page-right-collapsed-container">
                  {sessionCategory !== "group" && (
                    <OverlayTrigger
                      overlay={<Tooltip>Notes</Tooltip>}
                      placement="left"
                    >
                      <div
                        className="session-page-right-action"
                        onClick={() => setRightContent(true)}
                      >
                        <ReactSVG
                          src={NotesIcon}
                          className="session-page-right-action-icon"
                        />
                      </div>
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger
                    overlay={<Tooltip>Appointments</Tooltip>}
                    placement="left"
                  >
                    <div
                      className="session-page-right-action"
                      onClick={() => setRightContent(true)}
                    >
                      <ReactSVG
                        src={AppointmentsIcon}
                        className="session-page-right-action-icon"
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              )}
              {!!sidePanelExpanded && sessionCategory !== "group" && (
                <SessionMemberNotes
                  memberNotes={props.memberNotes}
                  hideNotes={() => setRightContent(false)}
                  preferredTimezone={props.preferredTimezone}
                  sessionDetailsData={props.sessionDetails.data}
                  sessionType={props.sessionType}
                  patientTreatmentPlan={props.patientTreatmentPlan}
                  patientId={patientId}
                />
              )}
              {!!sidePanelExpanded && (
                <SessionAppointments
                  endSession={endSession}
                  sessionEnded={meeting?.self?.roomState === "ended"}
                  currentSessionStatus={"Live"}
                  sessionType={props.sessionType}
                />
              )}
            </div>
          )}
        </DyteProvider>
      </Loader>
    </div>
  );
}

export default VirtualSession;
