import Patients from "./Patients";
import { connect } from "react-redux";
import {
  getPatients,
  addPatient,
  getDummyPhoneNumber,
  getDummyEmail,
  getPatientsAlerts,
} from "./patientsModule";

const mapStateToProps = state => {
  return {
    patients: state.patients.patients,
    patientAlertData: state.patients.patientAlertData,
    alertsSaved: state.patients.alertsSaved,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPatients: options => dispatch(getPatients(options)),
    addPatient: options => dispatch(addPatient(options)),
    getDummyPhoneNumber: () => dispatch(getDummyPhoneNumber()),
    getDummyEmail: name => dispatch(getDummyEmail(name)),
    getPatientsAlerts: patientId => dispatch(getPatientsAlerts(patientId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
