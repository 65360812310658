import React, { useEffect, useRef, useState } from "react";
import { RichTextEditor } from "@/components/RichTextEditor";
import { useParams } from "react-router-dom";
import { marked } from "marked";
import { dischargeSummary, weeklySummary, dischargeSummaryJson } from "@/services/dummyData";
import { Modal, Accordion, ProgressBar } from "react-bootstrap";
import "./DischargeSummary.scss";
import { PrimaryButton, SecondaryButton, Loader, TertiaryButton } from "@/components";
import html2pdf from "html2pdf.js";
import { toast } from "react-toastify";
import { Overlay, Tooltip } from "react-bootstrap";
import Calendar from "react-calendar";
import moment from "moment-timezone";
import { PatientSessionCard } from "../PatientSessions/PatientSessions";

const convertMarkdownToHtml = text => {
  return marked.parse(text);
};

const weekData = [
  { week: 1, date: "2024-08-05" },
  { week: 2, date: "2024-08-12" },
  { week: 3, date: "2024-08-19" },
  { week: 4, date: "2024-08-26" },
];

const DATE_FORMAT = "MMM DD, YYYY";

function DischargeSummary(props) {
  const [data, setData] = useState("");
  const [extraData, setExtraData] = useState(null);
  const { patientId } = useParams();
  const [supportingEvidenceList, setSupportingEvidenceList] = useState([]);
  const [currentView, setCurrentView] = useState("summary");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);
  const startCalendarRef = useRef(null);
  const endCalendarRef = useRef(null);
  useEffect(() => {
    if (!!props.modalOpen) {
      fetchData();
    }
  }, [props.sessions, props.modalOpen]);
  const fetchData = async () => {
    if (!props.dischargeSummary?.loading) {
      let data = await props.getDischargeSummary({ patientId });
      if (!!data?.dischargeSummary?.data) {
        setData(data.dischargeSummary.data);
        setExtraData(data);
      } else {
        if (props.sessions?.length > 0) {
          generateDischargeSummary();
        }
      }
    }
  };
  const downloadPdf = () => {
    let intakeSessionTime = "";
    if (props.dischargeSummarySession?.data?.intakeSession?.startTime) {
      intakeSessionTime = `${moment(props.dischargeSummarySession?.data?.intakeSession?.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMMM DD")}, ${moment(props.dischargeSummarySession?.data?.intakeSession?.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"}`;
    }
    let lastSessionTime = "";
    if (props.dischargeSummarySession?.data?.latestSession?.startTime) {
      lastSessionTime = `${moment(props.dischargeSummarySession?.data?.latestSession?.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMMM DD")}, ${moment(props.dischargeSummarySession?.data?.latestSession?.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"}`;
    }
    // Create a temporary container for the HTML string
    const container = document.createElement("div");
    container.style = "display: none;";
    let html = `
    <div class="discharge-summary-pdf">
      <div class="pdf-clinic-name-container">
        <div class="pdf-clinic-name">${props?.patientData?.organizationName || ""}</div>
      </div>
      <div class="pdf-patient-details-container">
        <div class="pdf-patient-item"><label class="pdf-patient-item-label">Patient Name:</label> ${
          props.patientData?.firstName || ""
        } ${props.patientData?.lastName || ""}</div>
        <div class="pdf-patient-item"></div>
        <div class="pdf-patient-item"><label class="pdf-patient-item-label">Intake Appointment:</label> ${intakeSessionTime}</div>
        <div class="pdf-patient-item"><label class="pdf-patient-item-label">Last Appointment:</label> ${lastSessionTime}</div>
      </div>
      ${data}
    </div>`;
    container.innerHTML = html;

    // Append the container to the body (or any other element)
    document.body.appendChild(container);
    var opt = {
      margin: 0.7,
      filename: "discharge-summary.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };
    // Convert the HTML to PDF and download
    // html2pdf().from(container).set(opt).save();
    html2pdf()
      .from(container.innerHTML)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(pdf => {
        // Remove the container from the body after PDF is generated
        document.body.removeChild(container);

        // Save the PDF
        pdf.save(`${props.patientData?.firstName}-discharge-summary.pdf`);
      });
  };
  const onSaveChanges = async (isEdit, newData, selectedIntakeSession, selectedLatestSession) => {
    let payload = isEdit
      ? {
          ...props.dischargeSummary.data,
          createdByPractitionerRoleId: props.selectedUserRole?.id,
          patientId,
          dischargeSummary: props.dischargeSummary.data?.dischargeSummary?.data
            ? {
                ...props.dischargeSummary.data.dischargeSummary,
                data: newData,
              }
            : {
                data: newData,
                intakeSession: selectedIntakeSession,
                latestSession: selectedLatestSession,
              },
          updatedAt: new Date().toISOString(),
        }
      : {
          createdByPractitionerRoleId: props.selectedUserRole?.id,
          patientId: patientId,
          dischargeSummary: {
            data: newData,
            intakeSession: selectedIntakeSession,
            latestSession: selectedLatestSession,
          },
        };
    console.log("payload", payload);
    await props.saveDischargeSummary({
      patientId: patientId,
      payload,
      isEdit,
    });
  };
  const generateDischargeSummary = async () => {
    let sessions =
      props.sessions?.filter(session => {
        return session.sessionCategory === "INDIVIDUAL" && session.patientId === patientId;
      }) || [];
    sessions = sessions.sort(
      (session1, session2) => new Date(session2.startTime) - new Date(session1.startTime)
    );
    let intakeSessions = sessions.filter(session => session.appointmentType === "INTAKE");
    sessions = sessions.filter(session => session.appointmentType !== "INTAKE");
    sessions = sessions.filter(session => session.sessionCategory === "INDIVIDUAL");
    let isEdit = !!props.dischargeSummary?.data;
    if (sessions?.length > 0) {
      let { dischargeSummaryData, extraData, selectedIntakeSession, selectedLatestSession } =
        await props.generateDischargeSummary({
          sessions,
          intakeSessions,
          patientId,
        });
      if (dischargeSummaryData) {
        if (dischargeSummaryData.dischargeSummary?.data) {
          setData(dischargeSummaryData.dischargeSummary.data);
          setExtraData(extraData);
          onSaveChanges(
            isEdit,
            dischargeSummaryData.dischargeSummary.data,
            selectedIntakeSession,
            selectedLatestSession
          );
        }
        if (dischargeSummaryData.supportingEvidenceList?.length > 0) {
          setSupportingEvidenceList(dischargeSummaryData.supportingEvidenceList);
        }
      } else {
        toast.error("Error generating discharge summary");
      }
    } else {
      toast.error("No sessions found in this timerange");
    }
  };
  const lastUpdatedOn = props.dischargeSummary?.data?.updatedAt
    ? moment(props.dischargeSummary.data.updatedAt)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMM DD, YYYY")
    : moment()
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMM DD, YYYY");
  let intakeSession = props.dischargeSummarySession?.data?.intakeSession;
  let latestSession = props.dischargeSummarySession?.data?.latestSession;
  let intakeSessionTime = intakeSession?.startTime
    ? `${moment(intakeSession.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMMM DD")}, ${moment(intakeSession.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"}`
    : "";
  let latestSessionTime = latestSession?.startTime
    ? `${moment(latestSession.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMMM DD")}, ${moment(latestSession.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"}`
    : "";
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="discharge-summary-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Discharge Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProgressLoader
          loading={props.dischargeSummary?.loading}
          dischargeSummarySession={props.dischargeSummarySession?.data}
          preferredTimezone={props.preferredTimezone}
        >
          {currentView === "date" ? (
            <div className="discharge-summary-date-selection">
              <div className="discharge-summary-date-selection-label">
                Generate Discharge Summary from
              </div>
              <div className="date-list">
                <div className="discharge-date-wrapper">
                  <div className="discharge-date-label">Admission Date:</div>
                  <div
                    className="discharge-date-container"
                    ref={startCalendarRef}
                    onClick={() => {
                      setShowStartCalendar(!showStartCalendar);
                      if (showEndCalendar) {
                        setShowEndCalendar(false);
                      }
                    }}
                  >
                    <div className="discharge-date">
                      {startDate ? moment(startDate).format(DATE_FORMAT) : "MM DD, YYYY"}
                    </div>
                  </div>
                  <Overlay
                    target={startCalendarRef.current}
                    show={showStartCalendar}
                    placement="bottom"
                    rootClose={true}
                  >
                    {props => (
                      <Tooltip
                        id="start-calendar-overlay"
                        className="calendar-overlay"
                        {...props}
                      >
                        <Calendar
                          onChange={date => {
                            setStartDate(date);
                            setShowStartCalendar(false);
                          }}
                          value={startDate ? moment(startDate) : null}
                        />
                      </Tooltip>
                    )}
                  </Overlay>
                </div>
                <div className="separator">To</div>
                <div className="discharge-date-wrapper">
                  <div className="discharge-date-label">Discharge Date:</div>
                  <div
                    className="discharge-date-container"
                    ref={endCalendarRef}
                    onClick={() => {
                      setShowEndCalendar(!showEndCalendar);
                      if (showStartCalendar) {
                        setShowStartCalendar(false);
                      }
                    }}
                  >
                    <div className="discharge-date">
                      {endDate ? moment(endDate).format(DATE_FORMAT) : "MM DD, YYYY"}
                    </div>
                  </div>
                  <Overlay
                    target={endCalendarRef.current}
                    show={showEndCalendar}
                    placement="bottom"
                    rootClose={true}
                  >
                    {props => (
                      <Tooltip
                        id="end-calendar-overlay"
                        className="calendar-overlay"
                        {...props}
                      >
                        <Calendar
                          onChange={date => {
                            setEndDate(date);
                            setShowEndCalendar(false);
                          }}
                          value={endDate ? moment(endDate) : null}
                        />
                      </Tooltip>
                    )}
                  </Overlay>
                </div>
              </div>
              <PrimaryButton
                disabled={!startDate || !endDate}
                onClick={generateDischargeSummary}
              >
                Generate
              </PrimaryButton>
            </div>
          ) : (
            <>
              <div className="discharge-summary-header-container">
                <div className="discharge-summary-last-updated">
                  Last updated on: {lastUpdatedOn}
                </div>
                <div className="action-btns">
                  <TertiaryButton onClick={generateDischargeSummary}>
                    Regenerate Discharge Summary
                  </TertiaryButton>
                  <SecondaryButton onClick={downloadPdf}>Download PDF</SecondaryButton>
                  <PrimaryButton onClick={() => onSaveChanges(true, data)}>
                    Save Changes
                  </PrimaryButton>
                </div>
              </div>
              {intakeSession && latestSession && (
                <div className="selected-session-time-container">
                  <div className="selected-session-time-item">
                    <strong>Intake Session Date:</strong> {intakeSessionTime}
                  </div>
                  <div className="selected-session-time-item">
                    <strong>Latest Session Date:</strong> {latestSessionTime}
                  </div>
                </div>
              )}
              <div className="discharge-summary-container">
                <div className="discharge-summary-content">
                  <RichTextEditor
                    value={data}
                    setValue={setData}
                    hideCopy={true}
                  />
                </div>
                {/* <div className="weekly-summary-container">
                  <Accordion
                    defaultActiveKey={"week-1"}
                    className="weekly-summary-list"
                  >
                    {supportingEvidenceList?.map((week, index) => {
                      return (
                        <Accordion.Item
                          eventKey={`week-${week.weekNumber}`}
                          key={`week-${week.weekNumber}`}
                          className="weekly-summary-item"
                        >
                          <Accordion.Header className="weekly-summary-item-name">
                            Week {week.weekNumber} (Date: {week.date})
                          </Accordion.Header>
                          <Accordion.Body className="weekly-summary-item-content">
                            {Object.keys(week.filteredContentFromTranscript).map(section => {
                              return (
                                <div
                                  className="discharge-summary-section"
                                  key={section}
                                >
                                  <div className="section-title">{section}</div>
                                  {week.filteredContentFromTranscript[section].length > 0 && (
                                    <div className="section-transcripts">
                                      {week.filteredContentFromTranscript[section].map(
                                        (transctipt, index) => {
                                          let memberName = Object.keys(transctipt)?.[0] || "";
                                          let transcriptText =
                                            transctipt?.[Object.keys(transctipt)?.[0]] || "";
                                          if (memberName === "text") {
                                            memberName = "";
                                          }
                                          return (
                                            <div
                                              className="transcript-item"
                                              key={index}
                                            >
                                              <div className="transcript-text">
                                                <span className="transcript-name">
                                                  {memberName}
                                                  {memberName ? ":" : ""}
                                                </span>{" "}
                                                {transcriptText}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </div> */}
              </div>
            </>
          )}
        </ProgressLoader>
      </Modal.Body>
    </Modal>
  );
}

export default DischargeSummary;

const ProgressLoader = props => {
  let dischargeSummarySession = props.dischargeSummarySession;
  let intakeSession = dischargeSummarySession?.intakeSession;
  let latestSession = dischargeSummarySession?.latestSession;
  if (props.loading) {
    return (
      <div className="progress-loader">
        {intakeSession && latestSession && (
          <div className="selected-session-details">
            <div className="selected-session-item">
              <div className="selected-session-label">Intake Session</div>
              <PatientSessionCard
                session={intakeSession}
                preferredTimezone={props.preferredTimezone}
                hideDetailsLink={true}
              />
            </div>
            <div className="selected-session-item">
              <div className="selected-session-label">Latest Session</div>
              <PatientSessionCard
                session={latestSession}
                preferredTimezone={props.preferredTimezone}
                hideDetailsLink={true}
              />
            </div>
          </div>
        )}
        <ProgressBar
          animated
          now={100}
        />
        <div className="loading-msg">Generating Discharge Summary</div>
      </div>
    );
  } else {
    return props.children || <></>;
  }
};
