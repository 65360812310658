import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "@/components";
import PatientNotes from "./components/PatientNotes";
import Transcript from "./components/Transcript";
import TranscriptLoader from "./components/TranscriptLoader";
import { ReactSVG } from "react-svg";
import NotesIcon from "@/assets/tasks/notes.svg";
import "./SessionDetails.scss";

import SpeakerMapping from "./components/SpeakerMapping";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

const defaultData = {
  session: {
    data: null,
    loading: false,
  },
  memberNotes: {
    data: {},
    loading: false,
    updateLoading: false,
  },
  soapNotes: {
    data: "",
    notesId: "",
    loading: false,
    soapNotesJson: null,
  },
  narrative: {
    data: "",
    loading: false,
  },
  bhPredictions: {
    data: null,
    notesId: "",
    loading: false,
  },
  sessionTranscript: {
    data: [],
    loading: false,
  },
  treatmentPlan: {
    data: null,
    loading: false,
  },
  speakerProfileEnrollment: {
    loading: false,
    data: [],
  },
  speakerRecognition: {
    loading: false,
    data: {},
  },
  sessionDetailsData: {},
};

function SessionDetails(props) {
  const [hideTranscript, setHideTranscript] = useState(false);
  const [speakerMappingOpen, setSpeakerMappingOpen] = useState(false);
  let { sessionCategory, sessionType, sessionId, patientId } = useParams();
  if (props.fromPatientChart) {
    ({ sessionCategory, sessionType, sessionId, patientId } = props);
  }
  const {
    memberNotes = defaultData.memberNotes,
    soapNotes = defaultData.soapNotes,
    narrative = defaultData.narrative,
    bhPredictions = defaultData.bhPredictions,
    sessionTranscript = defaultData.sessionTranscript,
    session = defaultData.session,
    treatmentPlan = defaultData.treatmentPlan,
    speakerProfileEnrollment = defaultData.speakerProfileEnrollment,
    speakerRecognition = defaultData.speakerRecognition,
  } = props.sessionDetailsData?.[sessionId] || {};
  useEffect(() => {
    getData();
  }, [sessionId]);
  useEffect(() => {
    if (!session?.loading && session?.data?.id) {
      let patientName =
        session?.data?.sessionCategory === "INDIVIDUAL"
          ? `${session?.data?.patientFirstName || ""} ${session?.data?.patientLastName || ""}`
          : session?.data?.groupName;
      document.title = `Notes | ${patientName}`;
    } else {
      document.title = "Notes";
    }
  }, [session?.data, patientId]);
  const getData = async () => {
    await props.getSessionDetails({ sessionId, sessionCategory });
    props.getSessionDetailsTranscript({
      sessionCategory,
      sessionId,
      triggerTime: new Date(),
    });
    props.getSessionMemberNotes({ sessionId, patientId, groupId: patientId });
  };
  const enableVoiceRecognitionFeature =
    APP_ENV === "DEV" ||
    props.selectedUserRole?.organizationId === "de4c00e0-101a-4483-a4b4-b6ca0041946b"; //Enabled on all dev orgs & Soulside Inc (anurag+soulside_owner@getsoulside.com) org on prod
  return (
    <TranscriptLoader
      loading={sessionTranscript?.loading || session.loading}
      loadingMsg={"Please wait while we fetch the session transcript"}
    >
      <div className={`session-details-container`}>
        {!speakerMappingOpen && (
          <div className={`session-details-wrapper ${hideTranscript ? "hide-transcript" : ""}`}>
            <PatientNotes
              memberNotes={memberNotes}
              soapNotes={soapNotes}
              narrative={narrative}
              updateMemberNotes={props.updateMemberNotes}
              generateSoapNotes={props.generateSoapNotes}
              saveSoapNotes={props.saveSoapNotes}
              sessionId={sessionId}
              groupId={sessionCategory === "group" ? patientId : null}
              patientId={sessionCategory === "individual" ? patientId : null}
              sessionType={sessionType}
              sessionCategory={sessionCategory}
              transcriptData={sessionTranscript?.data || []}
              bhPredictions={bhPredictions}
              generateBhPredictions={props.generateBhPredictions}
              saveBhPredictions={props.saveBhPredictions}
              hideTranscript={hideTranscript}
              toggleTranscript={setHideTranscript}
              sessionData={session.data}
              removeTask={props.removeTask}
              preferredTimezone={props.preferredTimezone}
              treatmentPlan={treatmentPlan}
              generateTreatmentPlan={props.generateTreatmentPlan}
              sessionTranscript={sessionTranscript}
              generateNarrative={props.generateNarrative}
              saveNarrativeData={props.saveNarrativeData}
              selectedUserRole={props.selectedUserRole}
              speakerProfileEnrollment={speakerProfileEnrollment}
              getSessionSpeakerProfilesEnrollmentStatus={
                props.getSessionSpeakerProfilesEnrollmentStatus
              }
              speakerRecognition={speakerRecognition}
              getSessionSpeakerRecognitionStatus={props.getSessionSpeakerRecognitionStatus}
              enableVoiceRecognitionFeature={enableVoiceRecognitionFeature}
            />
            {!hideTranscript ? (
              <div className="session-transcript-wrapper">
                <OverlayTrigger
                  overlay={<Tooltip>Hide Transcript</Tooltip>}
                  placement="bottom"
                >
                  <div
                    className="collapse-btn"
                    onClick={() => setHideTranscript(true)}
                  >
                    <div className="collapse-icon"></div>
                  </div>
                </OverlayTrigger>
                <Transcript
                  {...sessionTranscript}
                  sessionId={sessionId}
                  sessionCategory={sessionCategory}
                  sessionData={session.data}
                  setSpeakerMappingOpen={setSpeakerMappingOpen}
                  selectedUserRole={props.selectedUserRole}
                  speakerProfileEnrollment={speakerProfileEnrollment}
                  speakerRecognition={speakerRecognition}
                  enableVoiceRecognitionFeature={enableVoiceRecognitionFeature}
                />
              </div>
            ) : (
              <div className="transcript-collapsed-container">
                <div
                  className="transcript-collapsed-action"
                  onClick={() => setHideTranscript(false)}
                >
                  <ReactSVG
                    src={NotesIcon}
                    className="transcript-collapsed-action-icon"
                  />
                  <div className="transcript-collapsed-action-label">Transcript</div>
                </div>
              </div>
            )}
          </div>
        )}
        {!!speakerMappingOpen && (
          <SpeakerMapping
            sessionId={sessionId}
            providerSessionsTranscriptData={sessionTranscript?.data || []}
            saveSpeakerMapping={props.saveSpeakerMapping}
            sessionData={session.data}
            groupId={patientId}
            sessionCategory={sessionCategory}
            setSpeakerMappingOpen={setSpeakerMappingOpen}
            speakerRecognition={speakerRecognition}
          />
        )}
      </div>
    </TranscriptLoader>
  );
}

export default SessionDetails;
