import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useBlocker } from "react-router-dom";
import { useDyteMeeting, useDyteSelector } from "@dytesdk/react-web-core";
import {
  provideDyteDesignSystem,
  DyteDialogManager,
  DyteNotifications,
} from "@dytesdk/react-ui-kit";
import JoinSession from "./components/JoinSession";
import SessionJoined from "./components/SessionJoined";
import SessionLeft from "./components/SessionLeft";
import SessionEnded from "./components/SessionEnded";
import NetworkDisconnection from "./components/NetworkDisconnection";
import { AlertModal, Loader } from "@/components";
import designTokens from "./designTokens.json";
import { Form } from "react-bootstrap";
import VirtualIcon from "@/assets/video-recorder.svg";
import PhoneSpeakerIcon from "@/assets/phone-speaker.svg";
import { sendDelayMessage } from "@/Routes/Appointments/appointmentsModule";
import "./Meeting.scss";
import MentalWellnessScore from "@/Routes/Appointments/components/Sessions/components/MentalWellnessScore";
import { sendMeetingJoinedEvent } from "@/Routes/Appointments/appointmentsModule";
import WaitingBar from "./components/WaitingBar";
import CryptoJS from "crypto-js";

// const groupId = import.meta.env.VITE_GROUP_ID;
const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";
const isMobile = window.innerWidth <= 480;

function Meeting(props) {
  const { meeting } = useDyteMeeting();
  const navigate = useNavigate();
  const { sessionCategory, patientId, sessionId } = useParams();
  const [showWarning, setShowWarning] = useState(false);
  // const [states, setStates] = useState({ activeSidebar: true, sidebar: "participants" });
  const [states, setStates] = useState({});
  const setState = s => setStates(states => ({ ...states, ...s }));
  const roomState = useDyteSelector(meeting => meeting.self.roomState);
  useEffect(() => {
    provideDyteDesignSystem(document.body, designTokens);
    // console.log("meeting => ", meeting);
  }, []);
  useEffect(() => {
    if (roomState === "left" || roomState === "ended") {
      createTask();
      props.hideSideBar(isMobile ? true : false);
      props.getTodaysSessionsByPractitionerRole({ isBackgroundProcess: true });
    } else {
      props.hideSideBar(true);
    }
    if (roomState === "ended") {
      // navigate(`/session-details/${sessionCategory}/virtual/${sessionId}/${patientId}`, {replace: true});
      let options = {
        patientId,
        groupId: patientId,
        isGroupSession: sessionCategory === "group",
        message: "",
      };
      sendDelayMessage(options);
    }
    if (roomState === "joined") {
      sendMeetingJoinedEvent({
        isGroupSession: sessionCategory === "group",
        patientId,
        groupId: patientId,
      });
    }
    console.log("roomState => ", roomState);
  }, [roomState]);
  const chunks = useRef([]);
  const recorder = useRef();
  const audioContext = useRef();
  const gumStream = useRef();
  useEffect(() => {
    if (meeting?.meta?.sessionId && roomState === "joined") {
      chunks.current = [];
      recorder.current = null;
      audioContext.current = null;
      gumStream.current = null;
      startRecording();
    }
    return () => {
      stopRecording();
    };
  }, [meeting?.meta?.sessionId, roomState]);
  function startRecording() {
    console.log("recording start");
    const constraints = { audio: true };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
        gumStream.current = stream;
        const input = audioContext.current.createMediaStreamSource(stream);

        recorder.current = new MediaRecorder(stream, { audioBitsPerSecond: 1536000 });
        recorder.current.ondataavailable = e => {
          console.log("chunk added", chunks.current.length + 1);
          chunks.current.push(e.data);
        };
        recorder.current.onstop = async () => {
          console.log("recording stopped");
          await sendAudioChunks(chunks.current);
          chunks.current = [];
        };
        recorder.current.start();
      })
      .catch(err => {
        console.error("Error: " + err);
        alert("Could not access your microphone. Please check your permissions.");
      });
  }
  const blobToData = blob => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = e => resolve(e.target.result);
      reader.readAsArrayBuffer(blob);
    });
  };
  const sendAudioChunks = async chunksList => {
    let providerSessionId = meeting.meta.sessionId;
    try {
      const uploadId = await fetch(
        `https://transcript.${
          APP_ENV === "DEV" ? "dev" : "prod"
        }.soulsidehealth.com/audio/start/${sessionId}/${providerSessionId}`,
        {
          method: "POST",
        }
      ).then(response => response.text());

      let eTags = [];

      for (let i = 0; i < chunksList.length; i++) {
        const fileData = await blobToData(chunksList[i]);
        const wordArray = CryptoJS.lib.WordArray.create(fileData);
        const md5Hash = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Base64);
        console.log(`Chunk ${i + 1} MD5 Hash: ${md5Hash}`);
        try {
          let presignedUrl = await fetch(
            `https://transcript.${
              APP_ENV === "DEV" ? "dev" : "prod"
            }.soulsidehealth.com/audio/presigned-url/${sessionId}/${providerSessionId}/${uploadId}/${
              i + 1
            }?md5Hash=${window.encodeURIComponent(md5Hash)}`,
            {
              method: "GET",
            }
          ).then(response => response.text());
          let formData = new FormData();
          formData.append("data", chunksList[i]);
          try {
            const response = await fetch(presignedUrl, {
              method: "PUT",
              body: chunksList[i],
              headers: {
                "Content-MD5": md5Hash,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            });

            if (response.ok) {
              const eTag = response.headers.get("ETag") && JSON.parse(response.headers.get("ETag"));
              eTags.push(eTag);
            }
            if (i === chunksList.length - 1) {
              // await endSession({ sessionId, uploadId, eTags });
            }
          } catch (error) {
            console.log("error", error);
          }
        } catch (error) {
          console.log("error", error);
        }
        await endSession({ sessionId, uploadId, eTags });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  async function endSession({ sessionId, uploadId, eTags }) {
    let providerSessionId = meeting.meta.sessionId;
    let url = `https://transcript.${
      APP_ENV === "DEV" ? "dev" : "prod"
    }.soulsidehealth.com/audio/complete/individual/${sessionId}/${providerSessionId}/${uploadId}`;
    if (sessionCategory === "group") {
      url = `https://transcript.${
        APP_ENV === "DEV" ? "dev" : "prod"
      }.soulsidehealth.com/audio/complete/group/${sessionId}/${providerSessionId}/${uploadId}`;
    }
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(eTags),
    })
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.error("Error:", error));
  }
  function stopRecording() {
    if (recorder.current && recorder.current.state === "recording") {
      recorder?.current?.stop?.();
      gumStream?.current?.getAudioTracks()[0].stop();
    }
  }
  const createTask = () => {
    props.addTask(props.sessionDetailsData);
  };
  const leaveSession = async () => {
    try {
      // if (roomState !== "joined") {
      //   await meeting?.join();
      // }
      await meeting?.participants?.kickAll();
      // await meeting?.leave("ended");
    } catch (error) {
      console.log(error);
    }
  };
  const navBlocker = useBlocker(
    ({ currentLocation, nextLocation, historyAction }) =>
      roomState === "joined" &&
      currentLocation.pathname !== nextLocation.pathname &&
      historyAction === "POP"
  );
  return (
    <div className="session-meeting-container">
      <WaitingBar />
      {!!props.sideBarHidden && (
        <div className="session-exit-btn-container">
          {/* <div
            className="session-exit-btn"
            onClick={() => setShowWarning(true)}
          >
            Exit
          </div> */}
          {(isMobile && roomState !== "joined") || roomState === "init" ? (
            <div
              className="session-exit-btn"
              onClick={() => setShowWarning(true)}
            >
              Exit
            </div>
          ) : (
            <div></div>
          )}
          <div></div>
          <MentalWellnessScore session={props.sessionDetailsData} />
        </div>
      )}
      <DyteDialogManager
        meeting={meeting}
        size={isMobile ? "sm" : "lg"}
        // states={states}
        // onDyteStateUpdate={e => {
        //   console.log(e);

        //   setState(e.detail);
        // }}
      />
      <DyteNotifications
        meeting={meeting}
        config={{
          config: {
            // which notifications to show
            notifications: ["chat", "participant_joined", "participant_left"],
            // which notifications should have sounds
            notification_sounds: ["chat", "participant_joined", "participant_left"],
            // maximum number of participant joined sound notifications
            participant_joined_sound_notification_limit: 10,
            // maximum number of chat message sound notifications
            participant_chat_message_sound_notification_limit: 10,
          },
        }}
      />
      {roomState === "init" && (
        <Loader loading={true}>
          <JoinSession
            patientId={patientId}
            sessionId={sessionId}
            groupId={patientId}
            sessionCategory={sessionCategory}
            sessionDetailsData={props.sessionDetailsData}
            sessionType={props.sessionType}
            meetingToken={props.meetingToken}
            initMeeting={props.initMeeting}
            preferredTimezone={props.preferredTimezone}
          />
        </Loader>
      )}
      {roomState === "joined" && (
        <SessionJoined
          setSelectedMember={props.setSelectedMember}
          showMemberNotes={props.showMemberNotes}
          setShowNotes={props.setShowNotes}
          showNotes={props.showNotes}
          sessionDetailsData={props.sessionDetailsData}
          preferredTimezone={props.preferredTimezone}
          mapProviderSessionId={props.mapProviderSessionId}
          meetingSession={props.meetingSession}
          changeMeetingSession={props.changeMeetingSession}
          sessionType={props.sessionType}
          states={states}
          setState={setState}
          memberNotes={props.memberNotes}
          patientTreatmentPlan={props.patientTreatmentPlan}
          patientNotes={props.patientNotes}
          getPatientNotes={props.getPatientNotes}
        />
      )}
      {roomState === "left" && (
        <SessionLeft
          meetingToken={props.meetingToken}
          initMeeting={props.initMeeting}
          sessionDetailsData={props.sessionDetailsData}
          sessionType={props.sessionType}
        />
      )}
      {roomState === "ended" && (
        <SessionEnded
          meetingToken={props.meetingToken}
          initMeeting={props.initMeeting}
          sessionDetailsData={props.sessionDetailsData}
          sessionType={props.sessionType}
        />
      )}
      {(roomState === "disconnected" || roomState === "failed") && (
        <NetworkDisconnection networkFailed={roomState === "failed"} />
      )}
      <AlertModal
        modalOpen={navBlocker.state === "blocked" || showWarning}
        onCancel={() => {
          if (showWarning) {
            setShowWarning(false);
          } else {
            navBlocker.reset();
          }
        }}
        onConfirm={async () => {
          await leaveSession();
          if (showWarning) {
            setShowWarning(false);
            // navigate(`/appointments`, { replace: true });
            window.location.href = window.location.origin + "/";
          } else {
            navBlocker.proceed();
          }
        }}
        title={"Leave the session?"}
        desc={"Session will get ended if you exit"}
        confirmBtnText={"End Session"}
        cancelBtnText={"Cancel"}
      />
    </div>
  );
}

export default Meeting;
