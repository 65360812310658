import React, { useEffect, useState } from "react";
import { Avatar, Loader, PrimaryButton, Search, SecondaryButton } from "@/components";
import AddPatientModal from "./components/AddPatientModal";
import TablePagination from "./components/TablePagination";

import { flexRender } from "@tanstack/react-table";
import "./Patients.scss";

import usePatients from "./usePatients";

function Patients(props) {
  const {
    searchKey,
    setSearchKey,
    addPatientModaOpen,
    setAddPatientModalOpen,
    pagination,
    setPagination,
    patientsData,
    table,
  } = usePatients(props);
  return (
    <div className="patients-wrapper">
      <Loader loading={props.patients.loading}>
        <div className="patients-header">
          <Search
            placeholder={"Search for a patient"}
            value={searchKey}
            onSearch={val => setSearchKey(val)}
            clearSearch={() => setSearchKey("")}
            noDebounce={true}
          />
          <div className="patients-header-actions">
            <PrimaryButton
              className="add-patient-btn"
              onClick={() => setAddPatientModalOpen(true)}
            >
              Add Patient
            </PrimaryButton>
          </div>
        </div>
        <div className="patients-table-container">
          <table className="patients-table">
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{ position: "relative", width: header.getSize() }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanResize() && (
                          <div
                            onMouseDown={header.getResizeHandler()}
                            onTouchStart={header.getResizeHandler()}
                            className={`resizer ${
                              header.column.getIsResizing() ? "isResizing" : ""
                            }`}
                          ></div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td
                          key={cell.id}
                          style={{ width: cell.column.getSize() }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <TablePagination table={table} />
        <AddPatientModal
          modalOpen={addPatientModaOpen}
          toggleModal={setAddPatientModalOpen}
          addPatient={props.addPatient}
        />
      </Loader>
    </div>
  );
}

export default Patients;
