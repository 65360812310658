import React, { useState, useEffect } from "react";
import { Modal, OverlayTrigger, Tooltip, ProgressBar } from "react-bootstrap";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import DeleteIcon from "@/assets/delete-icon.svg";
import "./TreatmentPlanModal.scss";

const getNewProblem = index => {
  return {
    description: "",
    goals: [getNewGoal(index, 0)],
    interventions: [getNewIntervention(index, 0)],
  };
};

const getNewGoal = (index, goalIndex) => {
  return {
    description: "",
    objectives: [getNewObjective(index, goalIndex, 0)],
  };
};

const getNewObjective = (index, goalIndex, objectiveIndex) => {
  return {
    description: "",
    timeline: "",
  };
};

const getNewIntervention = (index, interventionIndex) => {
  return "";
};

const TreatmentPlanModal = props => {
  const [treatmentPlanData, setTreatmentPlanData] = useState(null);
  useEffect(() => {
    if (props.modalOpen) {
      if (props.treatmentPlan?.data?.plan?.treatmentPlanData) {
        setTreatmentPlanData(props.treatmentPlan.data.plan.treatmentPlanData);
      } else {
        setTreatmentPlanData({
          problems: [getNewProblem(0)],
        });
      }
    }
  }, [props.treatmentPlan?.data?.plan?.treatmentPlanData, props.modalOpen]);
  const onInputChange = ({
    key,
    value,
    type,
    problemIndex,
    goalIndex,
    objectiveIndex,
    interventionIndex,
  }) => {
    setTreatmentPlanData(prevData => {
      let data = prevData ? { ...prevData } : {};
      if (type === "problem") {
        data = {
          ...data,
          problems:
            data.problems?.length > 0
              ? data.problems.map((problem, tempProblemIndex) => {
                  if (tempProblemIndex === problemIndex) {
                    return {
                      ...problem,
                      [key]: value,
                    };
                  }
                  return problem;
                })
              : [{ [key]: value }],
        };
      } else if (type === "goal") {
        data = {
          ...data,
          problems: data.problems.map((problem, tempProblemIndex) => {
            if (tempProblemIndex === problemIndex) {
              return {
                ...problem,
                goals:
                  problem.goals?.length > 0
                    ? problem.goals.map((goal, tempGoalIndex) => {
                        if (tempGoalIndex === goalIndex) {
                          return {
                            ...goal,
                            [key]: value,
                          };
                        }
                        return goal;
                      })
                    : [{ [key]: value }],
              };
            }
            return problem;
          }),
        };
      } else if (type === "objective") {
        data = {
          ...data,
          problems: data.problems.map((problem, tempProblemIndex) => {
            if (tempProblemIndex === problemIndex) {
              return {
                ...problem,
                goals: problem.goals.map((goal, tempGoalIndex) => {
                  if (tempGoalIndex === goalIndex) {
                    return {
                      ...goal,
                      objectives:
                        goal?.objectives?.length > 0
                          ? goal.objectives.map((objective, tempObjectiveIndex) => {
                              if (tempObjectiveIndex === objectiveIndex) {
                                return {
                                  ...objective,
                                  [key]: value,
                                };
                              }
                              return objective;
                            })
                          : [{ [key]: value }],
                    };
                  }
                  return goal;
                }),
              };
            }
            return problem;
          }),
        };
      } else if (type === "intervention") {
        data = {
          ...data,
          problems: data.problems.map((problem, tempProblemIndex) => {
            if (tempProblemIndex === problemIndex) {
              return {
                ...problem,
                interventions:
                  problem.interventions?.length > 0
                    ? problem.interventions.map((intervention, tempInterventionIndex) => {
                        if (tempInterventionIndex === interventionIndex) {
                          return value;
                        }
                        return intervention;
                      })
                    : [value],
              };
            }
            return problem;
          }),
        };
      }
      return data;
    });
  };
  const addNewProblem = () => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems:
          prevData.problems?.length > 0
            ? [...prevData.problems, getNewProblem(prevData.problems.length)]
            : [getNewProblem(0)],
      };
    });
  };
  const deleteProblem = problemIndex => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems:
          prevData.problems?.filter(
            (problem, tempProblemIndex) => tempProblemIndex !== problemIndex
          ) || [],
      };
    });
  };
  const addNewGoal = problemIndex => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems: prevData.problems.map((problem, tempProblemIndex) => {
          if (tempProblemIndex === problemIndex) {
            return {
              ...problem,
              goals:
                problem.goals?.length > 0
                  ? [...problem.goals, getNewGoal(problemIndex, problem.goals.length)]
                  : [getNewGoal(problemIndex, 0)],
            };
          }
          return problem;
        }),
      };
    });
  };
  const deleteGoal = (problemIndex, goalIndex) => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems: prevData.problems.map((problem, tempProblemIndex) => {
          if (tempProblemIndex === problemIndex) {
            return {
              ...problem,
              goals:
                problem.goals?.filter((goal, tempGoalIndex) => tempGoalIndex !== goalIndex) || [],
            };
          }
          return problem;
        }),
      };
    });
  };
  const addNewObjective = (problemIndex, goalIndex) => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems: prevData.problems.map((problem, tempProblemIndex) => {
          if (tempProblemIndex === problemIndex) {
            return {
              ...problem,
              goals: problem.goals.map((goal, tempGoalIndex) => {
                if (tempGoalIndex === goalIndex) {
                  return {
                    ...goal,
                    objectives:
                      goal.objectives?.length > 0
                        ? [
                            ...goal.objectives,
                            getNewObjective(problemIndex, goalIndex, goal.objectives.length),
                          ]
                        : [getNewObjective(problemIndex, goalIndex, 0)],
                  };
                }
                return goal;
              }),
            };
          }
          return problem;
        }),
      };
    });
  };
  const deleteObjective = (problemIndex, goalIndex, objectiveIndex) => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems: prevData.problems.map((problem, tempProblemIndex) => {
          if (tempProblemIndex === problemIndex) {
            return {
              ...problem,
              goals: problem.goals.map((goal, tempGoalIndex) => {
                if (tempGoalIndex === goalIndex) {
                  return {
                    ...goal,
                    objectives:
                      goal.objectives?.filter(
                        (objective, tempObjectiveIndex) => tempObjectiveIndex !== objectiveIndex
                      ) || [],
                  };
                }
                return goal;
              }),
            };
          }
          return problem;
        }),
      };
    });
  };
  const addNewIntervention = problemIndex => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems: prevData.problems.map((problem, tempProblemIndex) => {
          if (tempProblemIndex === problemIndex) {
            return {
              ...problem,
              interventions:
                problem.interventions?.length > 0
                  ? [
                      ...problem.interventions,
                      getNewIntervention(problemIndex, problem.interventions.length),
                    ]
                  : [getNewIntervention(problemIndex, 0)],
            };
          }
          return problem;
        }),
      };
    });
  };
  const deleteIntervention = (problemIndex, interventionIndex) => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems: prevData.problems.map((problem, tempProblemIndex) => {
          if (tempProblemIndex === problemIndex) {
            return {
              ...problem,
              interventions:
                problem.interventions?.filter(
                  (intervention, tempInterventionIndex) =>
                    tempInterventionIndex !== interventionIndex
                ) || [],
            };
          }
          return problem;
        }),
      };
    });
  };
  const saveTreatmentPlan = async () => {
    let payload = props.treatmentPlan?.data
      ? {
          ...props.treatmentPlan.data,
          revisedByPractitionerRoleId: props.selectedUserRole?.id,
          patientId: props.patientId,
          plan: {
            treatmentPlanData,
            intakeSession: props.treatmentPlan?.data?.plan?.intakeSession || null,
          },
          updatedAt: new Date().toISOString(),
        }
      : {
          revisedByPractitionerRoleId: props.selectedUserRole?.id,
          patientId: props.patientId,
          plan: {
            treatmentPlanData,
            intakeSession: props.treatmentPlan?.data?.plan?.intakeSession || null,
          },
        };
    console.log("payload", payload);
    await props.generateTreatmentPlan({
      patientId: props.patientId,
      payload,
      isEdit: !!props.treatmentPlan?.data,
    });
    props.toggleModal(false);
  };
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="treatment-plan-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Treatment Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProgressLoader loading={props.treatmentPlan?.loading}>
          <div className="treatment-plan-modal-body">
            {treatmentPlanData?.["problems"]?.length > 0 && (
              <div className="treatment-plan-problems-container">
                <div className="treatment-plan-problems-label">Problems</div>
                <ol className="treatment-plan-problems-list">
                  {treatmentPlanData?.["problems"].map((problem, problemIndex) => {
                    return (
                      <li
                        className="treatment-plan-problem-item"
                        key={problem.problem_id}
                      >
                        <div className="treatment-plan-input-container with-textarea">
                          <div className="treatment-plan-input-label">
                            Problem:
                            {treatmentPlanData?.["problems"]?.length > 1 && (
                              <OverlayTrigger
                                overlay={<Tooltip>Delete Problem</Tooltip>}
                                placement="top"
                              >
                                <div
                                  className="delete-icon"
                                  onClick={() => deleteProblem(problemIndex)}
                                >
                                  <img
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </OverlayTrigger>
                            )}
                          </div>
                          <textarea
                            className="treatment-plan-input"
                            type="text"
                            value={problem.description}
                            placeholder="Problem Description"
                            onChange={e =>
                              onInputChange({
                                key: "description",
                                value: e.target.value,
                                type: "problem",
                                problemIndex,
                              })
                            }
                          />
                        </div>
                        {problem?.goals?.length > 0 && (
                          <div className="treatment-plan-goals-container">
                            <div className="treatment-plan-goals-label">Goals:</div>
                            <ol className="treatment-plan-goals-list">
                              {problem.goals.map((goal, goalIndex) => {
                                return (
                                  <li
                                    className="treatment-plan-goal-item"
                                    key={goal.goal_id}
                                  >
                                    <div className="treatment-plan-input-container with-textarea">
                                      <div className="treatment-plan-input-label">
                                        <textarea
                                          className="treatment-plan-input"
                                          type="text"
                                          value={goal.description}
                                          placeholder="Goal Description"
                                          onChange={e =>
                                            onInputChange({
                                              key: "description",
                                              value: e.target.value,
                                              type: "goal",
                                              problemIndex,
                                              goalIndex,
                                            })
                                          }
                                        />
                                        {problem?.goals?.length > 1 && (
                                          <OverlayTrigger
                                            overlay={<Tooltip>Delete Goal</Tooltip>}
                                            placement="top"
                                          >
                                            <div
                                              className="delete-icon"
                                              onClick={() => deleteGoal(problemIndex, goalIndex)}
                                            >
                                              <img
                                                src={DeleteIcon}
                                                alt=""
                                              />
                                            </div>
                                          </OverlayTrigger>
                                        )}
                                      </div>
                                    </div>
                                    {goal?.objectives?.length > 0 && (
                                      <div className="treatment-plan-objectives-container">
                                        <div className="treatment-plan-objectives-label">
                                          Objectives:
                                        </div>
                                        <ol className="treatment-plan-objectives-list">
                                          {goal.objectives.map((objective, objectiveIndex) => {
                                            return (
                                              <li
                                                className="treatment-plan-objective-item"
                                                key={objective.objective_id}
                                              >
                                                <div className="treatment-plan-input-container with-textarea">
                                                  <div className="treatment-plan-input-label">
                                                    <textarea
                                                      className="treatment-plan-input"
                                                      type="text"
                                                      value={objective.description}
                                                      placeholder="Objective Description"
                                                      onChange={e =>
                                                        onInputChange({
                                                          key: "description",
                                                          value: e.target.value,
                                                          type: "objective",
                                                          problemIndex,
                                                          goalIndex,
                                                          objectiveIndex,
                                                        })
                                                      }
                                                    />
                                                    {goal?.objectives?.length > 1 && (
                                                      <OverlayTrigger
                                                        overlay={
                                                          <Tooltip>Delete Objective</Tooltip>
                                                        }
                                                        placement="top"
                                                      >
                                                        <div
                                                          className="delete-icon"
                                                          onClick={() =>
                                                            deleteObjective(
                                                              problemIndex,
                                                              goalIndex,
                                                              objectiveIndex
                                                            )
                                                          }
                                                        >
                                                          <img
                                                            src={DeleteIcon}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </OverlayTrigger>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="treatment-plan-input-container">
                                                  <div className="treatment-plan-input-label">
                                                    Timeline:
                                                  </div>
                                                  <input
                                                    className="treatment-plan-input"
                                                    value={objective.timeline}
                                                    placeholder={""}
                                                    type={"text"}
                                                    onChange={e =>
                                                      onInputChange({
                                                        key: "timeline",
                                                        value: e.target.value,
                                                        type: "objective",
                                                        problemIndex,
                                                        goalIndex,
                                                        objectiveIndex,
                                                      })
                                                    }
                                                  />
                                                </div>
                                              </li>
                                            );
                                          })}
                                        </ol>
                                        <div
                                          className="add-item-button"
                                          onClick={() => addNewObjective(problemIndex, goalIndex)}
                                        >
                                          Add New Objective
                                        </div>
                                      </div>
                                    )}
                                  </li>
                                );
                              })}
                            </ol>
                            <div
                              className="add-item-button"
                              onClick={() => addNewGoal(problemIndex)}
                            >
                              Add New Goal
                            </div>
                          </div>
                        )}
                        {problem?.interventions?.length > 0 && (
                          <div
                            className="treatment-plan-interventions-container"
                            style={{ marginTop: "10px" }}
                          >
                            <div className="treatment-plan-interventions-label">Interventions:</div>
                            <ol className="treatment-plan-interventions-list">
                              {problem.interventions.map((intervention, interventionIndex) => {
                                return (
                                  <li
                                    className="treatment-plan-intervention-item"
                                    key={interventionIndex}
                                  >
                                    <div className="treatment-plan-input-container with-textarea">
                                      <div className="treatment-plan-input-label">
                                        <textarea
                                          className="treatment-plan-input"
                                          type="text"
                                          value={intervention}
                                          placeholder="Intervention Description"
                                          onChange={e =>
                                            onInputChange({
                                              key: "description",
                                              value: e.target.value,
                                              type: "intervention",
                                              problemIndex,
                                              interventionIndex,
                                            })
                                          }
                                        />
                                        {problem?.interventions?.length > 1 && (
                                          <OverlayTrigger
                                            overlay={<Tooltip>Delete Intervention</Tooltip>}
                                            placement="top"
                                          >
                                            <div
                                              className="delete-icon"
                                              onClick={() =>
                                                deleteGoal(problemIndex, interventionIndex)
                                              }
                                            >
                                              <img
                                                src={DeleteIcon}
                                                alt=""
                                              />
                                            </div>
                                          </OverlayTrigger>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ol>
                            <div
                              className="add-item-button"
                              onClick={() => addNewIntervention(problemIndex)}
                            >
                              Add New Intervention
                            </div>
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ol>
                <div
                  className="add-item-button"
                  onClick={() => addNewProblem()}
                >
                  Add New Problem
                </div>
              </div>
            )}
          </div>
        </ProgressLoader>
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton onClick={() => props.toggleModal(false)}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => saveTreatmentPlan()}>Save Changes</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

const ProgressLoader = props => {
  if (props.loading) {
    return (
      <div className="progress-loader">
        <ProgressBar
          animated
          now={100}
        />
        <div className="loading-msg">Generating Treatment Plan</div>
      </div>
    );
  } else {
    return props.children || <></>;
  }
};

export default TreatmentPlanModal;
