import { getPreferredTimezoneFromLocal } from "@/store/modules/userModule";

const APP_DOMAIN = import.meta.env.VITE_APP_DOMAIN;
const SOULSIDE_DOMAIN = import.meta.env.VITE_SOULSIDE_DOMAIN;
const APP_ENV = import.meta.env.VITE_APP_ENV;

const APP_IDENTITFER = import.meta.env.VITE_APP_IDENTIFIER;

export const getCookie = (name, crossDomain = false) => {
  if (!crossDomain) {
    name += `-${APP_IDENTITFER}`;
  }
  if (APP_ENV === "DEV") {
    name = name + `-dev`;
  }
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
};

export const saveCookie = (name, value, crossDomain = false) => {
  if (!crossDomain) {
    name += `-${APP_IDENTITFER}`;
  }
  if (APP_ENV === "DEV") {
    name = name + `-dev`;
  }
  var now = new Date();
  now.setDate(now.getDate() + 30);
  let domain = crossDomain ? SOULSIDE_DOMAIN : APP_DOMAIN;
  if (!domain) {
    domain = window.location.hostname;
  }
  document.cookie =
    name + "=" + value + ";expires=" + now.toGMTString() + ";domain=" + domain + ";path=/;secure";
};

export const deleteCookie = (name, crossDomain = false) => {
  if (!crossDomain) {
    name += `-${APP_IDENTITFER}`;
  }
  if (APP_ENV === "DEV") {
    name = name + `-dev`;
  }
  let domain = crossDomain ? SOULSIDE_DOMAIN : APP_DOMAIN;
  if (!domain) {
    domain = window.location.hostname;
  }
  document.cookie =
    name + `=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=${domain};secure;`;
  document.cookie = name + `=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;secure;`;
};

export const addLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getLocalStorage = key => {
  let elem = localStorage.getItem(key);
  if (elem) {
    return JSON.parse(localStorage.getItem(key));
  }

  return null;
};

export const deleteLocalStorage = key => {
  localStorage.removeItem(key);
};

export const checkTodaySession = session => {
  if (!session) {
    return false;
  }
  let isTodaysSession = false;
  const preferredTimezone = getPreferredTimezoneFromLocal();
  const today = new Date(
    new Date().toLocaleString("en", {
      timeZone: preferredTimezone?.name || "America/Los_Angeles",
    })
  );
  today.setHours(0, 0, 0, 0);
  const dateToCheck = new Date(
    new Date(session.startTime).toLocaleString("en", {
      timeZone: preferredTimezone?.name || "America/Los_Angeles",
    })
  );
  dateToCheck.setHours(0, 0, 0, 0);
  isTodaysSession = dateToCheck.getTime() === today.getTime();
  return isTodaysSession;
};
