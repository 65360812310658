import Session from "./Session";
import { connect } from "react-redux";
import {
  getSessionDetails,
  getMeetingToken,
  getMemberNotes,
  mapProviderSessionId,
  changeMeetingSession,
  getPatientNotes,
} from "./sessionModule";
import {
  getLatestPatientSessionMemberNotes,
  resetPatientDetails,
} from "../PatientDetails/patientDetailsModule";
import { getPatients } from "../Patients/patientsModule";
import { addTask, hideSideBar } from "@/store/modules/userModule";
import { getTodaysSessionsByPractitionerRole } from "@/Routes/Appointments/appointmentsModule";

const mapStateToProps = state => {
  return {
    sessionDetails: state.session.sessionDetails,
    meetingToken: state.session.meetingToken,
    memberNotes: state.session.memberNotes,
    patientNotes: state.session.patientNotes,
    userData: state.user.userData,
    selectedUserRole: state.user.selectedUserRole,
    soapNotesLoading: state.patientDetails.soapNotesLoading,
    soapNotes: state.patientDetails.soapNotes,
    soapNotesJson: state.patientDetails.soapNotesJson,
    soapNotesSession: state.patientDetails.soapNotesSession,
    patients: state.patients.patients,
    preferredTimezone: state.user.preferredTimezone,
    meetingSession: state.session.meetingSession,
    sideBarHidden: state.app.hideSideBar,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSessionDetails: options => dispatch(getSessionDetails(options)),
    getMeetingToken: options => dispatch(getMeetingToken(options)),
    getMemberNotes: options => dispatch(getMemberNotes(options)),
    getLatestPatientSessionMemberNotes: options =>
      dispatch(getLatestPatientSessionMemberNotes(options)),
    resetPatientDetails: () => dispatch(resetPatientDetails()),
    getPatients: options => dispatch(getPatients(options)),
    mapProviderSessionId: options => dispatch(mapProviderSessionId(options)),
    changeMeetingSession: options => dispatch(changeMeetingSession(options)),
    addTask: task => dispatch(addTask(task)),
    hideSideBar: flag => dispatch(hideSideBar(flag)),
    getTodaysSessionsByPractitionerRole: options =>
      dispatch(getTodaysSessionsByPractitionerRole(options)),
    getPatientNotes: options => dispatch(getPatientNotes(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Session);
