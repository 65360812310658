import PatientDetails from "./PatientDetails";
import { connect } from "react-redux";
import {
  getLatestSoapNotes,
  getPatientPrivateNotes,
  resetPatientDetails,
  getDischargeSummary,
  generateDischargeSummary,
  saveDischargeSummary,
  getLatestPatientSessionMemberNotes,
  getSessionsByPatientId,
} from "./patientDetailsModule";
import {
  getPatients,
  addPatient,
  getDummyPhoneNumber,
  getDummyEmail,
} from "@/Routes/Patients/patientsModule";
import { getSessionsByPractitionerRole } from "../Appointments/appointmentsModule";
import { getPatientNotes } from "../Session/sessionModule";

const mapStateToProps = state => {
  return {
    soapNotesLoading: state.patientDetails.soapNotesLoading,
    soapNotes: state.patientDetails.soapNotes,
    soapNotesSession: state.patientDetails.soapNotesSession,
    soapNotesJson: state.patientDetails.soapNotesJson,
    behaviouralHealthPredictions: state.patientDetails.behaviouralHealthPredictions,
    privateNotesLoading: state.patientDetails.privateNotesLoading,
    privateNotes: state.patientDetails.privateNotes,
    dischargeSummary: state.patientDetails.dischargeSummary,
    dischargeSummarySession: state.patientDetails.dischargeSummarySession,
    patients: state.patients.patients,
    sessions: state.patientDetails.patientSessions,
    preferredTimezone: state.user.preferredTimezone,
    selectedUserRole: state.user.selectedUserRole,
    patientNotes: state.session.patientNotes,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLatestSoapNotes: options => dispatch(getLatestSoapNotes(options)),
    getPatientPrivateNotes: options => dispatch(getPatientPrivateNotes(options)),
    resetPatientDetails: () => dispatch(resetPatientDetails()),
    getPatients: options => dispatch(getPatients(options)),
    getSessionsByPractitionerRole: options => dispatch(getSessionsByPractitionerRole(options)),
    generateDischargeSummary: options => dispatch(generateDischargeSummary(options)),
    saveDischargeSummary: options => dispatch(saveDischargeSummary(options)),
    getDischargeSummary: options => dispatch(getDischargeSummary(options)),
    getDummyPhoneNumber: () => dispatch(getDummyPhoneNumber()),
    getDummyEmail: name => dispatch(getDummyEmail(name)),
    getLatestPatientSessionMemberNotes: patientId =>
      dispatch(getLatestPatientSessionMemberNotes(patientId)),
    getSessionsByPatientId: patientId => dispatch(getSessionsByPatientId(patientId)),
    getPatientNotes: options => dispatch(getPatientNotes(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
