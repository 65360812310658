import React, { useState, useMemo, useEffect } from "react";
import { MemberAvatar, PrimaryButton, SecondaryButton } from "@/components";
import { Form, Dropdown } from "react-bootstrap";
import GroupsPlaceholderIcon from "@/assets/group-avatar-placeholder.svg";
import "./SpeakerMapping.scss";

function SpeakerMapping(props) {
  const [speakerMappedData, setSpeakerMappedData] = useState({});
  const speakerRecognitionData = props.speakerRecognition?.data?.[props.sessionId];
  const speakerMappingTranscriptData = useMemo(() => {
    let data =
      props.providerSessionsTranscriptData?.map(providerSessionTranscript => {
        let speakerTranscriptData = {};
        providerSessionTranscript.transcriptData?.forEach(transcript => {
          const providerParticipantId = transcript.providerParticipantId;
          if (speakerTranscriptData[providerParticipantId]) {
            speakerTranscriptData[providerParticipantId].push(transcript);
          } else {
            speakerTranscriptData[providerParticipantId] = [transcript];
          }
        });
        return {
          providerSessionId: providerSessionTranscript.providerSessionId,
          speakerTranscriptData,
        };
      }) || [];
    return data;
  }, [props.providerSessionsTranscriptData]);
  useEffect(() => {
    let data = {};
    if (props.sessionCategory === "individual") {
      if (props.providerSessionsTranscriptData?.length > 0) {
        props.providerSessionsTranscriptData.forEach(providerSessionTranscript => {
          if (providerSessionTranscript?.transcriptData?.length > 0) {
            let providerSessionId = providerSessionTranscript.providerSessionId;
            providerSessionTranscript.transcriptData.forEach(transcript => {
              data = {
                ...data,
                [providerSessionId]: data[providerSessionId]
                  ? {
                      ...data[providerSessionId],
                      [transcript.providerParticipantId]: transcript.memberName,
                    }
                  : { [transcript.providerParticipantId]: transcript.memberName },
              };
            });
          }
        });
      }
    } else {
      if (props.providerSessionsTranscriptData?.length > 0) {
        props.providerSessionsTranscriptData.forEach(providerSessionTranscript => {
          if (providerSessionTranscript?.transcriptData?.length > 0) {
            let providerSessionId = providerSessionTranscript.providerSessionId;
            providerSessionTranscript.transcriptData.forEach(transcript => {
              let memberId = transcript.memberId;
              let memberName = transcript.memberName;
              if (speakerRecognitionData?.[providerSessionId]?.[transcript.providerParticipantId]) {
                const { predictedLabel = "", speakerUUID = "" } =
                  speakerRecognitionData?.[providerSessionId]?.[memberId];
                if (predictedLabel && speakerUUID) {
                  memberId = speakerUUID;
                  let sessionPatientMemberDtos = props.sessionData?.sessionPatientMemberDtos || [];
                  let patientObj = sessionPatientMemberDtos.find(i => i.patientId === speakerUUID);
                  if (patientObj) {
                    memberName = `${patientObj.patientFirstName || ""} ${
                      patientObj.patientLastName || ""
                    }`;
                  }
                }
              }
              data = {
                ...data,
                [providerSessionId]: data[providerSessionId]
                  ? {
                      ...data[providerSessionId],
                      [transcript.providerParticipantId]: {
                        id: memberId,
                        name: memberName,
                      },
                    }
                  : {
                      [transcript.providerParticipantId]: {
                        id: memberId,
                        name: memberName,
                      },
                    },
              };
            });
          }
        });
      }
    }
    setSpeakerMappedData(data);
  }, [props.providerSessionsTranscriptData, speakerRecognitionData]);
  const mapIndividualSessionSpeaker = (
    providerSessionId,
    providerParticipantId,
    participantName
  ) => {
    setSpeakerMappedData(prevData => {
      let newData = { ...prevData };
      newData = {
        ...newData,
        [providerSessionId]: newData[providerSessionId]
          ? {
              ...newData[providerSessionId],
              [providerParticipantId]: participantName,
            }
          : { [providerParticipantId]: participantName },
      };
      return newData;
    });
  };
  const mapGroupSessionSpeaker = (providerSessionId, providerParticipantId, participant) => {
    setSpeakerMappedData(prevData => {
      let newData = { ...prevData };
      newData = {
        ...newData,
        [providerSessionId]: newData[providerSessionId]
          ? {
              ...newData[providerSessionId],
              [providerParticipantId]: participant,
            }
          : { [providerParticipantId]: participant },
      };
      return newData;
    });
  };
  const saveMapping = () => {
    let providerSessionsTranscriptData = props.providerSessionsTranscriptData.map(
      providerSessionTranscript => {
        let providerSessionId = providerSessionTranscript.providerSessionId;
        let providerSessionTranscriptData = providerSessionTranscript.transcriptData.map(
          transcript => {
            let providerParticipantId = transcript.providerParticipantId;
            let mappedData =
              speakerMappedData?.[providerSessionId]?.[providerParticipantId] || null;
            if (mappedData) {
              const memberName =
                props.sessionCategory === "individual"
                  ? mappedData
                  : mappedData?.name || transcript.memberName;
              const memberId =
                props.sessionCategory === "individual"
                  ? mappedData
                  : mappedData?.id || transcript.memberId;
              return {
                ...transcript,
                memberName,
                memberId,
              };
            }
            return transcript;
          }
        );
        return {
          ...providerSessionTranscript,
          transcriptData: providerSessionTranscriptData,
        };
      }
    );
    props.saveSpeakerMapping({
      providerSessionsTranscriptData,
      sessionId: props.sessionId,
      sessionCategory: props.sessionCategory,
    });
    props.setSpeakerMappingOpen(false);
  };
  return (
    <div className="speaker-mapping-container">
      <div className="speaker-mapping-patient-info-container">
        <MemberAvatar
          name={props.sessionData?.groupName}
          size={"md"}
          showFullName={true}
          placeholderImg={GroupsPlaceholderIcon}
        />
        <div className="action-btns">
          <SecondaryButton onClick={() => props.setSpeakerMappingOpen(false)}>
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={saveMapping}>Save Mapping</PrimaryButton>
        </div>
      </div>
      <div className="speaker-mapping-desc">
        Map the speakers from the session to the patients to generate the notes
      </div>
      <div className="speaker-mapping-list">
        {speakerMappingTranscriptData?.map(providerSessionTranscript => {
          const providerSessionId = providerSessionTranscript.providerSessionId;
          const speakerTranscriptData = providerSessionTranscript.speakerTranscriptData;
          return (
            <div
              className="speaker-mapping-provider-session-list"
              key={providerSessionId}
            >
              {Object.keys(speakerTranscriptData)?.map(providerParticipantId => {
                let speakerMapped =
                  speakerMappedData?.[providerSessionId]?.[providerParticipantId] || null;
                let speakerMappedName =
                  props.sessionCategory === "individual"
                    ? speakerMapped || ""
                    : speakerMapped?.name || "";
                let dropdownTitle = "Select Patient";
                let groupSessionPatientSelected = false;
                let practitionerFullName = `${props.sessionData?.practitionerFirstName || ""}${
                  props.sessionData?.practitionerLastName ? " " : ""
                }${props.sessionData?.practitionerLastName || ""}`;
                if (props.sessionCategory === "group") {
                  if (!["Provider", "Nurse Practitioner", "Unknown"].includes(speakerMappedName)) {
                    groupSessionPatientSelected = true;
                  }
                  dropdownTitle =
                    !groupSessionPatientSelected || !speakerMappedName
                      ? "Select Patient"
                      : speakerMappedName;
                }
                return (
                  <div
                    className="speaker-mapping-item"
                    key={providerParticipantId}
                  >
                    <div className="speaker-mapping-item-patient-container">
                      {props.sessionCategory === "group" && (
                        <div>
                          <div className="speaker-patient-mapping-container">
                            <Form.Label className="speaker-patient-label">
                              Select Patient:
                            </Form.Label>
                            <Dropdown className="speaker-patient-dropdown">
                              <Dropdown.Toggle disabled={!groupSessionPatientSelected}>
                                {dropdownTitle}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {props.sessionData?.sessionPatientMemberDtos?.length > 0 &&
                                  props.sessionData.sessionPatientMemberDtos.map(patient => {
                                    let patientName = `${patient?.patientFirstName || ""}${
                                      patient?.patientLastName ? " " : ""
                                    }${patient?.patientLastName || ""}`;
                                    return (
                                      <Dropdown.Item
                                        as={"button"}
                                        onClick={() =>
                                          mapGroupSessionSpeaker(
                                            providerSessionId,
                                            providerParticipantId,
                                            {
                                              id: patient.patientId,
                                              name: patientName,
                                            }
                                          )
                                        }
                                        key={patient.patientId}
                                      >
                                        <MemberAvatar
                                          name={patientName}
                                          size={"small"}
                                          showFullName={true}
                                        />
                                      </Dropdown.Item>
                                    );
                                  })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="speaker-checkbox-list">
                            <div
                              className="checkbox-container"
                              onClick={() => {
                                if (groupSessionPatientSelected) {
                                  console.log("Patient Selected");
                                } else {
                                  mapGroupSessionSpeaker(
                                    providerSessionId,
                                    providerParticipantId,
                                    null
                                  );
                                }
                              }}
                            >
                              <div
                                className={`custom-checkbox ${
                                  groupSessionPatientSelected ? "checked" : ""
                                }`}
                              >
                                <span className="checkbox-icon"></span>
                              </div>
                              <div className="checkbox-label">Patient</div>
                            </div>
                            <div
                              className="checkbox-container"
                              onClick={() =>
                                mapGroupSessionSpeaker(providerSessionId, providerParticipantId, {
                                  name: "Provider",
                                  id: "Provider",
                                })
                              }
                            >
                              <div
                                className={`custom-checkbox ${
                                  speakerMapped?.id === "Provider" ||
                                  speakerMapped?.name === "Provider" ||
                                  practitionerFullName
                                    ?.trim?.()
                                    ?.toLowerCase?.()
                                    ?.includes(speakerMapped?.name?.trim?.().toLowerCase?.())
                                    ? "checked"
                                    : ""
                                }`}
                              >
                                <span className="checkbox-icon"></span>
                              </div>
                              <div className="checkbox-label">Provider</div>
                            </div>
                            <div
                              className="checkbox-container"
                              onClick={() =>
                                mapGroupSessionSpeaker(providerSessionId, providerParticipantId, {
                                  name: "Nurse Practitioner",
                                  id: "Nurse Practitioner",
                                })
                              }
                            >
                              <div
                                className={`custom-checkbox ${
                                  speakerMappedData?.[providerSessionId]?.[providerParticipantId]
                                    ?.id === "Nurse Practitioner"
                                    ? "checked"
                                    : ""
                                }`}
                              >
                                <span className="checkbox-icon"></span>
                              </div>
                              <div className="checkbox-label">Nurse Practitioner</div>
                            </div>
                            <div
                              className="checkbox-container"
                              onClick={() =>
                                mapGroupSessionSpeaker(providerSessionId, providerParticipantId, {
                                  name: "Unknown",
                                  id: "Unknown",
                                })
                              }
                            >
                              <div
                                className={`custom-checkbox ${
                                  speakerMappedData?.[providerSessionId]?.[providerParticipantId]
                                    ?.id === "Unknown"
                                    ? "checked"
                                    : ""
                                }`}
                              >
                                <span className="checkbox-icon"></span>
                              </div>
                              <div className="checkbox-label">Unknown</div>
                            </div>
                          </div>
                        </div>
                      )}
                      {props.sessionCategory === "individual" && (
                        <div className="speaker-checkbox-list">
                          <div
                            className="checkbox-container"
                            onClick={() =>
                              mapIndividualSessionSpeaker(
                                providerSessionId,
                                providerParticipantId,
                                "Patient"
                              )
                            }
                          >
                            <div
                              className={`custom-checkbox ${
                                speakerMappedData?.[providerSessionId]?.[providerParticipantId] ===
                                "Patient"
                                  ? "checked"
                                  : ""
                              }`}
                            >
                              <span className="checkbox-icon"></span>
                            </div>
                            <div className="checkbox-label">Patient</div>
                          </div>
                          <div
                            className="checkbox-container"
                            onClick={() =>
                              mapIndividualSessionSpeaker(
                                providerSessionId,
                                providerParticipantId,
                                "Provider"
                              )
                            }
                          >
                            <div
                              className={`custom-checkbox ${
                                speakerMappedData?.[providerSessionId]?.[providerParticipantId] ===
                                "Provider"
                                  ? "checked"
                                  : ""
                              }`}
                            >
                              <span className="checkbox-icon"></span>
                            </div>
                            <div className="checkbox-label">Provider</div>
                          </div>
                          <div
                            className="checkbox-container"
                            onClick={() =>
                              mapIndividualSessionSpeaker(
                                providerSessionId,
                                providerParticipantId,
                                "Patient Family"
                              )
                            }
                          >
                            <div
                              className={`custom-checkbox ${
                                speakerMappedData?.[providerSessionId]?.[providerParticipantId] ===
                                "Patient Family"
                                  ? "checked"
                                  : ""
                              }`}
                            >
                              <span className="checkbox-icon"></span>
                            </div>
                            <div className="checkbox-label">Patient Family</div>
                          </div>
                          <div
                            className="checkbox-container"
                            onClick={() =>
                              mapIndividualSessionSpeaker(
                                providerSessionId,
                                providerParticipantId,
                                "Nurse Practitioner"
                              )
                            }
                          >
                            <div
                              className={`custom-checkbox ${
                                speakerMappedData?.[providerSessionId]?.[providerParticipantId] ===
                                "Nurse Practitioner"
                                  ? "checked"
                                  : ""
                              }`}
                            >
                              <span className="checkbox-icon"></span>
                            </div>
                            <div className="checkbox-label">Nurse Practitioner</div>
                          </div>
                          <div
                            className="checkbox-container"
                            onClick={() =>
                              mapIndividualSessionSpeaker(
                                providerSessionId,
                                providerParticipantId,
                                "Unknown"
                              )
                            }
                          >
                            <div
                              className={`custom-checkbox ${
                                speakerMappedData?.[providerSessionId]?.[providerParticipantId] ===
                                "Unknown"
                                  ? "checked"
                                  : ""
                              }`}
                            >
                              <span className="checkbox-icon"></span>
                            </div>
                            <div className="checkbox-label">Unknown</div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="speaker-mapping-transcript-container">
                      {speakerTranscriptData[providerParticipantId]?.map((transcript, index) => {
                        return (
                          <div
                            className="transctipt-text"
                            key={index}
                          >
                            {transcript.transcriptText}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SpeakerMapping;

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}
