import { get, post } from "@/services/api";
import { addLocalStorage, getLocalStorage, deleteCookie, saveCookie } from "@/services/utils";
import { roleBusinessFunctionMapping } from "@/services/dummyData";
import { getProviderSessionIds } from "@/Routes/SessionDetails/sessionDetailsModule";
import Papa from "papaparse";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";

const SESSION_TRANSCRIPT_LLM_API_URL = import.meta.env.VITE_SESSION_TRANSCRIPT_LLM_API_URL;

export const getUserData = options => {
  return async dispatch => {
    let url = "practitioner-role/profile/info";
    dispatch({ type: "TOGGLE_USER_LOADING", show: true });
    try {
      let response = await get(url);
      if (response?.data) {
        let data = response.data;
        dispatch({ type: "ADD_USER_DATA", data });
      }
      dispatch({ type: "TOGGLE_USER_LOADING", show: false });
      if (options?.cb) {
        options.cb();
      }
    } catch (error) {
      dispatch({ type: "TOGGLE_USER_LOADING", show: false });
    }
    await dispatch(getPractiotionerAssignedRoles());
  };
};

export const signout = () => {
  return dispatch => {
    deleteCookie("authtoken");
    deleteCookie("soulside-email");
    dispatch(selectUserRole(null));
    window.location.href = window.location.origin + "/login";
  };
};

export const getPractiotionerAssignedRoles = options => {
  return async dispatch => {
    let url = "practitioner-role/find-all-practitioner-roles";
    try {
      let response = await get(url);
      if (response?.data) {
        let data = response.data;
        data = data.filter(i => !!i.active);
        const groupedData = data.reduce((acc, item) => {
          const {
            organizationId,
            organizationName,
            id,
            practitionerId,
            practitionerFirstName,
            practitionerLastName,
            practitionerEmail,
            behaviorHealthRole,
            createdAt,
          } = item;

          if (!acc[organizationId]) {
            acc[organizationId] = {
              organizationId,
              organizationName,
              roles: [],
            };
          }

          acc[organizationId].roles.push({
            ...item,
          });

          return acc;
        }, {});
        const newData = Object.values(groupedData);
        dispatch({ type: "ADD_USER_ROLES_DATA", data: newData });
        if (newData.length === 1 && newData[0].roles.length === 1) {
          dispatch(selectUserRole(newData[0].roles[0], true));
        }
      }
    } catch (error) {}
  };
};

export const getSelectedUserRoleFromLocal = () => {
  let selectedUserRole = getLocalStorage("selected-practitioner-role");
  saveCookie("selected-practitioner-role", selectedUserRole?.id || "");
  saveCookie("selected-organization", selectedUserRole?.organizationId || "");
  return selectedUserRole || null;
};

export const selectUserRole = (selectedRole, dontRefresh = false) => {
  return dispatch => {
    let role = null;
    if (selectedRole) {
      role = {
        ...selectedRole,
        businessFunction: roleBusinessFunctionMapping(selectedRole?.behaviorHealthRole),
      };
    }
    dispatch({ type: "SELECT_USER_ROLE", data: role });
    addLocalStorage("selected-practitioner-role", role);
    saveCookie("selected-practitioner-role", role?.id || "");
    saveCookie("selected-organization", role?.organizationId || "");
    if (!!role && !dontRefresh) {
      window.location.href = window.location.href;
    }
  };
};

const timeZoneAbbrMapping = {
  "America/Los_Angeles": "PT",
  "America/Phoenix": "MT",
  "America/Chicago": "CT",
  "America/New_York": "ET",
};
export const timezones = moment.tz
  .zonesForCountry("US", true)
  .filter(
    i =>
      i.name === "America/Los_Angeles" ||
      i.name === "America/Phoenix" ||
      i.name === "America/Chicago" ||
      i.name === "America/New_York"
  )
  .map(i => {
    return {
      ...i,
      offset: i.offset / 60,
      abbr: timeZoneAbbrMapping[i.name] || "",
    };
  });

export const getPreferredTimezoneFromLocal = () => {
  let preferredTimezone = getLocalStorage("preferred-timezone");
  saveCookie("preferred-timezone", preferredTimezone?.name || "America/Chicago");
  return preferredTimezone || timezones.find(i => i.name === "America/Chicago") || null;
};

export const selectPreferredTimezone = timezone => {
  return dispatch => {
    dispatch({ type: "SELECT_PREFERRED_TIMEZONE", data: timezone });
    addLocalStorage("preferred-timezone", timezone);
    saveCookie("preferred-timezone", timezone?.name || "America/Chicago");
  };
};

export const getTasksFromLocal = () => {
  let tasks = getLocalStorage("session-tasks");
  return tasks || [];
};

export const addTask = task => {
  return dispatch => {
    dispatch({ type: "ADD_TASK", data: task });
  };
};

export const removeTask = sessionId => {
  return dispatch => {
    dispatch({ type: "REMOVE_TASK", sessionId });
  };
};

export const getAllTaskStatus = options => {
  return async (dispatch, getState) => {
    let state = getState();
    let tasks = state.user.tasks.data;
    if (!options?.dontShowLoader) {
      dispatch({ type: "TOGGLE_TASKS_LOADING", show: true });
    }
    let readyTasks = [];
    for (let i = 0; i < tasks.length; i++) {
      let session = tasks[i];
      let transcriptData = await dispatch(
        getProviderSessionIds({
          sessionCategory: session?.sessionCategory === "GROUP" ? "group" : "individual",
          sessionId: session.id,
          triggerTime: new Date(),
          fromTasks: true,
        })
      );
      let taskReady = transcriptData?.length > 0;
      if (taskReady) {
        readyTasks.push(tasks[i].id);
      }
    }
    dispatch({ type: "ADD_READY_TASKS", data: readyTasks });
    if (!options?.dontShowLoader) {
      dispatch({ type: "TOGGLE_TASKS_LOADING", show: false });
    }
  };
};

export const getOldSessionTranscriptData = async session => {
  let transcriptData = [];
  let sessionId = session?.id;
  let url =
    session?.modeOfDelivery === "IN_PERSON"
      ? `practitioner-role/meeting-session/in-person/${sessionId}/transcript`
      : session?.sessionCategory === "GROUP"
      ? `practitioner-role/meeting-session/group/${sessionId}/transcript`
      : `practitioner-role/meeting-session/individual-session/${sessionId}/transcript`;
  try {
    let response = await get(url);
    if (response?.data) {
      let transcriptUrl = response.data;
      let pdfPromise = new Promise((resolve, reject) => {
        Papa.parse(transcriptUrl, {
          download: true,
          complete: function (results) {
            let data = results?.data || [];
            let transcriptData = data
              .map(i => {
                return {
                  timestamp: i[0] * 1000,
                  memberId: i[3],
                  memberName: i[4],
                  transcriptText: i[5],
                  memberSessionId: i[2],
                };
              })
              .sort((a, b) => a.timestamp - b.timestamp);
            if (transcriptData?.length > 0) {
              resolve(transcriptData);
            } else {
              reject(transcriptData);
            }
          },
          error: function () {
            reject(transcriptData);
          },
        });
      });
      try {
        transcriptData = await pdfPromise;
      } catch (error) {
        console.log(error);
      }
    }
  } catch (error) {}
  return transcriptData;
};

export const hideSideBar = flag => {
  return dispatch => {
    dispatch({ type: "TOGGLE_HIDE_SIDEBAR", flag });
  };
};

export const getTreatmentPlan = options => {
  return async dispatch => {
    let patientId = options.patientId;
    let url = `practitioner-role/treatment-plan/mvp/find-all-by-patient/${patientId}`;
    dispatch({ type: "TOGGLE_TREATMENT_PLAN_LOADER", patientId, show: true });
    let treatmentPlanData = null;
    try {
      let response = await get(url);
      if (response?.data?.length > 0) {
        let data = response.data.sort(
          (data1, data2) => new Date(data2.updatedAt) - new Date(data1.updatedAt)
        );
        treatmentPlanData = data[0];
        if (treatmentPlanData?.plan && treatmentPlanData.plan.hasOwnProperty("problems")) {
          delete treatmentPlanData.plan.problems;
          if (treatmentPlanData.plan.hasOwnProperty("intakeSession")) {
            delete treatmentPlanData.plan.intakeSession;
          }
        }
        if (treatmentPlanData?.plan && Object.keys(treatmentPlanData.plan).length === 0) {
          treatmentPlanData.plan = null;
        }
      }
    } catch (error) {
      console.log(error);
    }
    dispatch({ type: "ADD_TREATMENT_PLAN", patientId, data: treatmentPlanData });
    dispatch({ type: "TOGGLE_TREATMENT_PLAN_LOADER", patientId, show: false });
  };
};

export const generateTreatmentPlan = options => {
  return async dispatch => {
    let patientId = options.patientId;
    let url = `practitioner-role/treatment-plan/mvp/create-new`;
    if (!!options?.isEdit) {
      url = `practitioner-role/treatment-plan/mvp/revise-plan`;
    }
    let payload = options?.payload || {};
    dispatch({ type: "TOGGLE_TREATMENT_PLAN_LOADER", patientId, show: true });
    let treatmentPlanData = null;
    try {
      let response = await post(url, payload);
      if (response?.data) {
        treatmentPlanData = response.data;
      }
    } catch (error) {
      console.log(error);
    }
    dispatch({ type: "ADD_TREATMENT_PLAN", patientId, data: treatmentPlanData });
    dispatch({ type: "TOGGLE_TREATMENT_PLAN_LOADER", patientId, show: false });
  };
};

function convertTreatmentPlanToData(treatmentPlanJson) {
  const diagnosis = treatmentPlanJson.diagnosis;
  const treatmentPlan = treatmentPlanJson.treatment_plan;

  const goals = treatmentPlan.goals.map(goalDescription => {
    // Find corresponding objectives for this goal
    const matchingObjectives = treatmentPlan.objectives
      .filter(obj => obj.goal.toLowerCase().trim() === goalDescription.toLowerCase().trim())
      .map(obj => ({
        description: obj.description,
        timeline: obj.timeline,
      }));

    return {
      description: goalDescription,
      objectives: matchingObjectives,
    };
  });

  return {
    problems: [
      {
        description: `${diagnosis.name}${
          diagnosis.icd_10_code ? ` (${diagnosis.icd_10_code})` : ""
        }`,
        icd_10_code: diagnosis.icd_10_code,
        goals: goals,
        interventions: treatmentPlan.interventions,
      },
    ],
  };
}

export const generateTreatmentPlanAI = options => {
  return async (dispatch, getState) => {
    let patientId = options.patientId;
    dispatch({ type: "TOGGLE_TREATMENT_PLAN_LOADER", patientId, show: true });
    let intakeHPINote = "";
    let intakeSession = null;
    let intakeSessions = await getPatientIntakeSessions(patientId);
    if (intakeSessions?.length > 0) {
      for (let i = 0; i < intakeSessions.length; i++) {
        intakeHPINote = await getIntakeSessioHPINote(intakeSessions[i].id);
        if (intakeHPINote) {
          intakeSession = intakeSessions[i];
          break;
        }
      }
    }
    if (intakeHPINote) {
      let url = `${SESSION_TRANSCRIPT_LLM_API_URL}/treatment-plan/generate-treatment-plan-from-hpi-note-v2`;
      let payload = {
        sessionCategory: "individual",
        intakeHPINote,
      };
      let response = await post(url, payload);
      if (
        response?.data?.diagnosis &&
        response?.data?.treatment_plan &&
        Object.keys(response.data.treatment_plan).length > 0
      ) {
        let state = getState();
        let practitionerRoleId = state.user.selectedUserRole?.id;
        let savedTreatmentPlan = state.user.treatmentPlan?.[patientId]?.data
          ? cloneDeep(state.user.treatmentPlan[patientId].data)
          : null;
        let treatmentPlanData = convertTreatmentPlanToData(response.data);
        let saveTreatmentpayload = savedTreatmentPlan
          ? {
              ...savedTreatmentPlan,
              revisedByPractitionerRoleId: practitionerRoleId,
              patientId: patientId,
              plan: { treatmentPlanData, intakeSession },
              updatedAt: new Date().toISOString(),
            }
          : {
              revisedByPractitionerRoleId: practitionerRoleId,
              intakeSession,
              patientId: patientId,
              plan: { treatmentPlanData, intakeSession },
            };
        await dispatch(
          generateTreatmentPlan({
            patientId,
            payload: saveTreatmentpayload,
            isEdit: !!savedTreatmentPlan,
          })
        );
      }
      if (response?.data?.focus_areas?.length > 0 && false) {
        //treatment plan v1
        let treatmentPlanData = response.data.focus_areas.map((focusArea, index) => {
          return {
            ...focusArea,
            description: focusArea.description,
            problem_id: `problem-${index}`,
            behavioral_description: "",
            patient_description: "",
            strength: "",
            comment: "",
            goals: [
              {
                goal_id: `goal-${index}-0`,
                description: "",
                responsible_party: "",
                target_date: "",
                target_number_of_sessions: "",
                patient_description: "",
                comment: "",
                objectives: [
                  {
                    objective_id: `objective-${index}-0-0`,
                    description: "",
                    target_date: "",
                    target_number_of_sessions: "",
                    patient_description: "",
                    comment: "",
                    interventions:
                      focusArea.interventions?.map((intervention, interventionIndex) => {
                        return {
                          intervention_id: `intervention-${index}-0-0-${interventionIndex}`,
                          description: intervention,
                          frequency: 1,
                          lengths: 1,
                          duration: 1,
                          comment: "",
                        };
                      }) || [],
                  },
                ],
              },
            ],
          };
        });
        console.log("treatmentPlanData", treatmentPlanData);

        let state = getState();
        let practitionerRoleId = state.user.selectedUserRole?.id;
        let savedTreatmentPlan = state.user.treatmentPlan?.[patientId]?.data
          ? cloneDeep(state.user.treatmentPlan[patientId].data)
          : null;
        let saveTreatmentpayload = savedTreatmentPlan
          ? {
              ...savedTreatmentPlan,
              revisedByPractitionerRoleId: practitionerRoleId,
              patientId: patientId,
              plan: { problems: treatmentPlanData, intakeSession },
              updatedAt: new Date().toISOString(),
            }
          : {
              revisedByPractitionerRoleId: practitionerRoleId,
              intakeSession,
              patientId: patientId,
              plan: { problems: treatmentPlanData, intakeSession },
            };
        await dispatch(
          generateTreatmentPlan({
            patientId,
            payload: saveTreatmentpayload,
            isEdit: !!savedTreatmentPlan,
          })
        );
      }
    } else {
      toast.error("No Intake Session Found");
    }
    dispatch({ type: "TOGGLE_TREATMENT_PLAN_LOADER", patientId, show: false });
  };
};

const getPatientIntakeSessions = async patientId => {
  let intakeSessions = [];
  let url = `practitioner-role/individual-session/intake/find-all-by-patient/${patientId}`;
  try {
    let response = await get(url);
    if (response?.data?.length > 0) {
      intakeSessions = response.data;
      intakeSessions = intakeSessions.sort(
        (session1, session2) => new Date(session2.startTime) - new Date(session1.startTime)
      );
    }
  } catch (error) {}
  return intakeSessions;
};

const getIntakeSessioHPINote = async sessionId => {
  let hpiNote = "";
  let url = `practitioner-role/session-member-notes/session/${sessionId}/find`;
  try {
    let response = await get(url);
    if (response?.data?.length > 0) {
      let soapNotesJson = response.data[0]?.jsonSoapNote;
      hpiNote = soapNotesJson?.["intake-assessment"]?.intakeHPINote || "";
    }
  } catch (error) {}
  return hpiNote;
};
